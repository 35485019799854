export const cookieExpirationTime : number = 3;

export const formattingDate: DateTimeFormatOptions = { 
      day: 'numeric',
      month: 'short',
      year: 'numeric',
  };
  
  export type DateTimeFormatOptions = {
    day?: 'numeric';
    month?: 'short' ;
    year?: 'numeric';
  };

  export const mobileViewStartSize = 1080;
  
  