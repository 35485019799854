import './sort.options.styles.scss';

type SortOptionsProps = {
    handleSortSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void
}


const SortOptions = ({ handleSortSelect }: SortOptionsProps) => {

    return (
        <div className='sort-bar-container'>
            <select className='sort-bar' defaultValue={'DEFAULT'} onChange={handleSortSelect}>
                <option value="DEFAULT" disabled>Sort By</option>
                <option value="ascending_name">Name Ascending</option>
                <option value="descending_name">Name Descending</option>
                <option value="price_low">Price (Low - High)</option>
                <option value="price_high">Price (High - Low)</option>
            </select>
        </div>
    )

}

export default SortOptions;