
import { useContext } from "react";
import { CustomerContext } from '../../../contexts/customer.context';
import './customer.styles.scss';
import UserLogo from "../../../assets/Userlogo.jpeg";
import { Address } from '../../../types/context-types/customer.address.types';
import EditIcon from "../../../assets/Edit.jpeg";
import SettingIcon from  "../../../assets/setting.png";

interface CustomerInfoProps {
  hideManageSubscription?: boolean;
}

const CustomerInfo : React.FC<CustomerInfoProps> = ({ hideManageSubscription }) => {
  const { customerData} = useContext(CustomerContext);
  const displayFirstName = customerData?.Firstname || '';
  const displayEmail = customerData?.Email || '';
  const customerAddresses: Address[] = customerData?.CustomerAddresses || [];
  const AddressObject = customerData?.AddressObject ?? {};
 
 const handleManageSubscriptionClick = () =>
{
  window.location.href = `/manage-subscription`;
  }

  const {
    displayAddress1 = '',
    displayAddress2 = '',
    displayCity = '',
    displayProvinceCode = '',
    displayZip = '',
  } = AddressObject;

  const displayPhone = customerAddresses[0]?.Phone || '';
  const hasAddress = displayAddress1 || displayAddress2 || displayCity || displayProvinceCode || displayZip;
   
  return (
    <div>
      <div className='customer-info-container'>  
        <h1 className="profile">Profile <span><img className='customer-info-container2' src={EditIcon} alt="no edit icon" /></span></h1> 
        <img className='customer-info-container1' src={UserLogo} alt="User Profile" />

        <span className="displayname"><b>Hello, {displayFirstName}</b></span>
        <span><b>Nice to see you again!</b></span>
        <span></span>

        <div className="detail1">
          <h3>Contact Information</h3>
          {displayPhone ? (
            <span>{displayPhone}</span>
          ) : (
            <span>Phone number not available</span>
          )}
          <span>{displayEmail}</span>
        </div>

        <div className="detail">
          <h3>Delivery Information</h3>
          {hasAddress ? (
            <>
          <span>
            {displayAddress1}
            {displayAddress2 && `, ${displayAddress2}`}
            </span>
            <span>{displayCity},{displayProvinceCode}</span>
            <span>{displayZip}</span> 
            </>
          ) : (
            <span>No address available</span>
          )}
        </div>
      </div>
        
      {!hideManageSubscription && (
      <button className="more-setting-box">
      <span className="more-setting-text" onClick={handleManageSubscriptionClick} ><b>Manage Subscriptions</b></span>
      </button>
      )}

<div className="more-setting-box">
  < div className="more-setting-text">More Settings</div>
  <img className="more-settings-icon"  src={SettingIcon} alt="more setting"></img>
</div>
      </div>
  );
};

export default CustomerInfo;

