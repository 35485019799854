import './subscriptions-payment-form.styles.scss';
import FormButton from '../../forms/form-button/form-button.component';
import { useContext } from 'react';
import { StripeContext } from '../../../contexts/stripe.context';
import { stripeInstance, createStripeCustomer, updateStripeCustomer } from '../../../services/stripe/stripe.utils';
import { CartContext } from '../../../contexts/cart.context';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Stripe from 'stripe';
import { sendErrorMessageInSlack } from '../../../utils/miscellaneous.functions';
import { ApiDataContext } from '../../../contexts/api.data.context';


const encryptionKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY as string
const ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string

type SubscriptionsPaymentFormProps = {
    setErrorBanner: (message: string) => void,
}

const SubscriptionsPaymentForm = ({ setErrorBanner }: SubscriptionsPaymentFormProps) => {

    const { stripeCustomerId, lineItems } = useContext(StripeContext);
    const { cartItems } = useContext(CartContext);
    const { setMetaDataAPI } = useContext(ApiDataContext);

    const shopPagePath = `${window.location.origin}/shop`
    let buildABoxPageUrl = ''


    let stripeLineItems = JSON.parse(localStorage.getItem('lineItems') as string) || lineItems
    let metaDataObject = JSON.parse(localStorage.getItem('metadata') as string)
    let metaDataObjectStripe = JSON.parse(localStorage.getItem('metadataForStripe') as string)
    let cartItemsToSend = cartItems
    let { City, Country, Address1, Address2, Zip, Province } = metaDataObject?.Customer.Address

    const customerShippingAddress = {
        city: City,
        country: Country,
        line1: Address1,
        line2: Address2,
        postal_code: Zip,
        state: Province
    }

    const customerEmail = metaDataObject.Customer.Email
    const customerName = `${metaDataObject.Customer.Firstname} ${metaDataObject.Customer.Lastname}`

    const sendCheckoutDataToBackend = async () => {

        if (cartItems.length === 0) {
            cartItemsToSend = JSON.parse(localStorage.getItem('cartItems') as any)
        }


        setMetaDataAPI(metaDataObject);

        metaDataObject = { ...metaDataObject, 'CheckoutLineItems': cartItemsToSend }
        metaDataObjectStripe = { ...metaDataObjectStripe, 'CheckoutLineItems': JSON.stringify(cartItemsToSend) }


        try {
            const encryptedEmail = encryptCustomerEmail();

            /**
             * Delete BELOW in PROD
             * BELOW SVIX endpoints are for TESTING ONLY.
             */

            const response = await axios.post('https://play.svix.com/in/e_00M59bEcUB0QGiDB43iPNq5FUCD/', metaDataObject, {
                headers: {
                    'Biglocal-Custom-Header': encryptedEmail
                }
            }); //for rey

            const response2 = await axios.post('https://play.svix.com/in/e_I04B1n7Cnw6l9NxBVJO5mbfiXJ3/', metaDataObject, {
                headers: {
                    'Biglocal-Custom-Header': encryptedEmail
                }
            }); //for savi

            /**
             * Delete Above in PROD
             */


            const checkoutResp = await axios.post(`${ordersBaseURL}checkouts`, metaDataObject, {
                headers: {
                    'Biglocal-Custom-Header': encryptedEmail
                }
            })
            return checkoutResp.status

        } catch (e: any) {
            setErrorBanner('Something went wrong. Please try again!')
            sendErrorMessageInSlack(`Error in checkout endpoint: Customer Email ${metaDataObject.Customer.Email}`, e.response.data.errors[0].message)
        }
    }


    const encryptCustomerEmail = () => {
        const emailToEncrypt = metaDataObject.Customer.Email;
        const encryptedCustomerEmail = CryptoJS.AES.encrypt(emailToEncrypt.toString(), encryptionKey).toString();
        return encryptedCustomerEmail
    }

    const setSubscriptionsCheckoutSession = async () => {

        try {
            let session: Stripe.Response<Stripe.Checkout.Session>
            let customerIdStripe: string

            if (stripeCustomerId) {
                customerIdStripe = stripeCustomerId
                await updateStripeCustomer(stripeCustomerId, customerShippingAddress)
            } else {
                let stripeCustomerObject = await createStripeCustomer(customerEmail, customerName, customerShippingAddress)
                customerIdStripe = stripeCustomerObject.id
            }

            //  buildABoxPageUrl = `${window.location.origin}/build-a-box/${customerIdStripe}`
            buildABoxPageUrl = `${window.location.origin}/build-a-box/${metaDataObject.CheckoutId}`



            session = await stripeInstance.checkout.sessions.create({
                payment_method_types: ['card'],
                line_items: stripeLineItems,
                mode: 'subscription',
                success_url: `${buildABoxPageUrl}`,
                cancel_url: `${shopPagePath}`,
                metadata: metaDataObjectStripe,
                allow_promotion_codes: true,
                automatic_tax: {
                    enabled: true
                },
                customer_update: {
                    address: 'auto'
                },
                customer: customerIdStripe
            });

            window.location.href = session?.url as string;

        } catch (e: any) {
            console.log(e, "Error in creating stripe checkout session");
            setErrorBanner('Something went wrong. Please try again!')
            sendErrorMessageInSlack(`Error in creating stripe checkout session Customer Email ${metaDataObject.Customer.Email}`, e.raw.message)

        }
    };


    const setOTPCheckoutSession = async () => {
        const customer = stripeCustomerId ? { customer: stripeCustomerId } : {};
        const session = await stripeInstance.checkout.sessions.create({
            payment_method_types: ['card'],
            line_items: stripeLineItems,
            mode: 'payment',
            success_url: `${buildABoxPageUrl}`,
            cancel_url: `${shopPagePath}`,
            metadata: metaDataObjectStripe,
            ...customer,
            // shipping_address_collection: {
            //     allowed_countries: ['US', 'CA']
            // }
        });
        window.location.href = session?.url as string;
    };

    const paymentHandlerCheckout = async (event: React.FormEvent<HTMLFormElement>) => {

        /**
         * 
        OrderType = 1 for Regular Subs
        Order Type = 2 for GS
        OrderType = 3 for OTP
         */

        try {
            event.preventDefault();
            const checkoutResponseStatus = await sendCheckoutDataToBackend();
            if (checkoutResponseStatus === 200) {
                if (cartItemsToSend[0].OrderType === 1) {
                    await setSubscriptionsCheckoutSession();
                } else if (cartItemsToSend[0].OrderType === 3) {
                    await setOTPCheckoutSession();
                }
            }
            else {
                setErrorBanner('Something went wrong. Please try again!')
            }

        } catch (e: any) {
            setErrorBanner('Something went wrong. Please try again!')
            sendErrorMessageInSlack(`Error in navigation to stripe checkout==> Customer Email ${metaDataObject.Customer.Email}`, e)
        }

    }

    return (
        <div className="payment-form-container">
            <form onSubmit={paymentHandlerCheckout} className="stripe-form">
                <FormButton>Go To STRIPE Checkout</FormButton>
            </form>

        </div>
    )
}

export default SubscriptionsPaymentForm;

