import React from "react";
import { formattingDate } from "../../../constants/other.data";

interface DeliveryDetailsContentProps {
  date?: string | null;
  time?: string | null;
  address?: string;
  isOrderFulfilled?: boolean;
  orderStatus?: any;
  skipOrder?: boolean;

}

interface OrderItemsContentProps {
  order?: any;
  isOrderFulfilled?: boolean;
  orderStatus?: any;
  skipOrder?: boolean;
}

interface OrderAddonsProps {
  addOnText?: string;
  isOrderFulfilled?: boolean;
  orderStatus?: any;
  skipOrder?: boolean;
}

interface OrderCardProps {
  heading: string;
  content:
  | DeliveryDetailsContentProps
  | OrderItemsContentProps
  | OrderAddonsProps;
  buttonText: string;
  handleButtonClick?: () => void;
}

const OrderCard: React.FC<OrderCardProps> = ({
  heading,
  content,
  buttonText,
  handleButtonClick,
}) => {

  const { isOrderFulfilled } = content as OrderItemsContentProps;
  const { orderStatus } = content as OrderItemsContentProps;
  const { skipOrder } = content

  const isManageSubscription = ["Manage Subscription", "Pause Subscription", "Cancel Subscription"].includes(heading);
  const renderContent = () => {

    if (isManageSubscription) {
      const { addOnText } = content as OrderAddonsProps
      return addOnText;
    }

    else {
      if ("order" in content) {
        const { order } = content as OrderItemsContentProps;
        return order && order.OrderLineItems && order.OrderLineItems.length > 0
          ? order.OrderLineItems.map(
            (
              { Name, Quantity }: { Name: string; Quantity: number },
              index: number
            ) => (
              <React.Fragment key={index}>
                {Quantity ? `${Quantity}x ${Name}` : `${Name}`}
                {index !== order.OrderLineItems.length - 1 && ", "}
              </React.Fragment>
            )
          )
          : "No line items";
      }

      if ("date" in content) {
        const { date, time, address } = content as DeliveryDetailsContentProps;
        return (
          <>
            <span>{address}</span> <br />
            <span>
              {date ? (
                <>{new Date(date).toLocaleDateString("en-US", formattingDate)}</>
              ) : (
                <>Delivery date not available</>
              )}
            </span>
            <span>
              {time ? (
                <>@{new Date(time).toLocaleTimeString()}</>
              ) : (
                <>, delivery time not available</>
              )}
            </span>
          </>
        );
      }

      const { addOnText } = content as OrderAddonsProps;
      return addOnText;
    }

  };

  return (
    <>
      <div className="card-child-components">
        <div className="delivery-details-heading">{heading}</div>
        <div className="dropdown-line">
          <div>
            <span>{renderContent()}</span>
          </div>

          <div className="edit-date-container">

            {isManageSubscription && (
              skipOrder ? (
                heading !== 'Pause Subscription' && (
                  <button className="edit-date-btn" onClick={handleButtonClick}>
                    {buttonText}
                  </button>
                )
              ) : (
                <button className="edit-date-btn" onClick={handleButtonClick}>
                  {buttonText}
                </button>
              )
            )}

            {!isOrderFulfilled && typeof orderStatus !== undefined && orderStatus === 1 && skipOrder === false && (
              <button className="edit-date-btn" onClick={handleButtonClick}>
                {buttonText}
              </button>
            )}

          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCard;
