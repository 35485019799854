import { createContext, useState ,useEffect} from "react";
import { CustomerProviderProps, CustomerContextProps, CustomerData } from "../types/context-types/customer.context.types";

const defaultCustomerData = {
    CustomerId: '',
    Email: '',
    FullAddress: '',
    AddressObject:{},
}

export const CustomerContext = createContext<CustomerContextProps>({
    customerData: defaultCustomerData,
    setCustomerData: () => null,
})

export const CustomerProvider = ({ children }: CustomerProviderProps) => {
    const [customerData, setCustomerData] = useState<CustomerData>(defaultCustomerData);

    useEffect(() => {
        const customerAddresses = customerData?.CustomerAddresses || [];
        const addressObject = {
            displayAddress1: customerAddresses[0]?.Address1 || '',
            displayAddress2: customerAddresses[0]?.Address2 || '',
            displayCity: customerAddresses[0]?.City || '',
            displayProvinceCode: customerAddresses[0]?.ProvinceCode || '',
            displayZip: customerAddresses[0]?.Zip || '',
          };  
      const fullAddress = `${addressObject.displayAddress1}${addressObject.displayAddress2 ? `, ${addressObject.displayAddress2}` : ''}, ${addressObject.displayCity}, ${addressObject.displayProvinceCode}, ${addressObject.displayZip}.`;
       setCustomerData({
      ...customerData,
      FullAddress:fullAddress,
      AddressObject: addressObject,
    });
  }, [customerData?.CustomerAddresses]);
    const value = { customerData, setCustomerData }
    return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>
}