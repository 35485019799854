import { useEffect, useRef, useState } from 'react';
import './search.styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

type SerachBarProps = {
    onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
    searchString: string

}


const SearchBar = ({ onChangeHandler, searchString }: SerachBarProps) => {

    useEffect(() => {
        
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const [isShowSearchImage, setIsShowSearchImage] = useState(false);
    const parentRef = useRef<HTMLDivElement>(null);


    const expandSearchBar = () => {
        setIsShowSearchImage(true);
    }

    const handleOuterComponentClick = (e: MouseEvent, parentRef: React.RefObject<HTMLDivElement>) => {
        if (parentRef.current && !parentRef.current.contains(e.target as Node)) {
            setIsShowSearchImage(false);
        }
    };

    const handleClickOutside = (e: MouseEvent) => {
        handleOuterComponentClick(e, parentRef);
    };




    return (
        <div ref={parentRef} className='search-bar-container'>
            {
                isShowSearchImage ?
                    <input
                        className='search-bar'
                        type="text"
                        placeholder="Search for items.."
                        value={searchString}
                        onChange={onChangeHandler}
                    /> :
                    <div className='search-icon' onClick={expandSearchBar}>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
            }
        </div>
    );
};

export default SearchBar;
