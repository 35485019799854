import './landing.styles.scss';
import { Outlet } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';
import { StripeContext } from '../../contexts/stripe.context';
import { fetchStripeRecurringProducts, fetchStripeRegularProducts } from '../../services/stripe/stripe.utils';
import { clearCartDataInLocalStorage } from '../../utils/localstorage.functions';


const LandingPage = () => {

    let hostedEnv = process.env.REACT_APP_ENV as string;

    const { setReccuringStripeProducts, setRegularStripeProducts } = useContext(StripeContext);
    const [formattedDate, setFormattedDate] = useState<string>()


    useEffect(() => {
        clearCartDataInLocalStorage();
        getDate();
        setStripeProcuts();

    }, [])

    const setStripeProcuts = async () => {
        const recurringStripeProducts = await fetchStripeRecurringProducts();
        const regularStripeProducts = await fetchStripeRegularProducts();
        setReccuringStripeProducts(recurringStripeProducts);
        setRegularStripeProducts(regularStripeProducts);
        // setReccuringStripeProducts(recurringStripeProducts.data); // This should be edited to set GS from Stripe

    }

    const getDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${month}/${day}/${year}`;
        setFormattedDate(formattedDate)
    }


    return (
        <div className='landing-page-container'>
            <h3>{`${hostedEnv} <<#>> BIG LOCAL LANDING PAGE <$$> - ${formattedDate}`}</h3>
            <Outlet />
        </div>
    )

}

export default LandingPage;