import React, { useState, useEffect } from "react";
import {
  Order,
  EnabledDate,
} from "../../../types/context-types/api.data.context.types";
import { getDeliveryDatesAvailable } from "../../../services/api/data.utils";
import "./edit-date-modal.styles.scss";
import { formattingDate } from "../../../constants/other.data";

const ordersBaseURL: string = process.env.REACT_APP_ORDERS_BASE_URL as string;
const getDeliveryDateResponse = getDeliveryDatesAvailable();

const EditDeliveryDetails: React.FC<{
  order: Order;
  updateOrdersState: any;
  onModalClose: () => void;
}> = ({ order, updateOrdersState, onModalClose }) => {
  const { Id } = order;
  const [currentMonthDates, setCurrentMonthDates] = useState<EnabledDate[]>([]);
  const [selectedDateFromDropDown, setSelectedDateFromDropDown] = useState<
    string | null
  >(currentMonthDates[0]?.date.toISOString() || null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [currentlySelectedDeliveryDate, setCurrentlySelectedDeliveryDate] =
    useState<string | null>(order.Group?.StartTime || null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onModalClose();
  };

  const handleConfirmButtonClick = () => {
    if (selectedDateFromDropDown !== null) {
      handleEditDate(selectedDateFromDropDown, Id);
      onModalClose();
    }
  };

  useEffect(() => {
    fetchDeliveryDatesData();
  }, []);

  const fetchDeliveryDatesData = async () => {
    try {
      const deliveryDateResponse = await getDeliveryDateResponse;
      console.log()
      let enabledDates: EnabledDate[] = [];
      if (Array.isArray(deliveryDateResponse.data)) {
        enabledDates = deliveryDateResponse.data.map((item: any) => {
          const id: number = item.Id;
          const date: Date = new Date(item.StartTime);
          return { id, date: new Date(date) };
        });
      }
      const currentDate = new Date();
      const currentMonthDates = enabledDates.filter(
        (date) =>
          date.date.getMonth() === currentDate.getMonth() &&
          date.date.toISOString() !== currentlySelectedDeliveryDate
      );
      setCurrentMonthDates(currentMonthDates);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateOrders = (orderId: number, formattedDate: string) => {
    updateOrdersState((prevOrders: Order[]) => {
      return prevOrders.map((order: Order) => {
        if (order.Id === orderId) {
          return {
            ...order,
            Group: {
              ...order.Group,
              StartTime: formattedDate,
            },
          };
        }
        return order;
      });
    });
  };

  const handleEditDate = async (selectedValue: string, orderId: number) => {
    let formattedDate: any;
    if (selectedValue) {
      const selectedDate: Date = new Date(selectedValue);
      formattedDate = selectedDate.toISOString();
      const selectedDateId = currentMonthDates.find(
        (date) => date.date.toISOString() === selectedValue
      )?.id;
      try {
        const response = await fetch(`${ordersBaseURL}orders/deliverydate`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            OrderId: orderId,
            GroupId: selectedDateId,
          }),
        });

        if (response.status === 200) {
          updateOrders(orderId, formattedDate);
          setIsModalOpen(false);
        } else {
          console.error("Error updating delivery date:", response.status);
        }
      } catch (error) {
        console.error("Error updating delivery date:", error);
      }
    } else {
      console.log("No selected date");
    }
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Select Order Date:</h2>
            <select
              value={selectedDateFromDropDown || ""}
              onChange={(e) => {
              const selectedValue = e.target.value;
                setSelectedDateFromDropDown(selectedValue);
                
              }}
            >

  {currentlySelectedDeliveryDate ? (
                <option value={currentlySelectedDeliveryDate}>
                  {new Date(currentlySelectedDeliveryDate).toLocaleDateString(
                    "en-US",
                    formattingDate
                  )}{" "}
                  @
                  {new Date(currentlySelectedDeliveryDate).toLocaleTimeString()}
                </option>
              ) : (
                <option value="">select a delivery date</option>
              )}
          
              {currentMonthDates.map(({ id, date }) => (
                <option key={id} value={date.toISOString()}>
                  {date.toLocaleDateString("en-US", formattingDate)} @
                  {date.toLocaleTimeString()}
                </option>
              ))}
            </select>

            <button
              className="confirm-button"
              onClick={handleConfirmButtonClick}
            >
              Confirm
            </button>
            <button className="close-button" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDeliveryDetails;