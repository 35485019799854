import React, { useState } from "react";
import axios from 'axios'
import {
  Order
} from "../../../types/context-types/api.data.context.types";
import "./skip-order-modal.styles.scss";

const ordersBaseURL: string = process.env.REACT_APP_ORDERS_BASE_URL as string;

const SkipOrderModal: React.FC<{
  order: Order;
  onModalClose: () => void;
}> = ({ order, onModalClose }) => {

  const orderId = order?.Id;

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onModalClose();
  };

  const handleResetButtonClick = async () => {
    try {
      const response = await axios.put(`${ordersBaseURL}orders/${orderId}/skiporder/reset`);
      console.log('Response Data:', response.data);
      window.location.reload();
    }
    catch (error) {
      console.error('error skipping order:', error);
    }
    onModalClose();
  };

  const handeKeepItemsClick = async () => {
    try {
      const response = await axios.put(`${ordersBaseURL}orders/${orderId}/skiporder/keep`);
      console.log('Response Data:', response.data);
      window.location.reload();
    }
    catch (error) {
      console.error('error skipping order:', error);
    }
    onModalClose();
  };

  return (
    <div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Are you sure you want to skip the order?</h2>
            <button
              className="reset-confirm-button"
              onClick={handleResetButtonClick} >
              Reset line items
            </button>
            <button
              className="keep-confirm-button"
              onClick={handeKeepItemsClick}
            >
              Keep the items
            </button>
            <button className="close-button" onClick={handleCloseModal}>
              Close the modal
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SkipOrderModal;