import { createContext, useState, useEffect } from "react";
import { CartContextProps, CartProviderProps, StripeCartItem } from "../types/context-types/cart.context.types";


const makeCartEmpty = (cartItems: StripeCartItem[]) => {
    cartItems.splice(0, cartItems.length);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    return cartItems
}

const addCartItem = (cartItems: StripeCartItem[], productToAdd: StripeCartItem): StripeCartItem[] => {

    const qtyToAdd = productToAdd.Quantity;

    const existingCartItem = cartItems.find((cartItem) => cartItem.ProductId === productToAdd.ProductId);

    if (existingCartItem) {
        const itemsInCart = cartItems.map((cartItem) => cartItem.ProductId === productToAdd.ProductId ?
            { ...cartItem, Quantity: (cartItem.Quantity as number + qtyToAdd) } : cartItem)

        localStorage.setItem('cartItems', JSON.stringify(itemsInCart));
        return itemsInCart
    }
    localStorage.setItem('cartItems', JSON.stringify([...cartItems, { ...productToAdd, Quantity: qtyToAdd }]))
    return [...cartItems, { ...productToAdd, Quantity: qtyToAdd }]

}

const removeCartItem = (cartItems: StripeCartItem[], cartItemToRemove: StripeCartItem): StripeCartItem[] => {

    const existingCartItem = cartItems.find((cartItem) => cartItem.ProductId === cartItemToRemove.ProductId);

    if (existingCartItem?.Quantity === 1) {
        const itemsInCart = cartItems.filter((cartItem) => cartItem.ProductId !== cartItemToRemove.ProductId)
        localStorage.setItem('cartItems', JSON.stringify(itemsInCart));
        return itemsInCart
    }

    const itemsInCart = cartItems.map((cartItem) => cartItem.ProductId === cartItemToRemove.ProductId ?
        { ...cartItem, Quantity: (cartItem.Quantity as number - 1) } : cartItem)
    localStorage.setItem('cartItems', JSON.stringify(itemsInCart));
    return itemsInCart;
}

const clearCartItem = (cartItems: StripeCartItem[], cartItemToClear: StripeCartItem) => {
    const itemsInCart = cartItems.filter((cartItem) => cartItem.ProductId !== cartItemToClear.ProductId)
    localStorage.setItem('cartItems', JSON.stringify(itemsInCart));
    return itemsInCart
}




export const CartContext = createContext<CartContextProps>({
    isCartOpen: false,
    setIsCartOpen: () => null,
    cartItems: [],
    setCartItems: () => null,
    addItemToCart: () => null,
    clearCart: () => null,
    removeItemFromCart: () => null,
    clearItemFromCart: () => null,
    cartCount: 0,
    cartTotal: 0,
    subscriptionBalance: 0,
    setSubscriptionBalance: () => null,
    addOnCharge: 0,
    isInitialAddonSelection: false,
    setIsInitialAddonSelection: () => null,
    isInitialItemsEmpty: false,
    setIsInitialItemsEmpty: () => null,
    addonOrderId: '',
    setAddonOrderId: () => null,
    paidAmount: 0,
    setPaidAmount: () => null,
    initialCartTotal: 0,
    setInitialCartTotal: () => null,
    selectedSubscriptionName: '',
    setSelectedSubscriptionName: () => null,
    selectedSubscriptionPrice: 0,
    setSelectedSubscriptionPrice: () => null,
    isInitialPaymentFailed: false,
    setIsInitialPaymentFailed: () => null,
    checkoutID: '',
    setCheckoutID: () => null,

})


export const CartProvider = ({ children }: CartProviderProps) => {

    const [isCartOpen, setIsCartOpen] = useState(false);
    const [cartItems, setCartItems] = useState<StripeCartItem[]>([]);
    const [cartCount, setCartCount] = useState(0);
    const [cartTotal, setCartTotal] = useState(0);
    const [subscriptionBalance, setSubscriptionBalance] = useState(0);
    const [addOnCharge, setAddOnCharge] = useState(0);
    const [isInitialAddonSelection, setIsInitialAddonSelection] = useState(false);
    const [isInitialItemsEmpty, setIsInitialItemsEmpty] = useState(false);
    const [addonOrderId, setAddonOrderId] = useState('')
    const [paidAmount, setPaidAmount] = useState(0);
    const [initialCartTotal, setInitialCartTotal] = useState(0);
    const [selectedSubscriptionName, setSelectedSubscriptionName] = useState('');
    const [selectedSubscriptionPrice, setSelectedSubscriptionPrice] = useState(0);
    const [isInitialPaymentFailed, setIsInitialPaymentFailed] = useState(false);
    const [checkoutID, setCheckoutID] = useState('');



    let itemsInCart = cartItems


    useEffect(() => {
        if (cartItems.length === 0) {
            itemsInCart = JSON.parse(localStorage.getItem('cartItems') as any);
        }

        if (itemsInCart) {
            const newCartCount = itemsInCart?.reduce((total, cartItem) =>
                total + cartItem.Quantity, 0)
            setCartCount(newCartCount)
            // setCartCount(newCartCount || cartCount)
            localStorage.setItem('cartCount', JSON.stringify(newCartCount));
        }

    }, [itemsInCart]);



    useEffect(() => {
        if (cartItems.length === 0) {
            itemsInCart = JSON.parse(localStorage.getItem('cartItems') as any);
        }


        if (itemsInCart) {
            const newCartTotal = itemsInCart?.reduce((total, cartItem) =>
                (total + cartItem.Quantity * cartItem.Price), 0)
            setCartTotal(newCartTotal)
            // setCartTotal(newCartTotal || cartTotal)
            localStorage.setItem('cartTotal', JSON.stringify(newCartTotal));

            calculateAddOnCharge(newCartTotal);

        }

    }, [itemsInCart, paidAmount]);


    const calculateAddOnCharge = (newCartTotal: number) => {

        if (newCartTotal > paidAmount) {
            setAddOnCharge(newCartTotal - paidAmount);
            localStorage.setItem('addOnCharge', JSON.stringify(addOnCharge))

        } else {
            setAddOnCharge(0);
            localStorage.setItem('addOnCharge', JSON.stringify(0))

        }
    }




    const addItemToCart = (productToAdd: StripeCartItem) => {
        setCartItems(addCartItem(cartItems, productToAdd));
    }

    const removeItemFromCart = (cartItemToRemove: StripeCartItem) => {
        setCartItems(removeCartItem(cartItems, cartItemToRemove));

    }

    const clearItemFromCart = (cartItemToClear: StripeCartItem) => {
        setCartItems(clearCartItem(cartItems, cartItemToClear));

    }

    const clearCart = () => {
        setCartItems(makeCartEmpty(cartItems));
        setCartCount(0);
        setCartTotal(0)
    }

    const value = { isCartOpen, setIsCartOpen, cartItems, setCartItems, addItemToCart, clearCart, removeItemFromCart, clearItemFromCart, cartCount, cartTotal, subscriptionBalance, setSubscriptionBalance, addOnCharge, isInitialAddonSelection, setIsInitialAddonSelection, addonOrderId, setAddonOrderId, paidAmount, initialCartTotal, selectedSubscriptionName, setSelectedSubscriptionName, selectedSubscriptionPrice, setSelectedSubscriptionPrice, isInitialPaymentFailed, setIsInitialPaymentFailed, setInitialCartTotal, setPaidAmount, checkoutID, setCheckoutID, isInitialItemsEmpty, setIsInitialItemsEmpty }

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

