import "./orders.styles.scss";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { getCustomerData } from "../../services/api/data.utils";
import { StripeContext } from "../../contexts/stripe.context";
import { CustomerContext } from "../../contexts/customer.context";
import { cookieExpirationTime } from "../../constants/other.data";

import OrdersManagementComponent from "../../components/orderDetails/order-management-main.component";
import { sendErrorMessageInSlack } from "../../utils/miscellaneous.functions";

import { CartContext } from "../../contexts/cart.context";
import { ModalContext } from "../../contexts/modal.context";
import { useParams } from "react-router-dom";

const CustomerOrdersPage = () => {
  const { subscriptionid } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState(null);
  const {
    setIsAutenticated,
    userSessionObject,
    setIsChangingEmail,
    setCognitoUserId,
  } = useContext(AuthContext);
  const { setStripeCustomerId } = useContext(StripeContext);
  const { setCustomerData } = useContext(CustomerContext);

  const { clearCart } = useContext(CartContext);
  const { resetModalContext } = useContext(ModalContext);

  const expires = new Date();
  expires.setDate(expires.getDate() + cookieExpirationTime);

  let userSession = null;
  let accessToken = null;
  let jwtToken: any = null;
  let idToken = null;
  let userEmail = null;
  let cognitoUserId: any = null;

  useEffect(() => {
    checkAuthState();
    getSessionData();
    setCognitoUserId(cognitoUserId);
    setCustomerDataAvailable();
  }, [cognitoUserId]);

  const checkAuthState = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAutenticated(true);
    } catch (error) {
      setIsAutenticated(false);
      navigate("/signin");
    }
  };

  const handleSignOut = () => {
    try {
      Auth.signOut();
      setIsAutenticated(false);
      localStorage.clear();
      sessionStorage.clear();
      clearCart();
      resetModalContext();
      window.location.href = "/signin";
    } catch (error) {
      console.log("Error in SignOut ", error);
    }
  };

  const getSessionData = () => {
    if (userSessionObject) {
      userSession = userSessionObject?.getSignInUserSession();
      accessToken = userSession?.getAccessToken() as any;
      jwtToken = accessToken?.jwtToken;
      idToken = userSession?.getIdToken();
      setName(idToken?.payload.given_name);
      userEmail = idToken?.payload.email;
      cognitoUserId = idToken?.payload["cognito:username"];
    } else {
      let fetchedUserSessionObject = JSON.parse(
        localStorage.getItem("userSessionObject") as any
      );
      jwtToken =
        fetchedUserSessionObject?.signInUserSession.accessToken.jwtToken;
      idToken = fetchedUserSessionObject?.signInUserSession.idToken;
      setName(idToken?.payload.given_name);
      userEmail = idToken?.payload.email;
      cognitoUserId = idToken?.payload["cognito:username"];
    }
  };

  const handleChangeEmail = async () => {
    try {
      setIsChangingEmail(true);
      navigate("/auth/changeEmail");
    } catch (e) {
      console.log(e, "Error in changing user email");
    }
  };

  const setCustomerDataAvailable = async () => {
    sendErrorMessageInSlack(" JWT Token", jwtToken);
    let customerData = await getCustomerData(cognitoUserId, jwtToken);
    const responseStatusOfCustomerData=customerData?.status;
    
    if(!responseStatusOfCustomerData){
    const statusCode=customerData;
    if (statusCode == 401 || statusCode==403) {
      handleSignOut();
    }
  }
    setStripeCustomerId(customerData?.data.CustomerId);
    localStorage.setItem("stripeCustomerId", customerData?.data.CustomerId);
    setCustomerData(customerData?.data);
  };

  /* return (
        <div>
            <h1>Authenticatd User: {name}</h1>
            <h3 onClick={handleChangeEmail}>Change Email </h3>
        </div>
    )*/

  return (
    <>
     {subscriptionid !== undefined ? (
        <OrdersManagementComponent subscriptionid={subscriptionid} />
      ) : (
        <OrdersManagementComponent />
      )}
    </>
  );
};

export default CustomerOrdersPage;
