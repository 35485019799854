import './build-a-box.styles.scss';

import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductsCardsContainer from '../../components/build-a-box/product-cards-container/products-cards-container.component';
import CartDropdown from '../../components/cart/cart-dropdown/cart-dropdown.component';
import CreditBar from '../../components/build-a-box/credit-bar/credit-bar.component';
import { CartContext } from '../../contexts/cart.context';
import { clearCartDataInLocalStorage } from '../../utils/localstorage.functions';
import { StripeCartItem } from '../../types/context-types/cart.context.types';
import LoadingSpinner from '../../components/mui/spinner.component';
import { CustomerContext } from '../../contexts/customer.context';
import { calcuatePaidAndCartTotalAmounts } from '../../utils/cart.functions';
import { sendErrorMessageInSlack } from '../../utils/miscellaneous.functions';
import { mobileViewStartSize } from '../../constants/other.data';
import { faCartShopping, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from 'aws-amplify';
import { AuthContext } from '../../contexts/auth.context';

const ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string;
const LOADING_TIME = 3000;

const BuildABoxPage = () => {

    let { checkoutID } = useParams();  //TODO : checkoutID should be encrypted..!

    const { setSubscriptionBalance, setCartItems, setIsInitialAddonSelection, setAddonOrderId, setSelectedSubscriptionName, setSelectedSubscriptionPrice, isInitialPaymentFailed, setInitialCartTotal, setPaidAmount, setCheckoutID, isInitialItemsEmpty, setIsInitialItemsEmpty } = useContext(CartContext);
    const { setIsAutenticated } = useContext(AuthContext);
    setCheckoutID(checkoutID as string)

    useEffect(() => {
        localStorage.setItem('isBuildABoxPage', 'true');
        setLoaderAndRefetchTimer();
        clearCartDataInLocalStorage();
        fetchOrderInfo();
    }, [])


    const checkAuthStateEmail = async () => {
        try {
            let authData = await Auth.currentAuthenticatedUser();
            setIsAutenticated(true);
            return authData.attributes.email

        } catch (error) {
            setIsAutenticated(false);
            navigate("/signin");
            return null
        }
    };

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })


    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isLoadingTime, setIsLoadingTime] = useState(true);
    const [isNegativeBalance, setIsNegativeBalance] = useState(false);
    const navigate = useNavigate();
    const { setCustomerData, customerData } = useContext(CustomerContext);

    const combineCartItems = (modifiedCartItems: StripeCartItem[]) => {
        const combinedCartItemsObject: any = {};

        for (const cartItem of modifiedCartItems) {
            const { PriceId, Quantity } = cartItem;
            if (combinedCartItemsObject[PriceId]) {
                combinedCartItemsObject[PriceId].Quantity += Quantity;
            } else {
                combinedCartItemsObject[PriceId] = cartItem;
            }
        }

        return Object.values(combinedCartItemsObject) as StripeCartItem[]
    }

    const setLoaderAndRefetchTimer = () => {
        setTimeout(() => {
            isInitialPaymentFailed && isNegativeBalance && refetchOrderAndNavigate();
            setIsLoadingTime(false);
        }, LOADING_TIME);
    }

    const refetchOrderAndNavigate = async () => {
        const orderInfo = await axios.get(`${ordersBaseURL}orders/${checkoutID}/itemselection`) // URL not finalized yet
        const balance = orderInfo.data.Order.Subscription.Balance;

        if (balance >= 0) {
            navigate(`/build-a-box/${checkoutID}/orderconfirmation`)
        }
    }

    const handleCartOpen = () => {
        setIsCartOpen(true);

    }

    const handleCloseCart = () => {
        setIsCartOpen(false);
    }


    const fetchOrderInfo = async () => {

        const userEmail = await checkAuthStateEmail()

        const orderInfo = await axios.get(`${ordersBaseURL}orders/${checkoutID}/itemselection`) // URL not finalized yet
        const { Balance, Name, Price } = orderInfo.data.Order.Subscription;
        const { SubscriptionTransactions } = orderInfo.data.Order;
        console.log('Balancee from backend ==>', Balance)
        const email = orderInfo.data.Customer.Email

        if (email !== userEmail) {
            setIsAutenticated(false);
            navigate("/signin");
        }

        if (Balance < 0) {
            setIsNegativeBalance(true);
        }
        setCustomerData({ ...orderInfo.data.Customer, 'GroupData': orderInfo.data.Order.Group })
        setSubscriptionBalance(Balance);
        setSelectedSubscriptionName(Name);
        setSelectedSubscriptionPrice(Price);

        const fetchedCartItems: StripeCartItem[] = orderInfo.data.Order.OrderLineItems

        /**
         * To be removed. Just for debugging 
         */
        console.log('Fetched Cart Items below')
        console.log(fetchedCartItems);
        console.log('Subscription Transaction Below');
        console.log(SubscriptionTransactions);




        const combinedCartItems = combineCartItems(fetchedCartItems);

        setCartItems(combinedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(combinedCartItems));
        setAddonOrderId(orderInfo.data.Order.Id);

        if (SubscriptionTransactions.length === 1) {
            setIsInitialAddonSelection(true);

        } else {
            setIsInitialAddonSelection(false);

        }

        if (fetchedCartItems.length === 0) {
            setIsInitialItemsEmpty(true);
           
        } else {
            setIsInitialItemsEmpty(false);

        }


        let { paidAmount, initialCartTotal } = calcuatePaidAndCartTotalAmounts(Balance, SubscriptionTransactions);

        /**
         * Need to remove below in prod
         */

        sendErrorMessageInSlack(`<==========BUIld A BOX PAGE DATA START ===========> checkoutID: `, checkoutID)
        sendErrorMessageInSlack(`Balance From Backend in Build A Box Page ==> `, Balance)
        sendErrorMessageInSlack(`SubscriptionTransactions From Backend in Build A Box Page ==> `, SubscriptionTransactions)
        sendErrorMessageInSlack(`Calculated paidAmount in UI ==> `, paidAmount);
        sendErrorMessageInSlack(`Calculated initialCartTotal in UI ==> `, initialCartTotal);
        sendErrorMessageInSlack(`<############# BUIld A BOX PAGE DATA END #############> checkoutID: `, checkoutID);



        setInitialCartTotal(initialCartTotal)
        setPaidAmount(paidAmount)
    }

    return (

        <div className='build-a-box-container'>
            {(isInitialPaymentFailed && isLoadingTime) && <LoadingSpinner />}
            {dimensions.width < mobileViewStartSize ?
                <div className='build-a-box-container-mobile'>
                    <h1 className='page-title'>
                        BUILD A BOX
                    </h1>
                    <div className='cart-and-otheritems-container-mobile'>
                        <div className='credit-items-sort-options-container-mobile'>
                            <div className='credit-bar'>
                                <CreditBar />
                            </div>
                            <div className='items-container'>
                                <ProductsCardsContainer />
                            </div>
                            {!isCartOpen && <div onClick={handleCartOpen} className='cart-image-container'>
                                <FontAwesomeIcon icon={faCartShopping} className='cart-img-logo' />
                            </div>}
                            {
                                isCartOpen ?
                                    <div className='cart-container-overlay'>
                                        <div className='cart-container-mobile'>
                                            <CartDropdown />
                                        </div>
                                        <div onClick={handleCloseCart} className='cart-image-close-container'>
                                            <FontAwesomeIcon className='cart-image-close-icon' icon={faTimes} />
                                        </div>
                                    </div> : null
                            }
                        </div>
                    </div>
                </div> :
                <div>
                    <h1 className='page-title'>
                        BUILD A BOX FOR CUSTOMER - {customerData?.Firstname?.toUpperCase()}
                    </h1>
                    <div className='cart-and-otheritems-container'>
                        <div className='credit-items-sort-options-container'>
                            <div className='credit-bar'>
                                <CreditBar />
                            </div>
                            <div className='items-container'>
                                <ProductsCardsContainer />
                            </div>
                        </div>
                        <div className='cart-container'>
                            <CartDropdown />
                        </div>

                    </div>
                </div>}

        </div>
    )

}

export default BuildABoxPage;