import './auth-options.styles.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalContext } from '../../../contexts/modal.context';
import { useContext } from 'react';


// type AuthOptionsProps = {

//     isBackButtonAllowed: boolean,
//     modalHeading: string,
//     handleBackButtonPress: () => void,
//     handleCloseModal: () => void,
//     setModalCategory: React.Dispatch<React.SetStateAction<string>>
// }


const AuthOptions = () => {

    const { setModalCategory, orderTypeSelected } = useContext(ModalContext);


    // const { modalSummaryMessage } = useContext(ModalContext);

    const handleGuestUser = () => {
        localStorage.setItem('isAuthFromModal', 'false')
        localStorage.setItem('guestCheckout', 'true')
        setModalCategory('shippingData')
    }

    const handleSignInUser = () => {
        localStorage.setItem('isAuthFromModal', 'true')
        sessionStorage.setItem('biglocal-session-key', 'true');
        const isAuthenticatedSession = sessionStorage.getItem('biglocal-session-key') === "true";
        const popupWindow: any = window.open(`${window.location.origin}/signin`, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400")
        popupWindow.addEventListener("beforeunload", () => {
            if (isAuthenticatedSession) {
                sessionStorage.setItem('biglocal-session-key', "true");
            } else {
                sessionStorage.removeItem('biglocal-session-key');
            }
        });
    }

    const handleSignUpUser = () => {
        localStorage.setItem('isAuthFromModal', 'true')
        sessionStorage.setItem('biglocal-session-key', 'true');
        const isAuthenticatedSession = sessionStorage.getItem('biglocal-session-key') === "true";
        const popupWindow: any = window.open(`${window.location.origin}/signup`, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400")
        popupWindow.addEventListener("beforeunload", () => {
            if (isAuthenticatedSession) {
                sessionStorage.setItem('biglocal-session-key', "true");
            } else {
                sessionStorage.removeItem('biglocal-session-key');
            }
        });
    }

    return (
        <div className='auth-modal'>
            <div className='modal-body modal-body-auth'>
                <div onClick={handleSignInUser} className='auth-option-conatiner'>
                    <h4>Already have an account?</h4>
                    <span>Sign-In</span>
                </div>
                <div onClick={handleSignUpUser} className='auth-option-conatiner'>
                    <h4>Create a new account?</h4>
                    <span >Sign-Up</span>
                </div>
                {orderTypeSelected.id !== 1 ?
                    <div onClick={handleGuestUser} className='auth-option-conatiner'>
                        <h4>Continue as a guest?</h4>
                    </div> : null}

            </div>
        </div>
    )
}

export default AuthOptions;

