import './order.confirmation.styles.scss';
import logo from '../../assets/logo.png'
import Button from '../../components/button/button.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from '../../components/mui/spinner.component';

const ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string
const LOADING_TIME = 3000;


const OrderConfirmationPage = () => {

    const navigate = useNavigate();
    const [isLoadingTime, setIsLoadingTime] = useState(true);
    let { checkoutID } = useParams();

    useEffect(() => {
        checkBalanceAmountAndNavigate();
        setIsLoadingTime(false);
    })


    const setLoaderAndRefetchTimer = () => {
        setTimeout(() => {
            refetchOrderAndNavigate();
            setIsLoadingTime(false);
        }, LOADING_TIME);
    }

    const refetchOrderAndNavigate = async () => {
        const orderInfo = await axios.get(`${ordersBaseURL}orders/${checkoutID}/itemselection`) // URL not finalized yet
        const balance = orderInfo.data.Order.Subscription.Balance;
        if (balance < 0) {
            navigate(`/build-a-box/${checkoutID}`)
        }
    }

    const checkBalanceAmountAndNavigate = async () => {
        const orderInfo = await axios.get(`${ordersBaseURL}orders/${checkoutID}/itemselection`);
        let balance = orderInfo.data.Order.Subscription.Balance;

        if (balance < 0) {
            setLoaderAndRefetchTimer();
        }
    }

    const handleManageOrders = () => {
        navigate(`/orders`);
    }

    const handleGoToHome = () => {
        navigate(`/`);
    }

    return (
        <div className='orderconfirmation-container'>
             {(isLoadingTime) && <LoadingSpinner />}
            <div className='orderconfirmation-heading'>
                <h1>TRUST US, YOUR BOX HAS THE BEST STUFF.</h1>
                <h1>THANK YOU SO MUCH FOR YOUR SUPPORT!</h1>
            </div>
            <div className='orderconfirmation-image'>
                <img src={logo} />
            </div>
            <div className='orderconfirmation-text'>
                <h3>
                    This is awesome, we know you'll love your items. We'll get started on collecting these items for your box and have them <span>delivered on your desired date and time.</span>
                </h3>
                <h3>
                    We know you're excited to receive your items, but if you would like to make further adjustments, just visit the order <span>management page!</span> PS. That page is also great for you to see your order details.
                </h3>
            </div>
            <div className='orderconfirmation-buttons'>
                <Button onClick={handleManageOrders}>Manage Orders</Button>
                <Button onClick={handleGoToHome}>Home</Button>

            </div>
            <div className='orderconfirmation-footer'>
                <span>
                    If you have any questions or would you like further assistance, please contact our support crew
                </span>
            </div>
        </div>
    )

}

export default OrderConfirmationPage;