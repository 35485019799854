import { createContext, useState } from 'react';
import { ModalProviderProps, ModalContxtType, FormValuesShipping, MetaData, ModalCard } from '../types/context-types/modal-context.types';
import { Address } from '../types/form-types/shipping-from.types';

const MODAL_START_AT = 'cities'
/**
 * Change according to future need.
 */

export const defaultAddressValues: Address = {
    displayAddress1: '',
    displayAddress2: '',
    displayCity: '',
    displayProvince: '',
    displayProvinceCode: '',
    displayProvinceId: 0,
    displayCountry: '',
    displayCountryCode: '',
    displayCountryId: 0,
    displayPostalCode: '',
    displayAddressType: '',
    displayBuzzCode: ''
}

export const defaultFormValuesShipping: FormValuesShipping = {
    displayFirstName: '',
    displayLastName: '',
    displayEmail: "",
    displayPhone: '',
    displayAddress: defaultAddressValues
}


const defaultMedaData: MetaData = {
    DeliveryDate: '',
    Name: '',
    Email: '',
    PhoneNumber: '',
    Address: '',
    DeliveryId: '',
}

const defaultModalCardData: ModalCard = {
    id: 0,
    name: '',
    abbrivation: ''
}

/**
 * Default Country and Province data should be removed when we introduce the modal process. After that default values should be an emptry object
 */

export const defaultCountryData: ModalCard = {
    id: 1,
    name: 'Canada',
    abbrivation: 'CA'
}

export const defaultProvinceData: ModalCard = {
    id: 3,
    name: 'Manitoba',
    abbrivation: 'MB'
}

export const ModalContext = createContext<ModalContxtType>({
    modalCategory: MODAL_START_AT,
    setModalCategory: () => null,
    countrySelected: defaultCountryData,
    setCountrySelected : () => null,
    provinceSelected: defaultProvinceData,
    setProvinceSelected: () => null,
    citySelected: defaultModalCardData,
    setCitySelected: () => null,
    orderTypeSelected: defaultModalCardData,
    setOrderTypeSelected: () => null,
    deliveryDateSelected: '',
    deliveryDateSelectedID: null,
    setDeliveryDateSelected: () => null,
    setDeliveryDateSelectedID: () => null,
    productSelected: '',    // THIS IS THE NAME OF THE PRODUCT! 
    setProductSelected: () => null,
    shippingData: defaultFormValuesShipping,
    setShippingData: () => null,
    isNavButtonActive: false,
    isEditModalFlow: false,
    setIsEditModalFlow: () => null,
    setIsNavButtonActive: () => null,
    selectedStripeProductFromModal: null,
    setSelectedStripeProductFromModal: () => null,
    showModal: true,
    setShowModal: () => null,
    modalSummaryMessage: '',
    setModalSummaryMessage: () => null,
    resetModalContext: () => null,
    metaData: defaultMedaData,
    setMetaData: () => null
});


export const ModalProvider = ({ children }: ModalProviderProps) => {

    const [modalCategory, setModalCategory] = useState(MODAL_START_AT);
    const [citySelected, setCitySelected] = useState(defaultModalCardData);
    const [countrySelected, setCountrySelected] = useState(defaultCountryData);
    const [provinceSelected, setProvinceSelected] = useState(defaultProvinceData);
    const [orderTypeSelected, setOrderTypeSelected] = useState(defaultModalCardData);
    const [deliveryDateSelected, setDeliveryDateSelected] = useState('');
    const [deliveryDateSelectedID, setDeliveryDateSelectedID] = useState<null | number>(null);
    const [productSelected, setProductSelected] = useState('');
    const [shippingData, setShippingData] = useState(defaultFormValuesShipping);
    const [isNavButtonActive, setIsNavButtonActive] = useState(false);
    const [isEditModalFlow, setIsEditModalFlow] = useState(false);
    const [selectedStripeProductFromModal, setSelectedStripeProductFromModal] = useState(null);
    const [showModal, setShowModal] = useState(true);
    const [modalSummaryMessage, setModalSummaryMessage] = useState('');
    const [metaData, setMetaData] = useState(defaultMedaData);
    
    const resetModalContext = () => {
        setModalCategory(MODAL_START_AT);
        setCitySelected(defaultModalCardData);
        setCountrySelected(defaultModalCardData);
        setProvinceSelected(defaultModalCardData);
        setOrderTypeSelected(defaultModalCardData);
        setDeliveryDateSelectedID(null);
        setDeliveryDateSelected('');
        setProductSelected('');
        setShippingData(defaultFormValuesShipping);
        setIsNavButtonActive(false);
        setIsEditModalFlow(false);
        setSelectedStripeProductFromModal(null);
        setShowModal(true);
        setModalSummaryMessage('');
        setMetaData(defaultMedaData);

    };


    const value = {
        modalCategory, setModalCategory,
        countrySelected, setCountrySelected, 
        provinceSelected, setProvinceSelected,
        citySelected, setCitySelected,
        orderTypeSelected, setOrderTypeSelected,
        deliveryDateSelected, setDeliveryDateSelected,
        deliveryDateSelectedID, setDeliveryDateSelectedID,
        productSelected, setProductSelected,
        shippingData, setShippingData,
        isNavButtonActive, setIsNavButtonActive,
        selectedStripeProductFromModal, setSelectedStripeProductFromModal,
        showModal, setShowModal,
        modalSummaryMessage, setModalSummaryMessage, resetModalContext,
        metaData, setMetaData,
        isEditModalFlow, setIsEditModalFlow

    };

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

