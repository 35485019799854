import { useEffect, useState } from 'react';
import './products-cards-container.styles.scss';
import { fetchStripeRegularProducts } from '../../../services/stripe/stripe.utils';
import ProductCard from '../product-card/product-card.component';
import { StripeProductDataCombined } from '../../../types/context-types/stripe.context.types';
import SearchBar from '../../search-bar/search.component';
import SortOptions from '../../sort-options/sort.options.component';
import FilterOptions from '../../filter-options/filter-options.components';


const ProductsCardsContainer = () => {

    const [products, setProducts] = useState<StripeProductDataCombined[]>([]);
    const [productsDisplay, setProductsDisplay] = useState<StripeProductDataCombined[]>(products);
    const [filteredProducts, setFilteredProducts] = useState<StripeProductDataCombined[]>(productsDisplay);

    const [sortState, setSortState] = useState('');
    const [searchField, setSearchField] = useState('');
    const [filterName, setFilterName] = useState('ALL_PRODUCTS');
    const [isLowQty, setIsLowQty] = useState(false);

    useEffect(() => {
        fetchStripeAddonProducts();
    }, [])

    useEffect(() => {
        handleSetSearchedProducts();
    }, [products, searchField])


    useEffect(() => {
        handleSetFilterProducts();
    }, [products, filterName])


    const handleSetSearchedProducts = () => {

        if (filterName === 'ALL_PRODUCTS' || '') {
            const productsSearched = products.filter((product) => {
                return product.name.toLowerCase().includes(searchField)
            })

            setProductsDisplay(productsSearched)
        } else {
            const productsSearched = filteredProducts.filter((product) => {
                return product.name.toLowerCase().includes(searchField)
            })

            setProductsDisplay(productsSearched)

        }

    }

    const handleSetFilterProducts = () => {

        setSearchField('')
        const productsFiltered = products.filter((product) => {
            return product.category.category?.toLowerCase() === filterName.toLowerCase();
        })
        if (productsFiltered.length === 0 && filterName === 'ALL_PRODUCTS') {
            setProductsDisplay(products)
            setFilteredProducts(products)
        }
        else if (productsFiltered.length === 0 && filterName !== 'ALL_PRODUCTS') {
            setProductsDisplay([])
            setFilteredProducts([])
        }
        else {
            setProductsDisplay(productsFiltered)
            setFilteredProducts(productsFiltered)
        }
    }


    const fetchStripeAddonProducts = async () => {
        const regularStripeProducts = await fetchStripeRegularProducts();
        setProducts(regularStripeProducts);

    }

    const handleSetSearchName = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let searchString = event.target.value.toLowerCase();
        setSearchField(searchString);

    }

    const handleSetProductsFilterName = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterName(event.target.value)

    }

    const handleSortSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortState(event.target.value)
    }

    const sortMethods: Record<string, { method: (a: any, b: any) => 1 | -1 }> = {
        ascending_name: { method: (a: any, b: any) => (a.name > b.name ? 1 : -1) },
        descending_name: { method: (a: any, b: any) => (a.name > b.name ? -1 : 1) },
        price_low: { method: (a: any, b: any) => (a.unit_amount > b.unit_amount ? 1 : -1) },
        price_high: { method: (a: any, b: any) => (a.unit_amount > b.unit_amount ? -1 : 1) },
    };

    return (
        <div>
            <div className='product-search-filter-sort-main-container'>
                <div className='category-name-container'>
                    {filterName.toUpperCase().replace('_', ' ')}
                </div>
                <div className='filter-sort-search-container'>
                    <FilterOptions handleProductsFilterName={handleSetProductsFilterName} />
                    <SortOptions handleSortSelect={handleSortSelect} />
                    <SearchBar onChangeHandler={handleSetSearchName} searchString={searchField} />
                </div>
            </div>
            {
                productsDisplay.length !== 0 ?
                    <div className={`products-cards-container ${productsDisplay.length < 5 ? `low-qty` : null}`}>
                        {
                            productsDisplay.sort(sortMethods[sortState as string]?.method)
                                .map((product: StripeProductDataCombined) => {
                                    return (
                                        <ProductCard key={product.id} product={product} isLowQty={productsDisplay.length < 5} />
                                    )
                                })
                        }
                    </div> :
                    <span className='products-empty-message'>There are no products to display!</span>
            }
        </div>

    )
}

export default ProductsCardsContainer;