import './form-button.styles.scss';

type FormButtonProps = {
    children: string,
} & React.ComponentProps<'button'>

const FormButton = ({ children, ...otherProps }: FormButtonProps) => {
    return (
        <button className='button-container' {...otherProps}>{children}</button>
    )
}

export default FormButton;