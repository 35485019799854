import './modal-card.styles.scss';
import { useContext, } from 'react';
import { ModalContext } from '../../../contexts/modal.context';
import { CartContext } from '../../../contexts/cart.context';
import { StripeProductDataCombined } from '../../../types/context-types/stripe.context.types';
import { getProvincesByCountrySelected, getCitiesByProvinceSelected } from '../../../services/api/data.utils';

type ModalCardProps = {
    children: string,
    modalValue: ModalValueType,
    modalType: string,
    stripeProductSelected?: StripeProductDataCombined
    // stripeProductSelected? : Stripe.Price

}

type ModalValueType = {
    name: string,
    id: number | string,
    // id: number | string,
    image?: string,
    abbrivation?: string
}



const ModalCard = ({ children, modalValue, modalType, stripeProductSelected }: ModalCardProps) => {

    const { name, id, abbrivation } = modalValue; // This is used for every modal type. not only for stripe data.


    const {
        countrySelected, setCountrySelected,
        provinceSelected, setProvinceSelected,
        citySelected, setCitySelected,
        orderTypeSelected, setOrderTypeSelected,
        deliveryDateSelected, setDeliveryDateSelected,
        productSelected, setProductSelected, setIsNavButtonActive, setSelectedStripeProductFromModal
    } = useContext(ModalContext)




    const handleModalData = () => {
        setIsNavButtonActive(true);
        switch (modalType) {
            case 'countries':
                getProvincesByCountrySelected(id as number)
                setCountrySelected({
                    id: id as number,
                    name,
                    abbrivation
                });
                localStorage.setItem('countrySelected', JSON.stringify({
                    id: id as number,
                    name,
                    abbrivation
                }));
                break;
            case 'provinces':
                getCitiesByProvinceSelected(id as number)
                setProvinceSelected({
                    id: id as number,
                    name,
                    abbrivation
                });
                localStorage.setItem('provinceSelected', JSON.stringify({
                    id: id as number,
                    name,
                    abbrivation
                }));
                break;
            case 'cities':
                setCitySelected({
                    id: id as number,
                    name,
                    abbrivation
                });
                localStorage.setItem('citySelected', JSON.stringify({
                    id: id as number,
                    name,
                    abbrivation
                }));
                break;
            case 'orderType':
                setOrderTypeSelected({ id: id as number, name });
                localStorage.setItem('orderTypeSelected', name);
                break;
            case 'avaialableDates':
                setDeliveryDateSelected(name);
                localStorage.setItem('deliveryDateSelected', JSON.stringify(name));
                break;
            case 'productSelection':
                setProductSelected(name);
                localStorage.setItem('productSelected', name);
                if (stripeProductSelected) {
                    setSelectedStripeProductFromModal(stripeProductSelected)
                    localStorage.setItem('selectedStripeProductFromModal', JSON.stringify(stripeProductSelected));
                }
                break;
            default:
                break;
        }
    }

    const setSelectedButton = () => {
        if (countrySelected.name === name || provinceSelected.name === name || citySelected.name == name || orderTypeSelected.name == name || deliveryDateSelected == name || productSelected == name) {
            return true
        }
    }

    return (
        <div className='modal-card-button'>
            <button
                className={`${setSelectedButton() ? 'clicked' : ''}`}
                onClick={handleModalData}>{children}
            </button>
        </div>
    )
}

export default ModalCard;