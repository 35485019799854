import { useContext } from 'react';
import './addon-other-details.styles.scss';
import { CustomerContext } from '../../../contexts/customer.context';


const AddOnOtherDetails = () => {

    const { customerData } = useContext(CustomerContext);
    let customerInfo = customerData;
    let { Firstname, Lastname, Email, Phone, GroupData, FullAddress } = customerInfo;
    let StartTime = GroupData?.StartTime

    const formatDateToDisplay = (StartTime: string) => {
        const date = new Date(StartTime);
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric"
        });

        return formattedDate;
    }

    return (
        <div className='other-info-container'>
            <div className='contact-info-container'>
                <div className='contact-info-header'>
                    <h2>CONTACT INFO</h2>
                    <img />
                </div>
                <div className='contact-info-body'>
                    <span className='info-name'>{`${Firstname} ${Lastname}`}</span>
                    <span className='info-phone'>{Phone}</span>
                    <span className='info-email'>{Email}</span>
                </div>
            </div>
            <div className='order-info-container'>
                <div className='order-info-header'>
                    <h2>ORDER DETAILS</h2>
                    <img />
                </div>
                <div className='order-info-body'>
                    <div className='delivery-address-container sub-container'>
                        <h3 className='order-info-subheading'>Delivery Address</h3>
                        <span className='order-info-data'>{FullAddress}</span>
                    </div>
                    <div className='date-time-container sub-container'>
                        <h3 className='order-info-subheading'>Date & Time</h3>
                        <span className='order-info-data'>{formatDateToDisplay(StartTime)}</span>

                    </div>
                    <div className='delivery-notes-container'>
                        <h3 className='order-info-subheading'>Delivery Notes (Optional)</h3>
                        <input className='delivery-notes' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddOnOtherDetails;