import React, { useState } from "react";
import axios from 'axios'
import {
  Order
} from "../../../types/context-types/api.data.context.types";
import "./confirm-cancel-pause-subscription.styles.scss";
import AlertBanner from '../../mui/alert.component';

const ordersBaseURL: string = process.env.REACT_APP_ORDERS_BASE_URL as string;

interface ConfirmationTextMap {
  [key: string]: string;
}

const confirmationTexts: ConfirmationTextMap = {
  cancel: 'Are you sure you want to cancel Subscription?',
  pause: 'Are you sure you want to pause Subscription?',
  resume: 'Are you sure you want to resume Subscription?'
};

const ConfirmCancelOrPause: React.FC<{
  order: Order;
  pauseOrCancel: string;
  onModalClose: () => void;
}> = ({ order, pauseOrCancel, onModalClose }) => {

  const [showAlertError, setShowAlertError] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onModalClose();
  };

  const handleConfirmButtonClick = () => {
    const confirmationText = confirmationTexts[pauseOrCancel];
    if (confirmationText) {
      if (pauseOrCancel === "cancel") {
        cancelSubscriptionInStripeAndDb(order?.Payment?.SubscriptionId);
      } else if (pauseOrCancel === 'pause') {
        pauseSubscriptionInStripeAndDb(order?.Payment?.SubscriptionId);
      } else if (pauseOrCancel === 'resume') {
        resumeSubscriptionInStripeAndDb(order?.Payment?.SubscriptionId);
      }
    }
    onModalClose();
  };
  
  async function cancelSubscriptionInStripeAndDb(subscriptionId: string) {
    try {
      await axios.post(`${ordersBaseURL}subscriptions/${subscriptionId}/cancel`);
      setShowAlertSuccess(true);
       window.location.reload();
    } catch (error) {
      console.error('Subscription error:', error);
      setShowAlertError(true);
    }
  }

  async function pauseSubscriptionInStripeAndDb(subscriptionId: string) {
    try {
      await axios.post(`${ordersBaseURL}subscriptions/${subscriptionId}/pause`);
      setShowAlertSuccess(true);
       window.location.reload();
    } catch (error) {
      console.error('Subscription error:', error);
      setShowAlertError(true);
    }
  }

  async function resumeSubscriptionInStripeAndDb(subscriptionId: string) {
    try {
      await axios.post(`${ordersBaseURL}subscriptions/${subscriptionId}/resume`);
      setShowAlertSuccess(true);
      window.location.reload();
    } catch (error) {
      console.error('Subscription error:', error);
      setShowAlertError(true);
    }
  }

  const confirmModalText = confirmationTexts[pauseOrCancel] || '';

  return (
    <div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2> {confirmModalText}</h2>
            <button
              className="confirm-button"
              onClick={handleConfirmButtonClick}
            >
              Confirm
            </button>
            <button className="close-button" onClick={handleCloseModal}>
              Close
            </button>
            {showAlertError && <AlertBanner alertType='error' alertMessage='error' />
            }
            {
              showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmCancelOrPause;
