import './dropdown-input.styles.scss';
import React from 'react';
import { ProvinceWithCountry } from '../../../types/context-types/api.data.context.types';

type DropdownInputProps = {
    name: string,
    id: string,
    defaultValue: string,
    isRequired: boolean,
    isHidden: boolean,
    label: string,
    errorMessage: string,
    handleDropdownChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    options: Options[]

} & React.ComponentProps<'select'>

type BasicOptions = { name: string, id: number }

type Options = ProvinceWithCountry | BasicOptions


const DropdownInput = ({ options, label, id, handleDropdownChange, name, defaultValue, isRequired, isHidden, errorMessage }: DropdownInputProps) => {

    return (
        <div className='select-input-container'>
            <select className={`select-input ${errorMessage ? 'error' : null}`} id={id} onChange={handleDropdownChange} name={name} value={defaultValue}>
                {
                    !defaultValue &&
                    <option value="" disabled>Choose an option</option>
                }
                {
                    options.map((option) => {
                        if ('Name' in option) {
                            const { Name, Id, Abbreviation } = option as ProvinceWithCountry;
                            return <option key={Id} data-id={Id} data-name={Name} data-abbreviation={Abbreviation} value={Name}>{Name}</option>;
                        } else {
                            const { name, id } = option as BasicOptions;
                            return <option key={id} data-id={id} data-name={name} value={id}>{name}</option>;
                        }
                    })
                }

            </select>
            <label className='shrink select-input-label'
                htmlFor={id}>{label}</label>

            {errorMessage && <p className='error-message'>{errorMessage}</p>}
        </div>

    )
}

export default DropdownInput;