import './sign-up-from.styles.scss';
import { useState, useContext } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';
import { SignUpFormType } from '../../../types/context-types/auth.context.types';
import { AuthContext } from '../../../contexts/auth.context';
import AlertBanner from '../../mui/alert.component';
import LoadingSpinner from '../../mui/spinner.component';
import { emailRegex, passwordRegex } from '../../../constants/regex.utils';
import { stripeInstance } from '../../../services/stripe/stripe.utils';
import Stripe from 'stripe';



const defaultSignUpFields: SignUpFormType = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
}

const SignUpForm = () => {

    const [formFields, setFormFields] = useState(defaultSignUpFields);
    const [errors, setErrors] = useState<SignUpFormType>({ ...defaultSignUpFields });
    const { firstName, lastName, email, password, confirmPassword } = formFields;
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const { setSignUpData, setIsAutenticated } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let username = email
        let given_name = firstName
        let family_name = lastName

        const hasError = validate();
        if (!hasError) {
            try {
                setIsLoading(true);
                // const signup_data= await Auth.signUp(email, password);
                const signup_data = await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email,
                        given_name,
                        family_name
                    }
                });
                setSignUpData(formFields);
                setIsAutenticated(true);
                sessionStorage.setItem('biglocal-session-key', "true");
                navigate('/auth/verify');
            } catch (error: any) {
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(error.message);
                console.log("Error in SignUp ", error.message)
            }

        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!')
            console.error("Invalid Form");
        }
    }

    const handleSignIp = () => {
        navigate('/signin')
    }

    const createStripeCustomer = async () => {

        const customer = await stripeInstance?.customers.create({
            email: email,
            name: `${firstName} ${lastName}`
          });
        return customer
    }

    

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        !value && setErrors({ ...errors, [name]: `This is a required field!` });
        if (email && !emailRegex.test(email)) {
            setErrors({ ...errors, email: 'Email is invalid!' });
        }
        if (password && !passwordRegex.test(password)) {
            setErrors({ ...errors, password: 'Password must contain at least 8 characters, including uppercase and lowercase letters, and a number!' });
        };
        if (confirmPassword && !passwordRegex.test(confirmPassword)) {
            setErrors({ ...errors, confirmPassword: 'ConfirmPassword must contain at least 8 characters, including uppercase and lowercase letters, and a number!' });
        };
        if ((password && confirmPassword) && password !== confirmPassword) {
            setErrors({ ...errors, confirmPassword: 'Password mismatch!' });
        }
    }

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
    };

    let tempErrors = { ...defaultSignUpFields };
    const validate = () => {
        let hasError = false;


        if (!firstName) {
            tempErrors.firstName = "First Name is required!";
            hasError = true;
        }
        if (!lastName) {
            tempErrors.lastName = "Last Name is required!";
            hasError = true;
        }
        if (!email) {
            tempErrors.email = "Email is required!";
            hasError = true;
        }
        else if (!emailRegex.test(email)) {
            tempErrors.email = "Email is invalid!";
            hasError = true;
        }
        if (!password) {
            tempErrors.password = "Password is required!";
            hasError = true;
        }
        else if (!passwordRegex.test(password)) {
            tempErrors.password = "Password must contain at least 8 characters, including uppercase and lowercase letters, and a number!"
            hasError = true;
        }
        if (!confirmPassword) {
            tempErrors.confirmPassword = "Confirm Password is required!";
            hasError = true;
        }
        else if (!passwordRegex.test(confirmPassword)) {
            tempErrors.confirmPassword = "Confirm password must contain at least 8 characters, including uppercase and lowercase letters, and a number!"
            hasError = true;
        }
        else if (password !== confirmPassword) {
            tempErrors.confirmPassword = "Password mismatch!"
            hasError = true;
        }
        setErrors(tempErrors);
        return hasError;
    };

    return (
        <div className='auth-form-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            <h2>Don't have an account?</h2>
            <span>Sign up with your email and passowrd </span>
            <form onSubmit={handleSubmit}>
                <FormInputText
                    label='First Name'
                    type='text'
                    onChange={handleChange}
                    name='firstName'
                    value={firstName}
                    errorMessage={errors?.firstName}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='Last Name'
                    type='text'
                    onChange={handleChange}
                    name='lastName'
                    value={lastName}
                    errorMessage={errors?.lastName}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='Email'
                    type='text'
                    onChange={handleChange}
                    name='email'
                    value={email}
                    errorMessage={errors?.email}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='Password'
                    type='password'
                    onChange={handleChange}
                    name='password'
                    value={password}
                    errorMessage={errors?.password}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='Confirm Password'
                    type='password'
                    onChange={handleChange}
                    name='confirmPassword'
                    value={confirmPassword}
                    errorMessage={errors?.confirmPassword}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <div className="auth-buttons-container">
                    <FormButton type='submit'>SignUp</FormButton>
                    <h3 onClick={handleSignIp}>Return to Signin? </h3>
                </div>


            </form>
        </div>

    )
}

export default SignUpForm;
