import { useState } from 'react';
import OrdersHeadingBar from '../orders-heading-bar/order-details-bar.component';
import './orders-type-tabs.styles.scss';

interface OrdersManagementComponentProps {
  subscriptionid?: string;
}

const OrderTypeTabs: React.FC<OrdersManagementComponentProps> = ({ subscriptionid }) => {
 
  const [activeButton, setActiveButton] = useState('active');

  const handleButtonClick = (buttonValue: string) => {
    setActiveButton(buttonValue);
  };

  const buttonData = [
    { value: 'active', label: 'Active Orders' },
    { value: 'fulfilled', label: 'Fulfilled Orders' },
    { value: 'upcoming', label: 'Upcoming Orders' }
  ];

  return (
    <div className='orderbuttons'>
      {buttonData.map(({ value, label }) => (
        <button
          key={value}
          className={`custom-button ${activeButton === value ? 'active' : ''}`}
          onClick={() => handleButtonClick(value)}
        >
          {label}
        </button>
      ))}

      {subscriptionid !== undefined ? (
        <OrdersHeadingBar activeButton={activeButton} subscriptionid={subscriptionid} />
      ) : (
        <OrdersHeadingBar activeButton={activeButton} />
      )}

    </div>
  );
};

export default OrderTypeTabs;
