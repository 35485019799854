import axios, { AxiosResponse } from "axios";
import {
  Country,
  ProvinceWithCountry,
} from "../../types/context-types/api.data.context.types";
import { CustomerData } from "../../types/context-types/customer.context.types";
import { sendErrorMessageInSlack } from "../../utils/miscellaneous.functions";

let ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string;
let customersBaseURL = process.env.REACT_APP_CUSTOMERS_BASE_URL as string;

export const getData = async <T>(
  url: string
): Promise<AxiosResponse<T> | undefined> => {
  const response = await axios.get(url);
  return response;
};

export const getAuthorizedData = async <T>(
  url: string,
  token: string
): Promise<AxiosResponse<T> | any> => {
  try {
    const response = await axios.get<T>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (e: any) {
    console.log(e, "Error in getting data from auth endpoints");
    return e.response.status;
  }
};

export const getDeliveryDatesAvailable = async (): Promise<any> => {
  const deliveryDatesResponse = await getData(`${ordersBaseURL}groups`);
  return deliveryDatesResponse;
};
export const getProvinces = async (): Promise<any> => {
  return await getData(`${customersBaseURL}provinces`);
};
export const getCountries = async (): Promise<Country[] | any> => {
  return await getData(`${customersBaseURL}countries`);
};
export const getProvincesByCountrySelected = async (countryId: number) => {
  //return list of provinces based on country
};
export const getCitiesByProvinceSelected = async (provinceId: number) => {
  //return list of cities based on province
};

export const getCustomerData = async (
  cognitoUserId: string,
  token: string
): Promise<AxiosResponse<CustomerData> | any> => {
  try {
    const customerDataResponse = await getAuthorizedData(
      `${customersBaseURL}customers/user/${cognitoUserId}`,
      token
    );
    return customerDataResponse;
  } catch (e) {
    console.log(e, "Error in getting customer data");
    return e as any;
  } finally {
    sendErrorMessageInSlack(
      " url To Get Customer Data",
      `${customersBaseURL}customers/user/${cognitoUserId}`
    );
  }
};

export const fetchItemSelectionsPageData = async (
  checkoutID: string
): Promise<any> => {
  const orderInfo = await getData(
    `${ordersBaseURL}orders/${checkoutID}/itemselection`
  );
  return orderInfo;
};

export const getCustomerOrderDetails = async (customerId: string) => {
  const customerOrderDetailsResponse = await getData(
    `${ordersBaseURL}orders/customer/${customerId}`
  );
  return customerOrderDetailsResponse;
};
