export const calcuatePaidAndCartTotalAmounts = (balance: number, subscriptionTransactions: any[]) => {

    let paidAmount = 0
    let initialCartTotal = 0

    subscriptionTransactions.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    if (subscriptionTransactions.length === 1) {
        paidAmount = subscriptionTransactions[0].Balance
        initialCartTotal = 0

    } else {
        if (balance > 0) {
            paidAmount = subscriptionTransactions[0].Debit + subscriptionTransactions[0].Balance
            initialCartTotal = subscriptionTransactions[0].Debit


        } else if (balance == 0) {
            const debitCreditTotals = subscriptionTransactions.reduce(
                (accumulator, currentValue) => {
                    accumulator.debitTotal += currentValue.Debit;
                    accumulator.creditTotal += currentValue.Credit;
                    return accumulator;
                },
                { debitTotal: 0, creditTotal: 0 }
            );

            paidAmount = debitCreditTotals.creditTotal
            initialCartTotal = debitCreditTotals.creditTotal - balance
        }
        else {
            paidAmount = subscriptionTransactions[0].Debit + subscriptionTransactions[0].Balance
            initialCartTotal = subscriptionTransactions[0].Debit
        }
    }


    console.log(paidAmount, 'Paid Amount')
    console.log(initialCartTotal, 'initialCartTotal')

    return {
        paidAmount: paidAmount, initialCartTotal: initialCartTotal
    }

}