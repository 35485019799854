import './navigation.styles.scss';
import { Outlet, Link } from 'react-router-dom'
import { Fragment } from 'react';
import { ReactComponent as BigLocalLogo } from '../../assets/biglocallogo.svg';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth.context';
import { Auth } from 'aws-amplify';
import { CartContext } from '../../contexts/cart.context';
import { ModalContext } from '../../contexts/modal.context';
import { StripeContext } from '../../contexts/stripe.context';
import CartIcon from '../../components/cart/cart-icon/cart-icon.component';
import { clearCartItemRelatedLocalStorage } from '../../utils/localstorage.functions';


const Navigation = () => {

    const { isAuthenticated, setIsAutenticated } = useContext(AuthContext);
    const { isCartOpen, clearCart } = useContext(CartContext);
    const { resetModalContext } = useContext(ModalContext);
    const { stripeCustomerId } = useContext(StripeContext);

  
    
    const handleSignOut = () => {
        try {
            Auth.signOut();
            setIsAutenticated(false);
            localStorage.clear();
            sessionStorage.clear();
            clearCart();
            resetModalContext();
               

        } catch (error) {
            console.log("Error in SignOut ", error)
        }
    }

    const resetIsAuthFromModal = () => {

        if (!stripeCustomerId) {
            localStorage.clear();
        } else {
            clearCartItemRelatedLocalStorage()
        }
        localStorage.setItem('isAuthFromModal', 'false')
    }


    return (
        <Fragment>
            <div className='navigation'>
                <Link className='logo-container' to='/'>
                    <BigLocalLogo className='logo' />
                </Link>
                <div className='nav-links-container'>
                    <Link className='nav-link' onClick={resetIsAuthFromModal} to='/shop'>Shop</Link>
                    {
                        isAuthenticated ?
                            <Link className='nav-link' onClick={handleSignOut} to='/'>SignOut</Link> :
                            <Link className='nav-link' to='/signin'>Sign-In</Link>
                    }
                    {/* <CartIcon /> */}
                </div>
            </div>
            <Outlet />
        </Fragment>
    )

}

export default Navigation;