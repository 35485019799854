import './email-verification-from.styles.scss';
import { useState, useContext, useEffect } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth.context';
import AlertBanner from '../../mui/alert.component';
import LoadingSpinner from '../../mui/spinner.component';
import { emailRegex } from '../../../constants/regex.utils';
import { createStripeCustomer } from '../../../services/stripe/stripe.utils';
import { StripeContext } from '../../../contexts/stripe.context';
import axios from 'axios';
import { sendErrorMessageInSlack } from '../../../utils/miscellaneous.functions';
import Stripe from 'stripe';

type VerifyFormFieldsType = {
    email: string,
    code: string,
}

const defaultVerifyFields: VerifyFormFieldsType = {
    email: '',
    code: '',

}

let customersBaseURL = process.env.REACT_APP_CUSTOMERS_BASE_URL as string


const EmailVerificationForm = () => {

    // useEffect(()=>{
    //     if (isChangingEmail) {
    //         Auth.verifyCurrentUserAttributeSubmit("email", code);
    //     }
    // },[])

    const [formFields, setFormFields] = useState(defaultVerifyFields);
    const [errors, setErrors] = useState<VerifyFormFieldsType>({ ...defaultVerifyFields });
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { email, code } = formFields;
    const { signupData, setUserSessionObject, isChangingEmail, setCognitoUserId } = useContext(AuthContext);
    const { setStripeCustomerId } = useContext(StripeContext)
    const { password } = signupData
    const signupEmail = signupData.email
    const fullName = signupData.firstName + " " + signupData.lastName

    const navigate = useNavigate()

    let userSession = null;
    let idToken = null;
    let cognitoUserId: any = null;
    let backendResponse: any


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const sendStripeCustomerIdToBackend = async (customerId: string, cognitoUserId: string) => {

        try {
            sendErrorMessageInSlack(`SENDING CUSTOMER DATA TO BACKEND STARTED ==> ${customerId} :::`, cognitoUserId) // NEED TO REMOVE. FOR DEBUGGING ONLY
            let url = `${customersBaseURL}signup`
            let signUpPayload = {
                userName: cognitoUserId,
                request: {
                    userAttributes: {
                        customerid: customerId,
                        email: signupEmail,
                        given_name: signupData.firstName,
                        family_name: signupData.lastName
                    }
                }
            }
            const response = await axios.post(url, signUpPayload);
            return response


        } catch (error) {
            sendErrorMessageInSlack(`Error in sending Signup data to backend ==>`, error)
        }
    }


    const createCognitoID = (userSessionObject: any) => {
        setUserSessionObject(userSessionObject);
        localStorage.setItem('userSessionObject', JSON.stringify(userSessionObject));
        userSession = userSessionObject?.getSignInUserSession();
        idToken = userSession?.getIdToken();
        cognitoUserId = idToken?.payload['cognito:username'];
        localStorage.setItem('cognitoUserId', cognitoUserId);
        return cognitoUserId
    }

    const setStripeCustomerDetails = async (stripeCustomerObject: Stripe.Response<Stripe.Customer>) => {
        setStripeCustomerId(stripeCustomerObject.id)
        localStorage.setItem('stripeCustomerId', stripeCustomerObject.id)
        backendResponse = await sendStripeCustomerIdToBackend(stripeCustomerObject.id, cognitoUserId);
        return backendResponse
    }


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hasError = validate();
        if (!hasError) {
            try {
                setIsLoading(true);
                if (isChangingEmail) {
                    sendErrorMessageInSlack(`Change email path`, {}) // NEED TO REMOVE. FOR DEBUGGING ONLY
                    await Auth.verifyCurrentUserAttributeSubmit("email", code);
                    await Auth.currentAuthenticatedUser().then((userSessionObject) => {
                        createCognitoID(userSessionObject)
                    });;

                } else {
                    if (email) {
                        sendErrorMessageInSlack(`NEW USER SIGNUP - EMAIL `, email) // NEED TO REMOVE. FOR DEBUGGING ONLY
                        await Auth.confirmSignUp(email, code)
                        await Auth.signIn(email, password).then((userSessionObject) => {
                            createCognitoID(userSessionObject)
                        });
                        await createStripeCustomer(email, fullName).then(async (stripeCustomerObject) => {
                            if (stripeCustomerObject) {
                                backendResponse = await setStripeCustomerDetails(stripeCustomerObject);
                            }
                        })
                    } else {
                        sendErrorMessageInSlack(`NEW USER SIGNUP - SIGNUP-EMAIL`, signupEmail) // NEED TO REMOVE. FOR DEBUGGING ONLY
                        await Auth.confirmSignUp(signupEmail, code);
                        await Auth.signIn(signupEmail, password).then((userSessionObject) => {
                            createCognitoID(userSessionObject)
                        });;
                        await createStripeCustomer(signupEmail, fullName).then(async (stripeCustomerObject) => {
                            if (stripeCustomerObject) {
                                backendResponse = await setStripeCustomerDetails(stripeCustomerObject);
                            }
                        })

                    }
                }

                let isAuthFromModal = localStorage.getItem('isAuthFromModal')

                if (isAuthFromModal === 'true' && (backendResponse.status === 200 || backendResponse.status === 201)) {
                    navigate('/shop');
                    sessionStorage.setItem('biglocal-session-key', "true");
                    window.opener.location.reload();
                    window.close();

                } else {
                    navigate('/orders')
                    sessionStorage.setItem('biglocal-session-key', "true");
                }
            }
            catch (error: any) {
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(error.message);
                sendErrorMessageInSlack(`Error in Verification ==>`, error.message) // NEED TO REMOVE. FOR DEBUGGING ONLY
                console.log("Error in Verification ", error.message)
            }
        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!')
            console.error("Invalid Form");
        }
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        !value && setErrors({ ...errors, [name]: 'This is a required field' });
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
    };



    const validate = () => {
        let tempErrors = { ...defaultVerifyFields };
        let hasError = false;
        if (!email && !signupEmail) {
            tempErrors.email = "Email is required";
            hasError = true;
        }
        else if (!emailRegex.test(email) && !emailRegex.test(signupEmail)) {
            tempErrors.email = "Email is invalid";
            hasError = true;
        }
        if (!code) {
            tempErrors.code = "Verification code is required";
            hasError = true;
        }
        setErrors(tempErrors);
        return hasError;
    };

    return (
        <div className='Verify-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            <h2>Got the verification code?</h2>
            <span>Continue to signup </span>
            <form onSubmit={handleSubmit}>
                <FormInputText
                    label='Email'
                    type='text'
                    onChange={handleChange}
                    name='email'
                    value={email || signupEmail}
                    errorMessage={errors?.email}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='Verification Code'
                    type='text'
                    onChange={handleChange}
                    name='code'
                    value={code}
                    errorMessage={errors?.code}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <div className='auth-buttons-container'>
                    <FormButton type='submit'>Verify</FormButton>
                    {/* <FormButton type='submit'>Forgot Password</FormButton> */}
                </div>


            </form>
        </div>

    )
}

export default EmailVerificationForm;