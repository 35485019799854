import './modal-template.styles.scss';
import ModalCard from '../modal-card/modal-card.component';
import { ModalCards } from '../../../types/modal-types/modal.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useContext } from 'react';
import { ModalContext } from '../../../contexts/modal.context';
import DateSelector from '../../date-picker/datepicker.component';
import { StripeProductDataCombined } from '../../../types/context-types/stripe.context.types';
import { CartContext } from '../../../contexts/cart.context';
import AuthOptions from '../auth-modal/auth-options.component';
import ShippingForm from '../../forms/shipping-form/shipping-data-form.component';


type ModalTemplateProps = {
    isBackButtonAllowed: boolean,
    modalHeading: string,
    handleBackButtonPress: () => void,
    modalNavigationTo?: string,
    type?: string,
    modalCards?: ModalCards,
    stripeProductsCard?: StripeProductDataCombined[]
    // stripeProductsCard?: Stripe.Price[]
    handleCloseModal: () => void
    shippingForm?: React.ReactNode
    isShippingPage?: boolean
}

const ModalTemplate = ({ isBackButtonAllowed, modalHeading, handleBackButtonPress, modalNavigationTo, type, modalCards, handleCloseModal, stripeProductsCard, isShippingPage }: ModalTemplateProps) => {

    const { setModalCategory, citySelected, countrySelected, provinceSelected, orderTypeSelected, deliveryDateSelected, productSelected, isNavButtonActive, setIsNavButtonActive, setModalSummaryMessage, modalSummaryMessage, selectedStripeProductFromModal, resetModalContext, isEditModalFlow } = useContext(ModalContext);

    const { addItemToCart, clearCart } = useContext(CartContext);

    useEffect(() => {
        let summaryMessage: string = ''

        if (citySelected.name) {
            summaryMessage += citySelected.name
            setModalSummaryMessage(summaryMessage);

        }
        if (orderTypeSelected.name) {

            summaryMessage += ` | ${orderTypeSelected.name}`
            setModalSummaryMessage(summaryMessage);
        }
        if (deliveryDateSelected) {
            summaryMessage += ` | ${deliveryDateSelected}`
            setModalSummaryMessage(summaryMessage);
        }
        if (productSelected) {
            summaryMessage += ` | ${productSelected}`
            setModalSummaryMessage(summaryMessage);
        }
    }, [citySelected, orderTypeSelected, deliveryDateSelected, productSelected])

    const handleNextButtonPress = () => {
        setModalCategory(modalNavigationTo as string);
        setIsNavButtonActive(false);
        if (selectedStripeProductFromModal) {
            clearCart();
            addItemToCart({
                Name: selectedStripeProductFromModal.name as string,
                ProductId: selectedStripeProductFromModal.productId as string,
                PriceId: selectedStripeProductFromModal.id as string,
                Price: selectedStripeProductFromModal.unit_amount/100 as number,
                ImageUrl: selectedStripeProductFromModal.imageUrl as string,
                Quantity: 1,
                OrderType: orderTypeSelected.id,
                OrderTypeName: orderTypeSelected.name
            });
        }
        switch (modalNavigationTo) {
            case 'countries':
                countrySelected && setIsNavButtonActive(true);
                break;
            case 'provinces':
                provinceSelected && setIsNavButtonActive(true);
                break;
            case 'cities':
                citySelected && setIsNavButtonActive(true);
                break;
            case 'orderType':
                orderTypeSelected.name && setIsNavButtonActive(true);
                break;
            case 'avaialableDates':
                deliveryDateSelected && setIsNavButtonActive(true);
                break;
            case 'productSelection':
                productSelected && setIsNavButtonActive(true);
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <div className='modal-header'>
                <h2> {modalHeading}</h2>
                {<FontAwesomeIcon className='close-button-icon' onClick={handleCloseModal} icon={faTimes} />}
            </div>
            <div className='modal-body'>
                {(modalCards && (type !== 'avaialableDates')) &&
                    modalCards?.map((modalCard) => {
                        return (
                            <div key={modalCard.id} >
                                <ModalCard modalValue={{ name: modalCard.name, id: modalCard.id, abbrivation: modalCard.abbrivation }} modalType={type as string}
                                >
                                    {modalCard.name}
                                </ModalCard>
                            </div>
                        )
                    })}
                {stripeProductsCard &&
                    stripeProductsCard?.map((stripeProductCard) => {
                        return (
                            <div key={stripeProductCard.id} >
                                <ModalCard
                                    modalValue={{ name: stripeProductCard.name as string, id: stripeProductCard.id }}
                                    stripeProductSelected={stripeProductCard} modalType={type as string}
                                >{stripeProductCard.name as string || 'NAME NOT FOUND'}
                                </ModalCard>
                            </div>
                        )
                    })
                }
                {
                    (type === 'shippingData' || isShippingPage) &&
                    <ShippingForm />
                }
                {
                    (type === 'avaialableDates') &&
                    <DateSelector />
                }
                {

                    (type === 'auth' && !isShippingPage) &&
                    <AuthOptions />

                }
            </div>
            <div className='modal-footer'>
                {isBackButtonAllowed && <FontAwesomeIcon className='back-button-icon' onClick={handleBackButtonPress} icon={faArrowLeft} />}
                <h3>{modalSummaryMessage}</h3>
                {<FontAwesomeIcon className={`next-button-icon ${isNavButtonActive ? 'active' : null}`} onClick={handleNextButtonPress} icon={faArrowRight} />}
            </div>
        </div>
    )
}

export default ModalTemplate;