import { Routes, Route } from 'react-router-dom'
import LandingPage from "./routes/landing/landing.component";
import Shop from './routes/shop/shop.component';
import Navigation from './routes/navigation/navigation.component';
import AuthPage from './routes/auth/auth.component';
import SubscriptionsSummaryPage from './routes/subscription-summary/subscription-summary.component';
import SignInForm from './components/forms/signin-form/sign-in-form.component';
import SignUpForm from './components/forms/signup-form/sign-up-form.componet';
import EmailVerificationForm from './components/forms/email-verification/email-verification-form.component';
import EmailChangeForm from './components/forms/email-change/email-change.component';
import CustomerOrdersPage from './routes/orders/orders.component';
import ForgotPasswordForm from './components/forms/forgot-password-form/forgot-password-form.component';
import ForgotPasswordSubmitForm from './components/forms/forgot-password-sumbit-form/forgot-password-submit-form.component';
import BuildABoxPage from './routes/build-a-box-page/build-a-box.component';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { AuthContext } from './contexts/auth.context';
import { StripeContext } from './contexts/stripe.context';
import AddOnSummary from './routes/addon-summary/addon-summary.component';
import { clearLocalStorage } from './utils/localstorage.functions';
import OneTimePayments from './components/payments/addon-payments-form/payments.component';
import OrderConfirmationPage from './routes/order-confirmation/order.confirmation.component';
import ManageSubscriptionPage from './routes/manage-subscriptions/manage-subscription.component';

const App = () => {

  const { setStripeCustomerId } = useContext(StripeContext);

  useEffect(() => {
    checkAuthState();
    handleBeforeUnload();
    window.addEventListener("beforeunload", handleBeforeUnload);
    setStripeCustomerIdFromLocalStorage();

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      let isAuthenticatedSession = sessionStorage.getItem('biglocal-session-key') === "true";
      let isGuestCheckout = localStorage.getItem('guestCheckout') === "true";
      let isBuildABoxPage = localStorage.getItem('isBuildABoxPage') === "true";

      if (!isAuthenticatedSession && !isGuestCheckout && !isBuildABoxPage) {
        clearLocalStorage();
      }
    };
  }, []);


  const checkAuthState = async () => {
    console.log('checking auth data')
    try {
      const authData = await Auth.currentAuthenticatedUser();
      console.log('inside CheckAuthState Try block', authData)
      setIsAutenticated(true);
    } catch (error) {
      console.log("Not an authenticated user...Redirecting to home authpage", error)
      setIsAutenticated(false);
    }
  }

  const setStripeCustomerIdFromLocalStorage = async () => {
    let customerId = localStorage.getItem('stripeCustomerId')
    setStripeCustomerId(customerId as string)
  }

  const handleBeforeUnload = () => {
    let isGuestCheckout = localStorage.getItem('guestCheckout') === "true"
    let isAuthenticatedSession = sessionStorage.getItem('biglocal-session-key') === "true";
    let isBuildABoxPage = localStorage.getItem('isBuildABoxPage') === "true";

    if (!isAuthenticatedSession && !isGuestCheckout && !isBuildABoxPage) {
      clearLocalStorage();
    }
  }



  const { setIsAutenticated } = useContext(AuthContext);

  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<LandingPage />} />
        <Route path='shop' element={<Shop />} />
        <Route path='auth' element={<AuthPage />} />
        <Route path='sub-summary' element={<SubscriptionsSummaryPage />} />
        <Route path='signin' element={<SignInForm />} />
        <Route path='signup' element={<SignUpForm />} />
        <Route path='orders/:subscriptionid?' element={<CustomerOrdersPage />} />

        <Route path='manage-subscription' element={<ManageSubscriptionPage />} />

        <Route path='auth/verify' element={<EmailVerificationForm />} />
        <Route path='auth/changeEmail' element={<EmailChangeForm />} />
        <Route path='auth/forgot_password_code' element={<ForgotPasswordForm />} />
        <Route path='auth/forgot_password_submit' element={<ForgotPasswordSubmitForm />} />
      </Route>
      <Route path='build-a-box/:checkoutID' element={<BuildABoxPage />} />
      <Route path='build-a-box/:checkoutID/summary/:client_secret' element={<AddOnSummary />} />
      <Route path='build-a-box/:checkoutID/summary/:client_secret/paynow' element={<OneTimePayments />} />
      <Route path='build-a-box/:checkoutID/orderconfirmation' element={<OrderConfirmationPage />} />
    </Routes>

  );
}

export default App;