import './button.styles.scss';

type ButtonProps = {
    children: string,
} & React.ComponentProps<'button'>


const Button = ({ children, ...otherProps }: ButtonProps) => {

    return (
        <button className='custom-button-container' {...otherProps}>{children}</button>

    )
}

export default Button;