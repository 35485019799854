import './forgot-password-submit-form.styles.scss';
import { useState, useContext } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth.context';
import AlertBanner from '../../mui/alert.component';
import LoadingSpinner from '../../mui/spinner.component';
import { emailRegex, passwordRegex } from '../../../constants/regex.utils';


type ForgotPasswordSubmitType = {
    email: string,
    newPassword: string,
    code: string
}

const defaultForgotPasswordSubmitFields: ForgotPasswordSubmitType = {
    email: '',
    newPassword: '',
    code: ''
}


const ForgotPasswordSubmitForm = () => {

    const [formFields, setFormFields] = useState(defaultForgotPasswordSubmitFields);
    const [errors, setErrors] = useState<ForgotPasswordSubmitType>({ ...defaultForgotPasswordSubmitFields });
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const { email, newPassword, code } = formFields;

    const { setUserSessionObject, passwordResetEmail } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hasError = validate();
        if (!hasError) {
            try {
                setIsLoading(true);
                let userSessionObject;
                if (email) {
                    await Auth.forgotPasswordSubmit(email, code, newPassword);
                    userSessionObject = await Auth.signIn(email, newPassword);
                }
                else {
                    await Auth.forgotPasswordSubmit(passwordResetEmail, code, newPassword);
                    userSessionObject = await Auth.signIn(passwordResetEmail, newPassword);
                }

                setUserSessionObject(userSessionObject);
                sessionStorage.setItem('biglocal-session-key', 'true');
                localStorage.setItem('userSessionObject', JSON.stringify(userSessionObject))
                let isAuthFromModal = localStorage.getItem('isAuthFromModal');
                if (isAuthFromModal === 'true') {
                    navigate('/shop');
                    sessionStorage.setItem('biglocal-session-key', "true");
                    window.opener.location.reload();
                    window.close();
                } else {
                    navigate('/orders');
                    sessionStorage.setItem('biglocal-session-key', "true");
                }

            } catch (error: any) {
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(error.message);
                console.log("Error in password reset! ", error.message)
            }

        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!')
            console.error("Invalid Form");
        }
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        !value && setErrors({ ...errors, [name]: `This is a required field!` });
        if (email && !emailRegex.test(email)) {
            setErrors({ ...errors, email: 'Email is invalid!' });
        }
        if (newPassword && !passwordRegex.test(newPassword)) {
            setErrors({ ...errors, newPassword: 'Password must contain at least 8 characters, including uppercase and lowercase letters, and a number!' });
        };
    }

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
    };

    let tempErrors = { ...defaultForgotPasswordSubmitFields };
    const validate = () => {
        let hasError = false;

        if (!email && !passwordResetEmail) {
            tempErrors.email = "Email is required!";
            hasError = true;
        }
        else if (!emailRegex.test(email) && !emailRegex.test(passwordResetEmail)) {
            tempErrors.email = "Email is invalid!";
            hasError = true;
        }
        if (!code) {
            tempErrors.code = "Reset code is required!";
            hasError = true;
        }
        if (!newPassword) {
            tempErrors.newPassword = "New Password is required!";
            hasError = true;
        }
        else if (!passwordRegex.test(newPassword)) {
            tempErrors.newPassword = "Password must contain at least 8 characters, including uppercase and lowercase letters, and a number!"
            hasError = true;
        }
        setErrors(tempErrors);
        return hasError;
    };


    const handleRequestCode = () => {
        navigate('/auth/forgot_password_code')
    }

    return (
        <div className='forgot-password-submit-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            <h2>Got the password reset code?</h2>
            <span>Set a new password </span>
            <form onSubmit={handleSubmit}>

                <FormInputText
                    label='Email'
                    type='text'
                    onChange={handleChange}
                    name='email'
                    value={email || passwordResetEmail}
                    errorMessage={errors?.email}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='New Password'
                    type='password'
                    onChange={handleChange}
                    name='newPassword'
                    value={newPassword}
                    errorMessage={errors?.newPassword}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='Reset Code'
                    type='text'
                    onChange={handleChange}
                    name='code'
                    value={code}
                    errorMessage={errors?.code}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <div className='password-reset-button-container'>
                    <FormButton type='submit'>Reset Password</FormButton>
                    <FormButton onClick={handleRequestCode}>Request a code</FormButton>

                </div>

            </form>
        </div>

    )
}

export default ForgotPasswordSubmitForm;