export const CITIES = [
    {
        id: 1,
        name: 'Winnipeg'
    },
    {
        id: 2,
        name: 'Brandon'
    },
    {
        id: 3,
        name: 'Headingley'
    },
    {
        id: 4,
        name: 'Selkirk'
    },
    {
        id: 5,
        name: 'Winkler area'
    },
    {
        id: 6,
        name: 'Gimli'
    },
    {
        id: 7,
        name: 'Hecla'
    },
    {
        id: 8,
        name: 'Lac du Bonnet'
    },
    {
        id: 9,
        name: 'Pinawa'
    },

]

export const ORDER_TYPES = [
    {
        id: 1,
        name: 'Regular Subscription'
    },
    {
        id: 2,
        name: 'Gift Subscription'
    },
    {
        id: 3,
        name: 'One Time Purchase'
    },
]

export const ORDER_TYPES_GUEST = [
    {
        id: 2,
        name: 'Gift Subscription'
    },
    {
        id: 3,
        name: 'One Time Purchase'
    },
]

export const PROVINCES = [
    {
        id: 1,
        name: 'Alberta'
    },
    {
        id: 2,
        name: 'British Columbia'
    },
    {
        id: 3,
        name: 'Manitoba'
    },
    {
        id: 4,
        name: 'New Brunswick'
    },
    {
        id: 5,
        name: 'Newfoundland and Labrador'
    },
    {
        id: 6,
        name: 'Northwest Territories'
    },
    {
        id: 7,
        name: 'Nova Scotia'
    },
    {
        id: 8,
        name: 'Nunavut'
    },
    {
        id: 9,
        name: 'Ontario'
    },
    {
        id: 10,
        name: 'Prince Edward Island'
    },
    {
        id: 11,
        name: 'Quebec'
    },
    {
        id: 12,
        name: 'Saskatchewan'
    },
    {
        id: 13,
        name: 'Yukon'
    },


]


export const RESIDENCE_OPTIONS = [{
    id: 1,
    name: 'Home'
}, {
    id: 2,
    name: 'Apartment'
}, {
    id: 3,
    name: 'Office'
}]

// export const AVAIALBALE_DATES = [
//     {
//         id: 1,
//         name: '30th Jan 2023'
//     },
//     {
//         id: 2,
//         name: '4th Feb 2023'
//     },
//     {
//         id: 3,
//         name: '14th Feb 2023'
//     },
// ]


// export const SUB_TYPES = [
//     {
//         id: 1,
//         name: '$30 Subscription'
//     },
//     {
//         id: 2,
//         name: '$70 Subscription'
//     },
//     {
//         id: 3,
//         name: '$100 Subscription'
//     },
// ]