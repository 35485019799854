import React, { useContext } from "react";
import "./order-details-dropdown.styles.scss";
import EditDeliveryDetails from "../../modal/delivery-date-modal/edit-date-modal.component";
import OrderCard from "./dropdown-content-card-child";
import { ManageSubscriptionContext } from '../../../contexts/manageSubscription.context';
import ConfirmCancelOrPause from "../../modal/cancel-pause-subscription-modal/confirm-cancel-pause-subscription-modal";

interface DropdownContentCardProps {
  heading?: string;
  address?: string;
  date?: string | null;
  time?: string | null;
  isOrderFulfilled?: boolean;
  orderStatus?: number;
  skipOrder?: boolean;
  order?: any;
  updateOrdersState?: any;
  handleEditClick?: () => void;
  handleEditOrder?: () => void;
  handleAddonClick?: () => void;
  isEditDateButtonClicked?: boolean;
  setIsEditDateButtonClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  description?: string;
  manageSubscription?: string;
}

interface DropdownContentCardData {
  dropdownContentPropsData?: DropdownContentCardProps;
  manageSubscription?: any
}

const DropdownContentCard: React.FC<DropdownContentCardData> = ({
  dropdownContentPropsData,
  manageSubscription,
}) => {
  const {
    heading,
    address,
    date,
    time,
    isOrderFulfilled,
    orderStatus,
    skipOrder,
    order,
    updateOrdersState,
    handleEditClick,
    handleEditOrder,
    handleAddonClick,
    isEditDateButtonClicked,
    setIsEditDateButtonClicked,
  } = dropdownContentPropsData || {};

  const {
    isCancelSubscriptionBtnClicked,
    setIsCancelSubscriptionBtnClicked,
    isPauseSubscriptionBtnClicked,
    setIsPauseSubscriptionBtnClicked,
    handleCancelSubscriptionClick,
    handlePauseSubscriptionClick,
  } = useContext(ManageSubscriptionContext);

  const handleEditDateModalClose = () => {
    if (setIsEditDateButtonClicked)
      setIsEditDateButtonClicked(!isEditDateButtonClicked);
  };

  const handleConfirmCancelSubscriptionModalClose = () => {
    if (setIsCancelSubscriptionBtnClicked)
      setIsCancelSubscriptionBtnClicked(!isCancelSubscriptionBtnClicked);
  };

  const handleConfirmPauseSubscriptionModalClose = () => {
    if (setIsPauseSubscriptionBtnClicked)
      setIsPauseSubscriptionBtnClicked(!isPauseSubscriptionBtnClicked);
  };

  const handleManageSubscriptionClick = () => {
    console.log(' handleManageSubscriptionClick')
    window.location.href = `/orders/${order?.Subscription?.Id}`;
  }

  const deliveryDetailsData = {
    heading: "Delivery Details:",
    content: {
      address,
      date,
      time,
      isOrderFulfilled,
      orderStatus,
      skipOrder,
    },
    buttonText: "Edit Delivery Date",
    handleButtonClick: handleEditClick,
  };

  const orderItemsData = {
    heading: "Order Items:",
    content: {
      order,
      isOrderFulfilled,
      orderStatus,
      skipOrder,
    },
    buttonText: "Edit Order",
    handleButtonClick: handleEditOrder,
  };


  let addOnText: string = "line item 4*, line item 5*, line item 6*";
  const addonsData = {
    heading: "Addons:",
    content: {
      addOnText,
      isOrderFulfilled,
      orderStatus,
      skipOrder,
    },
    buttonText: "Purchase More",
    handleButtonClick: handleAddonClick,
  };

  addOnText = "Want to manage subscription?";
  const manageSubscriptions = {
    heading: "Manage Subscription",
    content: { addOnText, isOrderFulfilled, skipOrder },
    buttonText: "Manage Orders",
    handleButtonClick: handleManageSubscriptionClick,
  };

  addOnText = "Want to cancel subscription?";
  const cancelSubscriptions = {
    heading: "Cancel Subscription",
    content: { addOnText, isOrderFulfilled, skipOrder },
    buttonText: "Cancel Subscription",
    handleButtonClick: handleCancelSubscriptionClick,
  };

  let buttonTextValue: string = '';
  if (order?.Subscription?.Status === 1 && order?.IsSkipOrder === false) {
    buttonTextValue = 'Pause Subscription'
    addOnText = "Want to pause subscription?";
  }
  else if (order?.Subscription?.Status === 3 && order.IsSkipOrder === false) {
    buttonTextValue = 'Resume Subscription'
    addOnText = "Your Subscription is currently Paused click on resume button to resume the subscription?";
  }

  else if (order?.IsSkipOrder == true) {
    buttonTextValue = 'Pause Subscription'
    addOnText = "your cannot pause this subs your order is currently skipped";
  }

  const pauseSubscriptions = {
    heading: "Pause Subscription",
    content: { addOnText, isOrderFulfilled, skipOrder },
    buttonText: buttonTextValue,
    handleButtonClick: handlePauseSubscriptionClick,
  }

  return (
    <div className="delivery-details-card">
      {manageSubscription !== "manage subscription" && (
        <>
          {heading === "Delivery Details:" && (
            <OrderCard {...deliveryDetailsData} />
          )}
          {heading === "Order Items:" && <OrderCard {...orderItemsData} />}
          {heading === "Addons:" && <OrderCard {...addonsData} />}

          {isEditDateButtonClicked && (
            <EditDeliveryDetails
              order={order}
              updateOrdersState={updateOrdersState}
              onModalClose={handleEditDateModalClose}
            />
          )}
        </>
      )}

      {manageSubscription === "manage subscription" && (
        <>
          <OrderCard {...manageSubscriptions} />
          <OrderCard {...pauseSubscriptions} />
          {order?.Subscription?.Status === 1 && skipOrder === false && isPauseSubscriptionBtnClicked && (
            <ConfirmCancelOrPause
              order={order}
              pauseOrCancel='pause'
              onModalClose={handleConfirmPauseSubscriptionModalClose}
            />
          )}
          {order?.Subscription?.Status !== 1 && skipOrder === false && isPauseSubscriptionBtnClicked && (
            <ConfirmCancelOrPause
              order={order}
              pauseOrCancel='resume'
              onModalClose={handleConfirmPauseSubscriptionModalClose}
            />
          )}
          <OrderCard {...cancelSubscriptions} />
          {isCancelSubscriptionBtnClicked && (
            <ConfirmCancelOrPause
              order={order}
              pauseOrCancel='cancel'
              onModalClose={handleConfirmCancelSubscriptionModalClose}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DropdownContentCard;