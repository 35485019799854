import './subscription-summary.styles.scss';
import { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../contexts/modal.context';
import FormButton from '../../components/forms/form-button/form-button.component';
import ImageNotFound from '../../../src/assets/image-not-available.png';
import AlertBanner from '../../components/mui/alert.component';
import LoadingSpinner from '../../components/mui/spinner.component';
import { AuthContext } from '../../contexts/auth.context';
import { useNavigate } from 'react-router-dom';
import SubscriptionsPaymentForm from '../../components/payments/subscriptions-payment-form/subscriptions-payment-form.component';
import { CartContext } from '../../contexts/cart.context';
import { StripeCartItem } from '../../types/context-types/cart.context.types';
import { StripeContext } from '../../contexts/stripe.context';
import { Address } from '../../types/form-types/shipping-from.types';
import { v4 as uuidv4 } from 'uuid';
import { ApiDataContext } from '../../contexts/api.data.context';

const SubscriptionsSummaryPage = () => {

    const navigate = useNavigate();
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const uu_id = uuidv4();

    const { orderTypeSelected, deliveryDateSelected, deliveryDateSelectedID, productSelected, shippingData, setMetaData, setIsEditModalFlow } = useContext(ModalContext);
    const { stripeCustomerId, setLineItems } = useContext(StripeContext)
    const { cognitoUserId } = useContext(AuthContext);
    const { cartItems, cartTotal } = useContext(CartContext);


    let selectedDate = deliveryDateSelected || localStorage.getItem('deliveryDateSelected') as string
    let selectedDateID = deliveryDateSelectedID || localStorage.getItem('deliveryDateSelectedID')
    let cognitoUserID = cognitoUserId || localStorage.getItem('cognitoUserId') || ''
    let customerStripeId = stripeCustomerId || localStorage.getItem('stripeCustomerId') || ''
    let itemsInCart = cartItems
    let totalCartValue = cartTotal || JSON.parse(localStorage.getItem('cartTotal') as string)



    let displayFirstName = ''
    let displayLastName = ''
    let displayEmail = ''
    let displayAddress: Address
    let displayPhone = ''
    let address1 = ''
    let address2 = ''
    let city = ''
    let province = ''
    let provinceCode = ''
    let provinceId = 0
    let country = ''
    let countryCode = ''
    let countryId = 0
    let zipCode = ''
    let fullAddress = ''
    let residenceType = 0

    useEffect(() => {
        if (orderTypeSelected && deliveryDateSelected && deliveryDateSelectedID && productSelected && shippingData && cartItems) {
            localStorage.setItem('orderTypeSelected', JSON.stringify(orderTypeSelected));
            localStorage.setItem('deliveryDateSelected', deliveryDateSelected);
            localStorage.setItem('deliveryDateSelectedID', JSON.stringify(deliveryDateSelectedID));
            localStorage.setItem('productSelected', productSelected);
            localStorage.setItem('shippingData', JSON.stringify(shippingData));
            localStorage.setItem('cartItems', JSON.stringify(cartItems));
        }

        createLineItems(cartItems);

    }, [])

    const createLineItems = async (cartItems: StripeCartItem[]) => {
        let cartItemsToSend = cartItems
        if (cartItems.length === 0) {
            cartItemsToSend = JSON.parse(localStorage.getItem('cartItems') as any)
        }

        let itemsArray = [];
        if (cartItemsToSend) {
            for (let i = 0; i < cartItemsToSend.length; i++) {
                itemsArray.push({
                    price: cartItemsToSend[i].PriceId as string,
                    quantity: cartItemsToSend[i].Quantity as number
                })
            }

            setLineItems(itemsArray);
            localStorage.setItem('lineItems', JSON.stringify(itemsArray));
        }
    }


    const setErrorBanner = (errorMessage: string) => {
        setShowAlertError(true)
        setErrorMessageDisplay(errorMessage)
    }


    const shippingDataExtraction = () => {

        if (cartItems.length === 0) {
            itemsInCart = JSON.parse(localStorage.getItem('cartItems') as any);
        }

        const shippingAddress = shippingData.displayFirstName ? shippingData : JSON.parse(localStorage.getItem('shippingData') as string);

        if (shippingAddress) {
            ({ displayFirstName, displayLastName, displayEmail, displayAddress, displayPhone, } = shippingAddress);

            address1 = shippingAddress.displayAddress['displayAddress1']
            address2 = shippingAddress.displayAddress['displayAddress2']
            city = shippingAddress.displayAddress['displayCity']
            province = shippingAddress.displayAddress['displayProvince']
            provinceCode = shippingAddress.displayAddress['displayProvinceCode']
            provinceId = shippingAddress.displayAddress['displayProvinceId']
            country = shippingAddress.displayAddress['displayCountry']
            countryCode = shippingAddress.displayAddress['displayCountryCode']
            countryId = shippingAddress.displayAddress['displayCountryId']
            zipCode = shippingAddress.displayAddress['displayPostalCode']
            residenceType = shippingAddress.displayAddress['displayAddressType']
        }

        fullAddress = `${address1}${address2 ? `, ${address2}` : ''}, ${city}, ${province}, ${country}, ${zipCode}.`
        createAndSetMetaData();

    }

    const createAndSetMetaData = () => {
        let metadata = {
            'CheckoutId': uu_id,
            'GroupId': selectedDateID as number,
            'OrderType': orderTypeSelected.id,
            'Customer': {
                'Id': customerStripeId || null,
                'UserId': cognitoUserID || null,
                'Email': displayEmail,
                'Firstname': displayFirstName,
                'Lastname': displayLastName,
                'Phone': displayPhone,
                'Address': {
                    "Address1": address1,
                    "Address2": address2,
                    "City": city,
                    "Province": province,
                    "ProvinceCode": provinceCode,
                    "ProvinceId": provinceId,
                    "Country": country,
                    "CountryCode": countryCode,
                    "CountryId": countryId,
                    "Zip": zipCode,
                    "ResidenceType": residenceType
                }
            }
        }

        
        let metadataForStripe = {
            'CheckoutId': uu_id,
            'GroupId': selectedDateID as number,
            'OrderType': orderTypeSelected.id,
            'Customer': JSON.stringify({
                ...metadata.Customer
            })
        }

        localStorage.setItem('metadata', JSON.stringify(metadata));
        localStorage.setItem('metadataForStripe', JSON.stringify(metadataForStripe));
    }

    shippingDataExtraction();


    const navigateToOrders = () => {
        navigate('/shop');
    }


    /**
     * For Future Use when user need to edit the order from Summary pAge
     */
    const handleEditOrder = async () => {
        setIsEditModalFlow(true);
        navigate('/shop');
    }

    return (
        <div className="cart-container">
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            {
                itemsInCart && itemsInCart.length !== 0 ?
                    <>
                        <div className="cart-header-container">
                            <h1>{displayFirstName.toUpperCase()}'S CART</h1>
                            <SubscriptionsPaymentForm setErrorBanner={setErrorBanner} />
                        </div>
                        <div className="product-customer-info-container">
                            <div className='cart-products-info-container'>
                                <h1 className='summary-page-subheading'>Product Information</h1>
                                {
                                    itemsInCart?.map((cartItem: StripeCartItem) => {
                                        return (
                                            <div key={cartItem.PriceId} className="cart-products">
                                                <img className='product-image' src={cartItem.ImageUrl || ImageNotFound} />
                                                <div className="product-details">
                                                    <h3>Product Name:{cartItem.Name}</h3>
                                                    <h3> Order Type: {cartItem.OrderTypeName}</h3>
                                                    <h3>Price{cartItem.Price}</h3>
                                                    <h3>Qty:{cartItem.Quantity}</h3>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <div className='cart-products-info-container'>
                                <h1 className='summary-page-subheading'>Delivery Information</h1>
                                <div className="product-details">
                                    <h3>Address: {fullAddress}</h3>
                                    <h3>Delivery Date: {selectedDate}</h3>
                                </div>
                            </div>
                            <div className='cart-products-info-container'>
                                <h1 className='summary-page-subheading'>Customer Information</h1>
                                <div className="product-details">
                                    <h3>Name: {displayFirstName + " " + displayLastName}</h3>
                                    <h3>Email: {displayEmail}</h3>
                                    <h3>Phone: {displayPhone}</h3>
                                </div>
                            </div>
                        </div>
                    </> :
                    <div className='empty-cart-message'>
                        <h1>YOUR CART IS EMPTY</h1>
                        <FormButton onClick={() => navigateToOrders()}>{'Order Now'}</FormButton>
                    </div>
            }
        </div>

    )
}

export default SubscriptionsSummaryPage;