import './email-change.styles.scss';
import { useState, useContext, useEffect } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth.context';
import AlertBanner from '../../mui/alert.component';
import LoadingSpinner from '../../mui/spinner.component';
import { emailRegex } from '../../../constants/regex.utils';

type VerifyFormFieldsType = {
    email: string,
    code: string,

}

const defaultVerifyFields: VerifyFormFieldsType = {
    email: '',
    code: '',

}

const EmailChangeForm = () => {

    const [formFields, setFormFields] = useState(defaultVerifyFields);
    const [errors, setErrors] = useState<VerifyFormFieldsType>({ ...defaultVerifyFields });
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { email } = formFields;
    const { signupData, setUserSessionObject, isChangingEmail } = useContext(AuthContext);
    const { password } = signupData
    const signupEmail = signupData.email

    const navigate = useNavigate()


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hasError = validate();
        if (!hasError) {
            try {
                setIsLoading(true);
                const user = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(user, { email: email });
                navigate('/auth/verify');
            }
            catch (error: any) {
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(error.message);
                console.log("Error in Change Email Form ", error.message)
            }
        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!')
            console.error("Invalid Form");
        }
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        !value && setErrors({ ...errors, [name]: 'This is a required field' });
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
    };

    const validate = () => {
        let tempErrors = { ...defaultVerifyFields };
        let hasError = false;
        if (!email) {
            tempErrors.email = "Email is required";
            hasError = true;
        }
        else if (!emailRegex.test(email)) {
            tempErrors.email = "Email is invalid";
            hasError = true;
        }

        setErrors(tempErrors);
        return hasError;
    };

    return (
        <div className='Verify-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            <h2>Got the verification code?</h2>
            <span>Continue to signup </span>
            <form onSubmit={handleSubmit}>
                <FormInputText
                    label='New Email'
                    type='text'
                    onChange={handleChange}
                    name='email'
                    value={email || signupEmail}
                    errorMessage={errors?.email}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <div className='auth-buttons-container'>
                    <FormButton type='submit'>Sumbit Email</FormButton>
                    {/* <FormButton type='submit'>Forgot Password</FormButton> */}
                </div>


            </form>
        </div>

    )
}

export default EmailChangeForm;