import './auth.styles.scss';
import SignInForm from '../../components/forms/signin-form/sign-in-form.component';
import SignUpForm from '../../components/forms/signup-form/sign-up-form.componet';
import { useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/auth.context';


const AuthPage = () => {


    return (
        <div className='authpage-container'>
            <SignInForm />
            <SignUpForm />
        </div>
    )
}


export default AuthPage;