import CustomerInfo from '../../components/orderDetails/customer/customer-profile.component';
import './manage-subscription.styles.scss';
import OrdersHeadingBar from '../../components/orderDetails/orders-heading-bar/order-details-bar.component'

const ManageSubscriptionComponent = () => {
 
  const activeButton='active'
  const hideManageSubscription=true;
  const manageSubscription:string='manage subscription'
  
return(
<div className="component">
  <>
<CustomerInfo hideManageSubscription={hideManageSubscription}/>
<div className="orderscomponent">
<OrdersHeadingBar activeButton={activeButton} manageSubscription={manageSubscription}/>
</div>
</>
</div>
)

  };
  
  export default ManageSubscriptionComponent;