import { createContext, useState } from 'react';
import { ApiDataContextType, ApiDataProviderProps, Country, MetaDataAPI, Province, ProvinceWithCountry } from '../types/context-types/api.data.context.types';

const defaultApiMetaData = {
    CheckoutId: '',
    GroupId: 0,
    OrderType: 0,
    Customer: {
        Id: '' ,
        UserId: '' ,
        Email: '',
        Firstname: '',
        Lastname: '',
        Phone: '',
        Address: {
            Address1: '',
            Address2: '',
            City: '',
            Province: '',
            ProvinceCode: '',
            ProvinceId: 0,
            Country: '',
            CountryCode: '',
            CountryId: 0,
            Zip: '',
            ResidenceType: 0
        }
    }
}

export const ApiDataContext = createContext<ApiDataContextType>({
    deliveryDateReponse: '',
    setDeliveryDateResponse: () => null,
    countries : [],
    setCountires : () => null,
    provinces: [], 
    setProvinces: () =>  null,
    metaDataAPI: defaultApiMetaData,
    setMetaDataAPI: () =>  null,
});


export const ApiDataProvider = ({ children }: ApiDataProviderProps) => {

    const [deliveryDateReponse, setDeliveryDateResponse] = useState<unknown>(null);;
    const [countries, setCountires] = useState<Country[]>([]);
    const [provinces, setProvinces] = useState<ProvinceWithCountry[]>([]);
    const [metaDataAPI, setMetaDataAPI] = useState<MetaDataAPI>(defaultApiMetaData);

    const value = {
        deliveryDateReponse, setDeliveryDateResponse, 
        countries, setCountires,
        provinces, setProvinces,
        metaDataAPI, setMetaDataAPI
    };

    return <ApiDataContext.Provider value={value}> {children}</ApiDataContext.Provider>
}