import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { AlertColor } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
// import CloseIcon from '@mui/icons-material/Close';
import './alert.styles.scss';


type AlertProps = {
    alertType: string,
    alertMessage: string
}

const AlertBanner = ({ alertType, alertMessage }: AlertProps) => {
    const [open, setOpen] = React.useState(true);

    return (
            <Box sx={{ width: '100%' }}>
                <Collapse in={open}>
                    <Alert className='alert-banner'
                        severity={alertType as AlertColor}
                        // variant="filled"
                        // sx={{
                        //     '& .MuiAlert-message': {
                        //       color: 'white'
                        //     },
                        //     '&. .MuiAlert-icon': {
                        //       color: 'white !important'
                        //     }
                        //   }}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
            </Box>
    );
}

export default AlertBanner;

/**
 *          // action={
                    //     <IconButton
                    //         aria-label="close"
                    //         color="inherit"
                    //         size="small"
                    //         onClick={() => {
                    //             setOpen(false);
                    //         }}
                    //     >
                    //         <CloseIcon fontSize="inherit" />
                    //     </IconButton>
                    // }
                    // sx={{ color: 'white' }}
 */