import { useContext, useEffect, useState } from 'react';
import './addon-order-summary.styles.scss';
import { CartContext } from '../../../contexts/cart.context';
import OpenBox from '../../../assets/openbox.png'
import axios from 'axios';
import { CustomerContext } from '../../../contexts/customer.context';
import { useParams } from 'react-router-dom';
import { calcuatePaidAndCartTotalAmounts } from '../../../utils/cart.functions';
import { fetchItemSelectionsPageData } from '../../../services/api/data.utils';

const ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string;

const AddonOrderDetails = () => {

    useEffect(() => {
        generateOrderNameAndLineItems();
    }, [])

    const { setSelectedSubscriptionName, setSelectedSubscriptionPrice, setInitialCartTotal, setPaidAmount, setSubscriptionBalance, cartItems, cartTotal, addOnCharge, paidAmount } = useContext(CartContext);
    const { setCustomerData } = useContext(CustomerContext);
    let { checkoutID } = useParams();  //TODO : checkoutID should be encrypted..!


    const [lineItemSummary, setLineItemSummary] = useState('');
    const [orderNameAndPrice, setOrderNameAndPrice] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [orderMonth, setOrderMonth] = useState('')

    const generateOrderNameAndLineItems = async () => {
        const orderInfo = await axios.get(`${ordersBaseURL}orders/${checkoutID}/itemselection`) // URL not finalized yet
        const { Balance, Name, Price } = orderInfo.data.Order.Subscription;
        const { SubscriptionTransactions, OrderNumber, createdAt } = orderInfo.data.Order
        setOrderNumber(OrderNumber);
        const orderCreatedAt = new Date(createdAt)
        setOrderMonth(orderCreatedAt.toLocaleString('default', { month: 'long' }))

        let summaryLineItem = ''
        let subscriptionNameAndPrice = `${Name} (${Price})`

        setCustomerData({ ...orderInfo.data.Customer, 'GroupData': orderInfo.data.Order.Group })
        setSubscriptionBalance(Balance);
        setSelectedSubscriptionName(Name);
        setSelectedSubscriptionPrice(Price);
        setOrderNameAndPrice(subscriptionNameAndPrice);

        let { paidAmount, initialCartTotal } = calcuatePaidAndCartTotalAmounts(Balance, SubscriptionTransactions);
        setInitialCartTotal(initialCartTotal)
        setPaidAmount(paidAmount)

        let itemsInCart = cartItems
        if (cartItems.length === 0) {
            itemsInCart = JSON.parse(localStorage.getItem('cartItems') as string);
        }

        for (let i = 0; i < itemsInCart.length; i++) {
            if (itemsInCart[i].Quantity > 1) {
                summaryLineItem += `${itemsInCart[i].Name} (X${itemsInCart[i].Quantity}), `
            }
            else {
                summaryLineItem += `${itemsInCart[i].Name}, `
            }
        }

        summaryLineItem = summaryLineItem.replace(/,\s*$/, '')
        setLineItemSummary(summaryLineItem)
    }

    const calculateCreditAfterConfirmation = () => {
        // const creditAfterConfirmation : number = (paidAmount.toFixed(2) as any) - (cartTotal.toFixed(2) as any)
        let creditAfterConfirmation = (paidAmount - cartTotal)
        if (creditAfterConfirmation <= 0) {
            return 0
        }
        return creditAfterConfirmation
    }

    return (
        <div className='order-details-container'>
            <div className='order-details-headings-container'>
                <h2>{orderMonth.toUpperCase()} ORDER #{orderNumber}</h2>
            </div>
            <div className='order-image-info-container'>
                <div className='order-image-container'>
                    <img src={OpenBox} />
                </div>
                <div className='order-info-container'>
                    <h1>{orderNameAndPrice}</h1>
                    <span>{lineItemSummary}</span>
                    <div className='price-details-container'>
                        <h3>Breakdown</h3>
                        <div className='price-details cost-of-goods'>
                            <h4>Cost of goods</h4>
                            <h4>${cartTotal.toFixed(2)}</h4>
                        </div>
                        <div className='price-details subscription-credits '>
                            <h4>Subscription credit</h4>
                            <h4>-${paidAmount.toFixed(2)}</h4>
                        </div>
                        <div className='price-details credit'>
                            <h4>Credits after confirmation</h4>
                            <h4>${calculateCreditAfterConfirmation().toFixed(2)}</h4>
                        </div>
                        <div className='price-details total'>
                            <h4>To be paid</h4>
                            <h4>${addOnCharge.toFixed(2)}</h4>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default AddonOrderDetails;