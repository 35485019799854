import axios from "axios";

export const sendErrorMessageInSlack = async (errorMessageHeading: string, errorObject: any) => {

    try {
        const errorMessageObject = {
            ErrorType: errorMessageHeading,
            Data: errorObject,
        }

        const api = 'https://4wafyplpr6.execute-api.us-east-1.amazonaws.com/dev/slackerrornotification';
        const data = JSON.stringify(errorMessageObject);
        await axios.post(api, data)

    } catch (error) {
        console.log(error, 'Error with API Gateway');

    }
}


// export const clearCartItemRelatedLocalStorage = () => {
//     localStorage.removeItem('orderTypeSelected')
//     localStorage.removeItem('lineItems')
//     localStorage.removeItem('citySelected')
//     localStorage.removeItem('cartItems')
//     localStorage.removeItem('isAuthFromModal')
//     localStorage.removeItem('productSelected')
//     localStorage.removeItem('deliveryDateSelectedID')
//     localStorage.removeItem('deliveryDateSelected')
//     localStorage.removeItem('guestCheckout')
//     localStorage.removeItem('metadata')
//     localStorage.removeItem('selectedStripeProductFromModal')
//     localStorage.removeItem('metadataForStripe')
// }

// export const clearLocalStorage = () => {
//     localStorage.clear();
// }

// export const clearCartDataInLocalStorage = () => {
//     localStorage.setItem('cartTotal', JSON.stringify(0))
//     localStorage.setItem('cartCount', JSON.stringify(0))
//     localStorage.setItem('cartItems', JSON.stringify([]))

// }