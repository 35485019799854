import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
import './empty-cart.styles.scss';

const EmptyCart = () => {
    return (
        <div className='empty-cart-container'>
            <FontAwesomeIcon icon={faCartShopping} className='empty-cart-img' />
            <span>Add items to the cart to begin!</span>
        </div>
    )
}

export default EmptyCart