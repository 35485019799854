import { useContext, useEffect, useState } from 'react';
import './product-card.styles.scss';
import { CartContext } from '../../../contexts/cart.context';
import { StripeProductDataCombined } from '../../../types/context-types/stripe.context.types';
import Button from '../../button/button.component';

type ProductCardProps = {
    product: StripeProductDataCombined,
    isLowQty: boolean
}

const ProductCard = ({ product, isLowQty }: ProductCardProps) => {

    const { imageUrl, name, unit_amount, id, productId } = product

    const price = unit_amount as number / 100;

    const [qty, setQty] = useState(1);
    const { subscriptionBalance, cartTotal, paidAmount, addItemToCart, clearCart } = useContext(CartContext);

    const [isPositiveBalance, setIsPositiveBalance] = useState(false);
    const [isNegativeBalance, setIsNegativeBalance] = useState(false);
    const [isZeroBalance, setIsZeroBalance] = useState(false);

    useEffect(() => {


        if (paidAmount - cartTotal > 0) {
            setIsPositiveBalance(true);
            setIsZeroBalance(false);
            setIsNegativeBalance(false);

        }
        else if (paidAmount - cartTotal == 0) {
            setIsZeroBalance(true);
            setIsPositiveBalance(false);
            setIsNegativeBalance(false);

        }
        else if (paidAmount - cartTotal < 0) {
            setIsNegativeBalance(true);
            setIsPositiveBalance(false);
            setIsZeroBalance(false);


        }
    }, [cartTotal, subscriptionBalance, paidAmount])


    const handleAddItemsToCart = () => {
        addItemToCart({
            Id: null,
            Name: name as string,
            ProductId: productId as string,
            PriceId: id as string,
            Price: price as number,
            ImageUrl: imageUrl as string,
            Quantity: qty,
            OrderType: 3,
            OrderTypeName: 'One Time Purchase'
        });
        setQty(1)
    }

    const handleAddQty = () => {
        setQty(qty + 1);

    }

    const handleReduceQty = () => {
        if (qty == 1) {
            return
        }
        setQty(qty - 1);

    }


    return (
        <div>
            <div className={`product-card-container ${isLowQty ? 'isLowQty' : null}`}>
                <img src={imageUrl} alt="product-image" />
                <h5 className="product-title">{name}</h5>
                <div className="product-info">
                    <span className="product-price">${price.toFixed(2)}</span>
                    <div className="product-footer">
                        <div className="qty-container">
                            <span onClick={handleReduceQty} className='qty-modifier'>&#10094;</span>
                            <span className="qty">{qty}</span>
                            <span onClick={handleAddQty} className='qty-modifier'>&#10095;</span>
                        </div>
                    </div>
                    <div className="add-to-cart-button-container">
                        {(isNegativeBalance || isZeroBalance) && <div className="add-to-cart-button negative-balance">
                            <Button onClick={handleAddItemsToCart}>Add for Purchase</Button>
                        </div>}
                        {(isPositiveBalance) && <div className="add-to-cart-button">
                            <Button onClick={handleAddItemsToCart}>Add to Cart</Button>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard;