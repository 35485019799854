import { useContext, useEffect, useState } from 'react';
import './credit-bar.styles.scss';
import { CartContext } from '../../../contexts/cart.context';


const CreditBar = () => {

    const { subscriptionBalance, cartTotal, paidAmount } = useContext(CartContext);

    const [isPositiveBalance, setIsPositiveBalance] = useState(false);
    const [isNegativeBalance, setIsNegativeBalance] = useState(false);
    const [isZeroBalance, setIsZeroBalance] = useState(false);

    useEffect(() => {


        if (paidAmount - cartTotal > 0) {

            setIsPositiveBalance(true);
            setIsZeroBalance(false);
            setIsNegativeBalance(false);

        }
        else if (paidAmount - cartTotal == 0) {

            setIsZeroBalance(true);
            setIsPositiveBalance(false);
            setIsNegativeBalance(false);

        }
        else if (paidAmount - cartTotal < 0) {

            setIsNegativeBalance(true);
            setIsPositiveBalance(false);
            setIsZeroBalance(false);


        }
    }, [cartTotal, subscriptionBalance, paidAmount])


    return (
        <div className='credit-bar-container'>
            {
                isPositiveBalance &&
                <div>
                    <h1>You have <span>${(paidAmount - cartTotal).toFixed(2)}</span> left to spend</h1>
                    <span>Don't fret! Items are still available to purchase after all credits have been used up, extra charges will apply.</span>
                </div>
            }
            {
                isZeroBalance &&
                <div>
                    <h1>Feel free to continue shopping</h1>
                    <span>All the credits have been used up! Additional items are available to purchase, extra charges will apply.</span>
                </div>
            }
            {
                isNegativeBalance &&
                <div>
                    <h1>Items of <span>+${(cartTotal - paidAmount).toFixed(2)}</span> is in your for purchase</h1>
                    <span>All the credits have been used up! Additional items are available to purchase, extra charges will apply.</span>
                </div>
            }

        </div>
    )
}

export default CreditBar;