import { useState, useEffect, useContext } from "react";
import { CustomerContext } from "../../../contexts/customer.context";
import { Order } from "../../../types/context-types/api.data.context.types";
import {
  getCustomerOrderDetails,
} from "../../../services/api/data.utils";
import "./order-details-bar.styles.scss";
import DropdownContent from "../dropdown-Content/order-details-dropdown.component";
import { sendErrorMessageInSlack } from "../../../utils/miscellaneous.functions";
import { AuthContext } from "../../../contexts/auth.context";
import { getCustomerData } from "../../../services/api/data.utils";

let ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string;

const OrdersHeadingBar = ({ activeButton, manageSubscription, subscriptionid }: { activeButton: string, manageSubscription?: string, subscriptionid?: string; }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const { customerData } = useContext(CustomerContext);
  const { setCustomerData } = useContext(CustomerContext);
  const [currentlyExpandedOrderNumber, setCurrentlyExpandedOrderNumber] =
    useState<string | null>(null);
  const [customerOrdersData, setCustomerOrdersData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userSessionObject } = useContext(AuthContext);

  let jwtToken: any = null;
  let cognitoUserId: any = null;
  let userSession = null;
  let accessToken = null;
  let idToken = null;

  if (userSessionObject) {
    userSession = userSessionObject?.getSignInUserSession();
    accessToken = userSession?.getAccessToken() as any;
    jwtToken = accessToken?.jwtToken;
    idToken = userSession?.getIdToken();
    cognitoUserId = idToken?.payload["cognito:username"];
  } else {
    let fetchedUserSessionObject = JSON.parse(
      localStorage.getItem("userSessionObject") as any
    );
    jwtToken = fetchedUserSessionObject?.signInUserSession.accessToken.jwtToken;
    idToken = fetchedUserSessionObject?.signInUserSession.idToken;
    cognitoUserId = idToken?.payload["cognito:username"];
  }

  const updateOrdersState = (updatedDeliveryDate: any) => {
    setOrders(updatedDeliveryDate);
    setCustomerOrdersData(updatedDeliveryDate);
  };

  const toggleDropdown = (orderNumber: string) => {
    if (currentlyExpandedOrderNumber === orderNumber) {
      setCurrentlyExpandedOrderNumber(null);
    } else {
      setCurrentlyExpandedOrderNumber(orderNumber);
    }
  };

  useEffect(() => {
    fetchCustomerOrdersData();
    filterOrders();
    setOrders([]);
  }, [customerData]);

  useEffect(() => {
    filterOrders();
  }, [activeButton, customerOrdersData]);

  const fetchCustomerOrdersData = async () => {
    try {
      setIsLoading(true);
      if (!customerData) {
        // sendErrorMessageInSlack(" JWT Token", jwtToken);
        if (cognitoUserId != undefined) {

          let customerData = await getCustomerData(cognitoUserId, jwtToken);
          setCustomerData(customerData?.data);
        }
      }
      let customerId = customerData?.Id;
      // sendErrorMessageInSlack(" getting customer ID", customerId);
      const urlToGetCustomerOrders = `${ordersBaseURL}orders/customer/${customerId}`;
      /* sendErrorMessageInSlack(
         " url To Get Customer Orders",
         urlToGetCustomerOrders
       );*/

      if (!customerId) {
        console.log("Customer ID is null or undefined. Skipping API call.");
        return;
      } else {
        const ordersData: any = await getCustomerOrderDetails(customerId);
        console.log('order data', ordersData.data)
        setCustomerOrdersData(ordersData?.data);
        /* sendErrorMessageInSlack(
           " orders data response status code",
           ordersData?.status
         );*/
      }
    } catch (error) {
      console.error("Error retrieving customer order details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterOrders = async () => {
    if (!customerOrdersData) {
      return;
    }
    let filteredOrders: Order[] = [];
    if (activeButton === "active") {
      filteredOrders = getActiveOrders(customerOrdersData);
    } else if (activeButton === "fulfilled") {
      filteredOrders = getFulfilledOrders(customerOrdersData);
    }
    /*else if (activeButton === "upcoming") {
      filteredOrders = getUpcomingOrders(customerOrdersData);
    }*/
    setOrders(filteredOrders);
  };

  const getActiveOrders = (ordersData: any) => {
    const filteredOrders = ordersData.filter((order: Order) => {
      if (order?.Subscription?.Status == 2) {
        return order.IsOrderFulfilled;
      }
      else {
        return !order.IsOrderFulfilled;
      }
    });
    return filteredOrders;
  };

  const getFulfilledOrders = (ordersData: any) => {
    const filteredOrders = ordersData.filter((order: Order) => {
      return order.IsOrderFulfilled;
    });
    return filteredOrders;
  };

  const getUpcomingOrders = (ordersData: any) => {
    const filteredOrders = ordersData.filter((order: Order) => {
      return order.IsOrderFulfilled;
    });
    return filteredOrders;
  };


  const renderOrders = () => {
    let sortedOrders = orders.slice().sort((a, b) => Number(a.OrderNumber) - Number(b.OrderNumber));
    if (manageSubscription === "manage subscription") {
      const uniqueSubIds = new Set();
      sortedOrders = sortedOrders.filter((order) => {
        if (order.Subscription && order.Subscription.Id) {
          if (!uniqueSubIds.has(order.Subscription.Id)) {
            uniqueSubIds.add(order.Subscription.Id);
            return true;
          }
          return false;
        }
        return false;
      });
    }

    else if (subscriptionid !== undefined) {
      sortedOrders = sortedOrders.filter((order) => {
        return order?.Subscription?.Id === subscriptionid;
      });
    }

    if (isLoading) {
      return (
        <div className="loading-spinner">
          <div className="loader"></div>
        </div>
      );
    }

    if (!customerOrdersData || orders.length === 0) {
      return <div>No orders to display.</div>;
    }

    return (
      <div>
        {sortedOrders.map((order) => {

          const { OrderNumber, Group, Subscription } = order;
          const price = Subscription?.Price;
          const orderDate = new Date(Group?.EndTime || "");
          const month = orderDate.toLocaleString("en-US", { month: "long" });
          const showDropdown = currentlyExpandedOrderNumber === OrderNumber;
          const shouldRenderSubscription = manageSubscription === "manage subscription";

          return (
            <div
              key={OrderNumber}
              className={`dropdown ${showDropdown ? "open" : ""}`}
            >
              <button
                className={`dropdown-btn ${showDropdown ? "open" : ""}`}
                onClick={() => toggleDropdown(OrderNumber)}
              >
                {shouldRenderSubscription ? (
                  <span>
                    <b>
                      {price}$/Month Subscription
                      <span className="dropdown-arrow">▼</span>
                    </b>
                  </span>
                ) : (
                  <span>
                    <b>
                      {month} Subscription Order #{OrderNumber}
                      <span className="dropdown-arrow">▼</span>
                    </b>
                    <br />
                    <span>+3 more add ons</span>
                  </span>
                )}
              </button>
              {showDropdown && (
                <DropdownContent
                  {...(manageSubscription === "manage subscription" && {
                    manageSubscription: "manage subscription",
                  })}
                  order={order}
                  updateOrdersState={updateOrdersState}
                />
              )}
            </div>
          );
        })}
      </div>
    );

  }

  return <div>{renderOrders()}</div>;
};


export default OrdersHeadingBar;
