import { createContext, useState } from "react";
import { AuthProviderProps, SignInFormType, AuthContextType, SignUpFormType } from "../types/context-types/auth.context.types";
import { CognitoUser } from 'amazon-cognito-identity-js';

const defaultSignInData: SignInFormType = {
    email: '',
    password: '',
}

const defultSignUpData: SignUpFormType = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
}


export const AuthContext = createContext<AuthContextType>({

    signinData: defaultSignInData,
    setSigninData: () => null,
    signupData: defultSignUpData,
    setSignUpData: () => null,
    isAuthenticated: false,
    setIsAutenticated: () => null,
    userSessionObject: null,
    setUserSessionObject: () => null,
    passwordResetEmail: '',
    setPasswordResetEmail: ()=> null,
    isChangingEmail: false,
    setIsChangingEmail:() => null,
    cognitoUserId:'',
    setCognitoUserId:()=> null
})


export const AuthProvider = ({ children }: AuthProviderProps) => {

    const [signinData, setSigninData] = useState(defaultSignInData);
    const [signupData, setSignUpData] = useState(defultSignUpData);
    const [isAuthenticated, setIsAutenticated] = useState(false);
    const [userSessionObject, setUserSessionObject] = useState<CognitoUser | null>(null);
    const [passwordResetEmail, setPasswordResetEmail] = useState('');
    const [isChangingEmail, setIsChangingEmail] = useState(false);
    const [cognitoUserId, setCognitoUserId] = useState('');

    const value = {
        signinData, setSigninData,
        signupData, setSignUpData,
        isAuthenticated, setIsAutenticated,
        userSessionObject, setUserSessionObject,
        passwordResetEmail, setPasswordResetEmail,
        isChangingEmail, setIsChangingEmail,
        cognitoUserId, setCognitoUserId
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

}