import React, { useContext, useState } from "react";
import { CustomerContext } from "../../../contexts/customer.context";
import { Order } from "../../../types/context-types/api.data.context.types";
import "./order-details-dropdown.styles.scss";
import SkipOrderLogo from "../../../assets/skip-order-logo.png";
import DropdownContentCard from "./dropdown-content-card";
import SkipOrderModal from "../../modal/skip-order-modal/skip-order-modal.component";

const DropdownContent: React.FC<{ order: Order; updateOrdersState: any; manageSubscription?: string }> = ({
  order,
  updateOrdersState,
  manageSubscription,
}) => {

  const orderStatus = order?.Subscription?.Status;
  const skipOrder = order?.IsSkipOrder;
  const { customerData } = useContext(CustomerContext);
  const [isEditDateButtonClicked, setIsEditDateButtonClicked] = useState(false);
  const isOrderFulfilled = order?.IsOrderFulfilled;

  const [isSkipOrderModalOpen, setIsSkipOrderModalOpen] = useState(false);

  const handleSkipOrderClick = () => {
    setIsSkipOrderModalOpen(true);
  };

  const handleEditClick = () => {
    setIsEditDateButtonClicked(!isEditDateButtonClicked);

  };
  const checkoutId = order?.Id;

  const handleEditOrder = () => {
    window.location.href = `/build-a-box/${checkoutId}`;
  };

  const deliveryDetailsData = {
    heading: "Delivery Details:",
    address: customerData.FullAddress,
    date: order.Group ? order.Group.StartTime : null,
    time: order.Group ? order.Group.StartTime : null,
    isOrderFulfilled: isOrderFulfilled,
    orderStatus: orderStatus,
    skipOrder,
    order: order,
    updateOrdersState: updateOrdersState,
    handleEditClick: handleEditClick,
    isEditDateButtonClicked: isEditDateButtonClicked,
    setIsEditDateButtonClicked: setIsEditDateButtonClicked,
  };

  const OrderItemsData = {
    heading: "Order Items:",
    isOrderFulfilled: isOrderFulfilled,
    orderStatus: orderStatus,
    skipOrder,
    order: order,
    handleEditOrder: handleEditOrder,
  };

  const handleAddonClick = () => { };
  const AddonsData = {
    heading: "Addons:",
    isOrderFulfilled: isOrderFulfilled,
    orderStatus: orderStatus,
    skipOrder,
    handleAddonClick: handleAddonClick,
  };


  const dropdownContentHeadings = [
    { data: deliveryDetailsData },
    { data: OrderItemsData },
    { data: AddonsData },
  ];

  let dataforsubs = { order: order, skipOrder }

  return (
    <>
      <div className="dropdown-content">

        {manageSubscription !== "manage subscription" && order?.Subscription?.Status === 1 && order?.IsSkipOrder === false && !isOrderFulfilled && (
          <div className="blue-text">
            <p className="right-aligned" onClick={handleSkipOrderClick}>
              <img
                className="logo"
                src={SkipOrderLogo}
                alt="no edit icon"
              />{" "}
              skip order
            </p>
          </div>
        )}

        {manageSubscription !== "manage subscription" && (
          <>
            {dropdownContentHeadings.map(({ data }, index) => (
              <div key={index}>
                <div>
                  {data ? (
                    <DropdownContentCard
                      dropdownContentPropsData={data}
                      {...(manageSubscription === "manage subscription" && {
                        manageSubscription,
                      })}
                    />
                  ) : (
                    <span>No data available</span>
                  )}
                </div>
              </div>
            ))}
          </>
        )}

        {manageSubscription === "manage subscription" && (
          <DropdownContentCard dropdownContentPropsData={dataforsubs} manageSubscription={manageSubscription} />
        )}
      </div>

      {isSkipOrderModalOpen && (
        <SkipOrderModal
          order={order}
          onModalClose={() => setIsSkipOrderModalOpen(false)}
        />
      )}
    </>
  );


};

export default DropdownContent;