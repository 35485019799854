import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from './contexts/modal.context';
import { StripeProductsProvider } from './contexts/stripe.context';
import { AuthProvider } from './contexts/auth.context';
import { ApiDataProvider } from './contexts/api.data.context';
import { CartProvider } from './contexts/cart.context';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from './services/stripe/stripe.utils';
import { CustomerProvider } from './contexts/customer.context';
import { ManageSubscriptionProvider } from './contexts/manageSubscription.context';

Amplify.configure(config)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const options = {
  clientSecret: process.env.REACT_APP_STRIPE_SECRET_KEY,
};

root.render(
  <React.StrictMode>
    <AuthProvider>
      <CustomerProvider>
        <CartProvider>
            <StripeProductsProvider>
              <ModalProvider>
                <ApiDataProvider>
                <ManageSubscriptionProvider>
                  <BrowserRouter>
                    <Elements stripe={stripePromise}>
                      <App />
                    </Elements>
                  </BrowserRouter>
                  </ManageSubscriptionProvider>
                </ApiDataProvider>
              </ModalProvider>
            </StripeProductsProvider>
        </CartProvider>
      </CustomerProvider>

    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
