import './cart-item.styles.scss';
import { StripeCartItem } from '../../../types/context-types/cart.context.types';
import { useContext } from 'react';
import { CartContext } from '../../../contexts/cart.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

type CartItemInputProps = {
    cartItem: StripeCartItem
}

const CartItem = ({ cartItem }: CartItemInputProps) => {

    const { Name, ProductId, Price, Quantity, ImageUrl, PriceId } = cartItem

    const { clearItemFromCart, addItemToCart, removeItemFromCart } = useContext(CartContext);

    const clearItemsInCheckout = () => {
        clearItemFromCart(cartItem);
    }

    const handleAddToCart = () => {
        addItemToCart({
            Name: Name as string,
            ProductId: ProductId as string,
            PriceId: PriceId as string,
            Price: Price as number,
            ImageUrl: ImageUrl as string,
            Quantity: 1,
            OrderType: 3,
            OrderTypeName: 'One Time Purchase'
        });
    }

    const handleReduceFromCart = () => {
        removeItemFromCart({
            Name: Name as string,
            ProductId: ProductId as string,
            PriceId: PriceId as string,
            Price: Price as number,
            ImageUrl: ImageUrl as string,
            Quantity: 1,
            OrderType: 3,
            OrderTypeName: 'One Time Purchase'
        })
    }

    return (
        <div className='cart-item-container'>
            <FontAwesomeIcon onClick={clearItemsInCheckout} icon={faMinus} className='remove-item' />
            <div className='item-details'>
                <span className='name'>{Name}</span>
                <span className='price'>${Price.toFixed(2)}</span>
            </div>
            <div className="cart-item-qty-container">
                <span onClick={handleReduceFromCart} className='cart-item-qty-modifier'>&#10094;</span>
                <span className="cart-item-qty">{Quantity}</span>
                <span onClick={handleAddToCart} className='cart-item-qty-modifier'>&#10095;</span>
            </div>

        </div>
    )
}

export default CartItem;