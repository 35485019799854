import './forgot-password-form.styles.scss';
import { useState, useContext } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom';
import AlertBanner from '../../mui/alert.component';
import LoadingSpinner from '../../mui/spinner.component';
import { emailRegex } from '../../../constants/regex.utils';
import { AuthContext } from '../../../contexts/auth.context';


type ForgetPasswordFormFieldsType = {
    email: string,
}

const defaultVerifyFields: ForgetPasswordFormFieldsType = {
    email: '',

}

const ForgotPasswordForm = () => {

    const {passwordResetEmail, setPasswordResetEmail} = useContext(AuthContext)
    const [formFields, setFormFields] = useState(defaultVerifyFields);
    const [errors, setErrors] = useState<ForgetPasswordFormFieldsType>({ ...defaultVerifyFields });
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { email } = formFields;

    const navigate = useNavigate()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true);
        event.preventDefault();
        setPasswordResetEmail(email);
        const hasError = validate();
        if (!hasError) {
            try {
                await Auth.forgotPassword(email);
                navigate('/auth/forgot_password_submit')
            }
            catch (error: any) {
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(error.message);
                console.log("Error in Verification ", error.message)
            }
        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!')
            console.error("Invalid Form");
        }
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        !value && setErrors({ ...errors, [name]: 'This is a required field' });
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
    };

    const validate = () => {
        let tempErrors = { ...defaultVerifyFields };
        let hasError = false;
        if (!email) {
            tempErrors.email = "Email is required";
            hasError = true;
        }
        else if (!emailRegex.test(email)) {
            tempErrors.email = "Email is invalid";
            hasError = true;
        }
        setErrors(tempErrors);
        return hasError;
    };

    return (
        <div className='forget-password-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            <h2>Reset your password?</h2>
            <span>Enter your email address and we will send you a new password code</span>
            <form onSubmit={handleSubmit}>
                <FormInputText
                    label='Email'
                    type='text'
                    onChange={handleChange}
                    name='email'
                    value={email}
                    errorMessage={errors?.email}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <div className='auth-buttons-container'>
                    <FormButton type='submit'>Send Password Reset Code</FormButton>
                    {/* <FormButton type='submit'>Forgot Password</FormButton> */}
                </div>


            </form>
        </div>

    )
}

export default ForgotPasswordForm;