import './sign-in-form.styles.scss';
import { useState, useContext } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { SignInFormType } from '../../../types/context-types/auth.context.types';
import { AuthContext } from '../../../contexts/auth.context';
import AlertBanner from '../../mui/alert.component';
import LoadingSpinner from '../../mui/spinner.component';
import { emailRegex } from '../../../constants/regex.utils';
import { useStripe } from "@stripe/react-stripe-js";
import { StripeContext } from '../../../contexts/stripe.context';
import { CustomerContext } from '../../../contexts/customer.context';
import { getCustomerData } from '../../../services/api/data.utils';
import { sendErrorMessageInSlack } from '../../../utils/miscellaneous.functions';
import { CartContext } from '../../../contexts/cart.context';


const defaultSignInFields: SignInFormType = {
    email: '',
    password: '',
}

const SignInForm = () => {
    const [formFields, setFormFields] = useState(defaultSignInFields);
    const [errors, setErrors] = useState<SignInFormType>({ ...defaultSignInFields });
    const { email, password } = formFields;
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();


    const {
        setSigninData,
        setIsAutenticated,
        setUserSessionObject, setCognitoUserId
    } = useContext(AuthContext);

    const { setStripeCustomerId } = useContext(StripeContext);
    const { setCustomerData } = useContext(CustomerContext);
    const { checkoutID } = useContext(CartContext)

    let userSession = null;
    let idToken = null;
    let cognitoUserId: any = null;
    let accessToken = null;
    let jwtToken: any = null;

    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const setCustomerDataAvailable = async () => {

        let customerData = await getCustomerData(cognitoUserId, jwtToken);
        setStripeCustomerId(customerData?.data?.CustomerId);
        localStorage.setItem('stripeCustomerId', customerData?.data?.CustomerId)
        setCustomerData(customerData.data);
        return customerData
    }



    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hasError = validate();
        if (!hasError) {
            try {
                setIsLoading(true);
                let userSessionObject = await Auth.signIn(email, password) as CognitoUser;
                let isBuildABoxPage = localStorage.getItem('isBuildABoxPage') === "true";
                userSession = userSessionObject?.getSignInUserSession();
                idToken = userSession?.getIdToken();
                cognitoUserId = idToken?.payload['cognito:username'];
                localStorage.setItem('cognitoUserId', cognitoUserId);
                accessToken = userSession?.getAccessToken() as any;
                jwtToken = accessToken?.jwtToken;
                setCognitoUserId(cognitoUserId);
                setIsAutenticated(true);
                setShowAlertSuccess(true);
                setUserSessionObject(userSessionObject);
                localStorage.setItem('userSessionObject', JSON.stringify(userSessionObject));
                setSigninData(formFields);
                let isAuthFromModal = localStorage.getItem('isAuthFromModal');
                if (isAuthFromModal === 'true') {
                    sendErrorMessageInSlack(`Customer Signin from modal ==>`, email)
                    navigate('/shop');
                    await setCustomerDataAvailable().then((customerData) => {
                        sessionStorage.setItem('biglocal-session-key', "true");
                        window.opener.location.reload();
                        window.close();
                    });



                } else {
                    if (isBuildABoxPage) {
                        sendErrorMessageInSlack(`Customer navigate to build a box page after auth ==>`, email)
                        navigate(`/build-a-box/${checkoutID}`);
                        await setCustomerDataAvailable();
                        sessionStorage.setItem('biglocal-session-key', "true");

                    } else {
                        sendErrorMessageInSlack(`Customer Signin from Nav bar ==>`, email)
                        navigate('/manage-subscription');
                        await setCustomerDataAvailable();
                        sessionStorage.setItem('biglocal-session-key', "true");
                    }

                }

            } catch (error: any) {
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(error.message);
                console.log("Error in Signin ", error.message)
            }

        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!');
            console.error("Invalid Form");
        }
    }


    const handleForgotPassword = () => {
        navigate('/auth/forgot_password_code')
    }

    const handleSignUp = () => {
        navigate('/signup')
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        !value && setErrors({ ...errors, [name]: 'This is a required field' });
        if (email && !emailRegex.test(email)) {
            setErrors({ ...errors, email: 'Email is invalid!' });
        }
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setErrors({ ...errors, [name]: '' });
    };

    const validate = () => {
        let tempErrors = { ...defaultSignInFields };
        let hasError = false;
        if (!email) {
            tempErrors.email = "Email is required";
            hasError = true;
        }
        else if (!emailRegex.test(email)) {
            tempErrors.email = "Email is invalid";
            hasError = true;
        }
        if (!password) {
            tempErrors.password = "Password is required";
            hasError = true;
        }
        setErrors(tempErrors);
        return hasError;
    };

    return (
        <div className='auth-form-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }

            <h2>Already have an account?</h2>
            <span>Sign in with your email and passowrd </span>
            <form onSubmit={handleSubmit}>
                <FormInputText
                    label='Email'
                    type='text'
                    onChange={handleChange}
                    name='email'
                    value={email}
                    errorMessage={errors?.email}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <FormInputText
                    label='Password'
                    type='password'
                    onChange={handleChange}
                    name='password'
                    value={password}
                    errorMessage={errors?.password}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                />
                <div className='auth-buttons-container'>
                    <FormButton type='submit'>SignIn</FormButton>
                    <h3>Don't have an account? <a onClick={handleSignUp}>SignUp</a></h3>
                    <h3 onClick={handleForgotPassword}>Forgot Password?</h3>
                </div>


            </form>
        </div>

    )
}

export default SignInForm;