
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout-form.component";
import { useParams } from "react-router-dom";
import { stripePromise } from "../../../services/stripe/stripe.utils";
import { useContext, useState } from "react";
import { CartContext } from "../../../contexts/cart.context";
import AlertBanner from '../../../components/mui/alert.component';

import './payments.styles.scss'


const OneTimePayments = ({ }) => {

    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const { client_secret } = useParams();
    const { addOnCharge } = useContext(CartContext);
    let clientSecret = client_secret;

    const setErrorBanner = (errorMessage) => {
        setShowAlertError(true)
        setErrorMessageDisplay(errorMessage)
    }



    return (
        <div className="payment-form-container">
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            <h1>Payment for the additional charge of ${addOnCharge.toFixed(2)}</h1>
            <span>This extra payment is caused by your cart going over your subscription credit.</span>
            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm setErrorBanner={setErrorBanner} />
                </Elements>
            )}
        </div>


    )
}

export default OneTimePayments;