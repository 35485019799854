import './shipping-data-form.styles.scss';

import { useState, useContext, useEffect } from 'react';
import FormInputText from '../form-input-text/form-input-text.component';
import FormButton from '../form-button/form-button.component';
import { useNavigate } from 'react-router-dom';
import DropdownInput from '../dropdown-input/dropdown-input.component';
import { ModalContext, defaultAddressValues, defaultFormValuesShipping } from '../../../contexts/modal.context';
import { RESIDENCE_OPTIONS } from '../../../constants/modal.data';
import { FormValuesShipping } from '../../../types/context-types/modal-context.types';
import AlertBanner from '../../mui/alert.component';
import LoadingSpinner from '../../mui/spinner.component';
import { emailRegex, postalCodeRegex, phoneRegex } from '../../../constants/regex.utils';
import { ApiDataContext } from '../../../contexts/api.data.context';
import { Address } from '../../../types/form-types/shipping-from.types';


const ShippingForm = () => {

    let userSessionObject = JSON.parse(localStorage.getItem('userSessionObject') as string);
    let userSessionPayload = userSessionObject?.signInUserSession.idToken.payload
    if (userSessionPayload) {
        var { family_name, given_name, email } = userSessionPayload
    }

    let firstName = ''
    let lastName = ''
    let customerEmail = ''
    let phone = ''
    let address1 = ''
    let address2 = ''
    let city = ''
    let buzzCode = ''
    let province = ''
    let postalCode = ''
    let addressType = ''

    const { setShippingData, setModalCategory, shippingData, provinceSelected, citySelected, countrySelected } = useContext(ModalContext);
    const { provinces } = useContext(ApiDataContext);
    const navigate = useNavigate();


    const autofillAddressData: Address = {
        ...defaultAddressValues,
        displayProvince: provinceSelected.name,
        displayProvinceCode: provinceSelected.abbrivation as string,
        displayProvinceId: provinceSelected.id,
        displayCity: citySelected.name,
        displayCountry: countrySelected.name,
        displayCountryId: countrySelected.id,
        displayCountryCode: countrySelected.abbrivation as string
    }

    const defaultShippingFormValues: FormValuesShipping = {
        displayFirstName: firstName || given_name || '',
        displayLastName: lastName || family_name || '',
        displayEmail: customerEmail || email || '',
        displayPhone: phone || '',
        displayAddress: autofillAddressData
    }

    const defaultShippingFormErrorValues: FormValuesShipping = {
        displayFirstName: '',
        displayLastName: '',
        displayEmail: '',
        displayPhone: '',
        displayAddress: { ...autofillAddressData, displayProvince: '', displayCity: '', displayPostalCode: '' }
    }

    const [formFields, setFormFields] = useState(defaultShippingFormValues);
    const [errors, setErrors] = useState<FormValuesShipping>({ ...defaultShippingFormErrorValues });
    const [showAlertError, setShowAlertError] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorMessageDisplay, setErrorMessageDisplay] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { displayFirstName, displayLastName, displayEmail, displayPhone, displayAddress } = formFields;
    let { displayAddress1, displayAddress2, displayCity, displayPostalCode, displayProvince, displayAddressType, displayBuzzCode } = displayAddress;

    useEffect(() => {
        let shippingDataFromLocalStorage = JSON.parse(localStorage.getItem('shippingData') as string);

        if (shippingDataFromLocalStorage) {
            firstName = shippingDataFromLocalStorage.displayFirstName
            lastName = shippingDataFromLocalStorage.displayLastName
            customerEmail = shippingDataFromLocalStorage.displayEmail
            phone = shippingDataFromLocalStorage.displayPhone

            address1 = shippingDataFromLocalStorage.displayAddress.displayAddress1
            address2 = shippingDataFromLocalStorage.displayAddress.displayAddress2
            city = shippingDataFromLocalStorage.displayAddress.displayCity
            buzzCode = shippingDataFromLocalStorage.displayAddress.displayBuzzCode
            province = shippingDataFromLocalStorage.displayAddress.displayProvince
            postalCode = shippingDataFromLocalStorage.displayAddress.displayPostalCode
            addressType = shippingDataFromLocalStorage.displayAddress.displayAddressType

        }

        if (!displayAddress1) {
            setFormFields({
                ...formFields,
                displayFirstName: given_name || firstName,
                displayLastName: family_name || lastName,
                displayEmail: email || customerEmail,
                displayPhone: phone,
                displayAddress: {
                    ...formFields.displayAddress,
                    displayAddress1: address1,
                    displayAddress2: address2,
                    displayPostalCode: postalCode,
                    displayBuzzCode: buzzCode,
                    displayAddressType: addressType,
                    displayProvince,
                    displayCity
                }
            });
        }
    }, [])


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name.includes("displayAddress.")) {
            const addressFieldName = name.replace("displayAddress.", "");

            setFormFields({
                ...formFields,
                displayAddress: {
                    ...formFields.displayAddress,
                    [addressFieldName]: value
                }
            });
        } else {
            setFormFields({ ...formFields, [name]: value });
        }
    }


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hasError = validate();
        if (!hasError) {
            try {
                setIsLoading(true);
                setShippingData(formFields);
                setShowAlertSuccess(true)
                setModalCategory('cities');
                localStorage.setItem('isAuthFromModal', 'false');
                localStorage.setItem('shippingData', JSON.stringify(formFields));
                navigate('/sub-summary');
            } catch (error: any) {
                let errorObj = JSON.parse(error.message);
                let errorMessage = errorObj[0].message;
                setIsLoading(false);
                setShowAlertError(true);
                setErrorMessageDisplay(errorMessage);
            }

        } else {
            setIsLoading(false);
            setShowAlertError(true);
            setErrorMessageDisplay('Invalid Form. Please try again!');
            console.error("Invalid Form");
        }
    }


    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.selectedOptions[0];

        if (event.target.name === 'displayProvince') {
            const Id = parseInt(selectedOption.dataset.id as string, 10);
            const Name = selectedOption.dataset.name;
            const Abbreviation = selectedOption.dataset.abbreviation;
            const id = Id as number
            setFormFields({
                ...formFields, displayAddress: {
                    ...formFields.displayAddress,
                    displayProvince: Name as string,
                    displayProvinceCode: Abbreviation as string,
                    displayProvinceId: Id,

                }
            })
            setErrors({ ...errors, displayAddress: { ...autofillAddressData, displayProvince: '' } });

        } else {
            const propertyName = event.target.name
            const id = selectedOption.dataset.id;
            const name = selectedOption.dataset.name;
            setFormFields({
                ...formFields, displayAddress: {
                    ...formFields.displayAddress,
                    [propertyName]: id
                }
            })
            setErrors({ ...errors, [name as string]: '' });
        }


    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        !value && setErrors({ ...errors, [name]: `This is a required field!` });

        if (displayEmail && !emailRegex.test(displayEmail)) {
            setErrors({ ...errors, displayEmail: 'Email is invalid!' });
        }
        if (displayPostalCode && !postalCodeRegex.test(displayPostalCode)) {
            setErrors({
                ...errors,
                displayAddress: {
                    ...errors.displayAddress,
                    displayPostalCode: 'Postal Code is invalid!'
                }
            });
        }
        if (displayPhone && !phoneRegex.test(displayPhone)) {
            setErrors({ ...errors, displayPhone: 'Phone number format is invalid!' });
        }
    }

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const addressFieldName = name.replace("displayAddress.", "");
        if (name.includes('displayAddress')) {
            setErrors({
                ...errors,
                displayAddress: {
                    ...errors.displayAddress,
                    [addressFieldName]: ''
                }
            });

        } else {
            setErrors({ ...errors, [name]: '' });
        }

    };

    let tempErrors = { ...defaultShippingFormErrorValues };
    const validate = () => {
        let hasError = false;
        if (!displayFirstName) {
            tempErrors.displayFirstName = "First Name is required!";
            hasError = true;
        }
        if (!displayLastName) {
            tempErrors.displayLastName = "Last Name is required!";
            hasError = true;
        }
        if (!displayEmail) {
            tempErrors.displayEmail = "Email is required!";
            hasError = true;
        }
        else if (!emailRegex.test(displayEmail)) {
            tempErrors.displayEmail = "Email is invalid!";
            hasError = true;
        }
        if (!displayAddress1) {
            tempErrors.displayAddress.displayAddress1 = "Address is required!";
            hasError = true;
        }
        if (!displayCity) {
            tempErrors.displayAddress.displayCity = "City is required!";
            hasError = true;
        }
        if (!displayPostalCode) {
            tempErrors.displayAddress.displayPostalCode = "Postal Code is required!";
            hasError = true;
        }
        else if (!postalCodeRegex.test(displayPostalCode)) {
            tempErrors.displayAddress.displayPostalCode = "Postal Code is invalid!";
            hasError = true;
        }
        if (!displayPhone) {
            tempErrors.displayPhone = "Phone number is required!";
            hasError = true;
        }
        else if (!phoneRegex.test(displayPhone)) {
            tempErrors.displayPhone = "Phone number format is invalid!";
            hasError = true;
        }
        if (!displayProvince) {
            tempErrors.displayAddress.displayProvince = "Province is required!";
            hasError = true;
        }
        if (!displayAddressType) {
            tempErrors.displayAddress.displayAddressType = "Residence Type is required!";
            hasError = true;
        }
        setErrors(tempErrors);
        return hasError;
    };

    return (
        <div className='shipping-form-container'>
            {
                showAlertError && <AlertBanner alertType='error' alertMessage={errorMessageDisplay} />
            }
            {
                showAlertSuccess && <AlertBanner alertType='success' alertMessage='Success!' />
            }
            {
                isLoading && <LoadingSpinner />
            }
            <form onSubmit={handleSubmit} className='shipping-form'>
                <FormInputText
                    label='First Name'
                    type='text'
                    onChange={handleChange}
                    name='displayFirstName'
                    value={displayFirstName}
                    errorMessage={errors?.displayFirstName}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <FormInputText
                    label='Last Name'
                    type='text'
                    onChange={handleChange}
                    name='displayLastName'
                    value={displayLastName}
                    errorMessage={errors?.displayLastName}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <FormInputText
                    label='Email'
                    type='text'
                    onChange={handleChange}
                    name='displayEmail'
                    value={displayEmail}
                    errorMessage={errors?.displayEmail}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <FormInputText
                    label='Address Line 1'
                    type='text'
                    onChange={handleChange}
                    name='displayAddress.displayAddress1'
                    value={displayAddress1}
                    errorMessage={errors?.displayAddress.displayAddress1}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <FormInputText
                    label='Address Line 2'
                    type='text'
                    onChange={handleChange}
                    name='displayAddress.displayAddress2'
                    value={displayAddress2}
                    // errorMessage={errors?.displayAddress}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <FormInputText
                    label='City'
                    type='text'
                    onChange={handleChange}
                    name='displayAddress.displayCity'
                    value={displayCity}
                    errorMessage={errors?.displayAddress.displayCity}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <FormInputText
                    label='Postal Code'
                    type='text'
                    onChange={handleChange}
                    name='displayAddress.displayPostalCode'
                    value={displayPostalCode}
                    errorMessage={errors?.displayAddress.displayPostalCode}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <FormInputText
                    label='Phone'
                    type='text'
                    onChange={handleChange}
                    name='displayPhone'
                    value={displayPhone}
                    errorMessage={errors?.displayPhone}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />

                <DropdownInput errorMessage={errors?.displayAddress.displayProvince} options={provinces} name='displayProvince' id='displayProvince' label='Province' handleDropdownChange={handleDropdownChange} defaultValue={displayAddress.displayProvince} isRequired={true} isHidden={false} />

                <DropdownInput errorMessage={errors?.displayAddress.displayAddressType} options={RESIDENCE_OPTIONS} name='displayAddressType' id='displayAddressType' label='Residence Type' handleDropdownChange={handleDropdownChange} defaultValue={displayAddress.displayAddressType} isRequired={true} isHidden={true} />

                <FormInputText
                    label='Buzz Code'
                    type='text'
                    onChange={handleChange}
                    name='displayAddress.displayBuzzCode'
                    value={displayBuzzCode}
                    // errorMessage={errors?.displayAddress}
                    checkValidation={handleBlur}
                    handleFocus={handleFocus}
                    isModalInput={true}
                />
                <div className='shipping-submit-button'>
                    <FormButton >Submit</FormButton>
                </div>


            </form>
        </div>

    )
}

export default ShippingForm;
