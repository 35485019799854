import { createContext, useState } from "react";
import { StripeContextProps, StripeProductsProviderProps, StripeProductDataCombined } from "../types/context-types/stripe.context.types";
import { StripeLineItems } from "../types/context-types/stripe.context.types";


const defaultLineItems: StripeLineItems[] = [{
  price: '',
  quantity: 1
}]


export const StripeContext = createContext<StripeContextProps>({
  allStripeProducts: [],
  setAllStripeProducts: () => null,
  reccuringStripeProducts: [],
  setReccuringStripeProducts: () => null,
  regularStripeProducts: [],
  setRegularStripeProducts: () => null,
  stripeCustomerId: '',
  setStripeCustomerId: () => null,
  lineItems: defaultLineItems,
  setLineItems: () => null,
  isCancelSubscriptionBtnClicked: false,
  setIsCancelSubscriptionBtnClicked: () => { },
  isPauseSubscriptionBtnClicked: false,
  setIsPauseSubscriptionBtnClicked: () => { },
  handleCancelSubscriptionClick: () => { },
  handlePauseSubscriptionClick: () => { },
})


export const StripeProductsProvider = ({ children }: StripeProductsProviderProps) => {

  const [reccuringStripeProducts, setReccuringStripeProducts] = useState<StripeProductDataCombined[]>([]);
  const [regularStripeProducts, setRegularStripeProducts] = useState<StripeProductDataCombined[]>([]);
  const [allStripeProducts, setAllStripeProducts] = useState<StripeProductDataCombined[]>([])
  // const [reccuringStripeProducts, setReccuringStripeProducts] = useState<Stripe.Price[]>([]);
  // const [allStripeProducts, setAllStripeProducts] = useState<Stripe.Price[]>([])
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [lineItems, setLineItems] = useState(defaultLineItems);

  const [isCancelSubscriptionBtnClicked, setIsCancelSubscriptionBtnClicked] = useState(false);
  const [isPauseSubscriptionBtnClicked, setIsPauseSubscriptionBtnClicked] = useState(false);

  const handleCancelSubscriptionClick = () => {
    setIsCancelSubscriptionBtnClicked(!isCancelSubscriptionBtnClicked);
  };

  const handlePauseSubscriptionClick = () => {
    setIsPauseSubscriptionBtnClicked(!isPauseSubscriptionBtnClicked);
  };

  const value = {
    setReccuringStripeProducts, reccuringStripeProducts, allStripeProducts, setAllStripeProducts, stripeCustomerId, setStripeCustomerId, lineItems, setLineItems, regularStripeProducts, setRegularStripeProducts,
    isCancelSubscriptionBtnClicked,
    setIsCancelSubscriptionBtnClicked,
    isPauseSubscriptionBtnClicked,
    setIsPauseSubscriptionBtnClicked,
    handleCancelSubscriptionClick,
    handlePauseSubscriptionClick,
  }

  return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>
}


