import './modal.styles.scss';
import { CITIES, ORDER_TYPES, ORDER_TYPES_GUEST } from '../../constants/modal.data';
import { useContext } from 'react';
import { ModalContext } from '../../contexts/modal.context';
import ModalTemplate from './modal-template/modal-template.component';
import { ModalCards } from '../../types/modal-types/modal.types';
import { useEffect } from 'react';
import { AuthContext } from '../../contexts/auth.context';
import { useNavigate } from 'react-router-dom';
import { StripeContext } from '../../contexts/stripe.context';
import { fetchStripeRecurringProducts, fetchStripeRegularProducts } from '../../services/stripe/stripe.utils';
import { StripeProductDataCombined } from '../../types/context-types/stripe.context.types';
import { Province, Country } from '../../types/context-types/api.data.context.types';
import { ApiDataContext } from '../../contexts/api.data.context';


type ModalProps = {
    type: string,
}

const Modal = ({ type }: ModalProps) => {

    let modalNavigationTo: string = '';
    let modalNavigationFrom: string = '';
    let modalCards: ModalCards = [];
    let stripeProductsCard: StripeProductDataCombined[] = []
    let modalHeading: string = '';
    let modalSubHeading: string = '';
    let isShippingPage: boolean = false;
    let isBackButtonAllowed: boolean = true;

    useEffect(() => {
        if (allStripeProducts.length === 0) {
            setStripeProducts();
        }
    }, [])


    const { setModalCategory, citySelected, orderTypeSelected, setIsNavButtonActive, showModal, resetModalContext } = useContext(ModalContext);

    const { reccuringStripeProducts, allStripeProducts, setReccuringStripeProducts, setRegularStripeProducts, regularStripeProducts } = useContext(StripeContext);

    const { countries, provinces } = useContext(ApiDataContext)

    let isAuthFromModal = localStorage.getItem('isAuthFromModal')

    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!showModal) {
        return null
    }

    if (isAuthFromModal === 'true') {
        type = 'shippingData'
    }

    const convertCountryDataToModalCardFormat = (countries: Country[]) => {
        const modalCards: ModalCards = countries.map(country => ({
            id: country.Id,
            name: country.Name,
            abbrivation: country.Iso
        }));

        return modalCards;
    }

    const convertProvinceDataToModalCardFormat = (provinces: Province[]) => {
        const modalCards: ModalCards = provinces.map(province => ({
            id: province.Id,
            name: province.Name,
            abbrivation: province.Abbreviation
        }));

        return modalCards;
    }

    const setStripeProducts = async () => {
        const recurringStripeProducts = await fetchStripeRecurringProducts();
        const regularStripeProducts = await fetchStripeRegularProducts();
        setReccuringStripeProducts(recurringStripeProducts);
        setRegularStripeProducts(regularStripeProducts);
        // setReccuringStripeProducts(recurringStripeProducts.data);
    }

    const handleBackButtonPress = () => {
        console.log(citySelected, 'citySelected', 'orderTypeSelected', orderTypeSelected);

        if (type === 'shippingData') {
            localStorage.setItem('isAuthFromModal', 'false')
        }
        setModalCategory(modalNavigationFrom);
        setIsNavButtonActive(true);

    }

    const handleCloseModal = () => {
        resetModalContext();
        navigate('/');
    }

    switch (type) {
        case 'countries':
            modalNavigationTo = 'provinces';
            // isBackButtonAllowed = false;
            modalCards = convertCountryDataToModalCardFormat(countries);
            modalHeading = 'Select the country?';
            break;
        case 'provinces':
            modalNavigationTo = 'cities';
            // isBackButtonAllowed = false;
            modalCards = convertProvinceDataToModalCardFormat(provinces);
            modalHeading = 'Select the province?';
            break;
        case 'cities':
            modalNavigationTo = 'orderType';
            isBackButtonAllowed = false;
            modalCards = CITIES;
            modalHeading = 'Where will the items be delivered?';
            break;
        case 'orderType':
            modalNavigationTo = 'avaialableDates';
            modalNavigationFrom = 'cities';
            modalCards = ORDER_TYPES;
            modalHeading = `Our Offers in ${citySelected.name}`;
            break;


        case 'avaialableDates':
            modalNavigationTo = 'productSelection';
            modalNavigationFrom = 'orderType';
            modalHeading = 'First Delivery Date?';
            break;
        case 'productSelection':
            modalNavigationTo = 'auth';
            modalNavigationFrom = 'avaialableDates';
            if (orderTypeSelected.id === 1) {
                stripeProductsCard = reccuringStripeProducts
            }
            else if (orderTypeSelected.id === 2) {
                // this need to be GS from Stripe
                stripeProductsCard = regularStripeProducts
            }
            else if (orderTypeSelected.id === 3) {
                stripeProductsCard = regularStripeProducts
            }
            modalHeading = 'Pick Your Subscription?';
            break;
        case 'auth':
            if (isAuthenticated) {
                modalNavigationTo = 'orderSummary';
                modalNavigationFrom = 'productSelection';
                isShippingPage = true;
                modalHeading = 'Where will the items be delivered each month?';
            } else {
                modalNavigationFrom = 'productSelection';
                modalHeading = 'Would you like to login? ';
                modalSubHeading = 'SignUp or Conitnue as a guest?';
            }
            break;
        case 'shippingData':
            modalNavigationTo = 'orderSummary';
            modalNavigationFrom = 'auth';
            isShippingPage = true;
            modalHeading = 'Where will the items be delivered each month?';
            break;
        default:
            break;
    }


    return (
        <div className='modal-overlay'>
            <div className='modal-container'>
                {
                    <div>
                        {
                            <ModalTemplate
                                isBackButtonAllowed={isBackButtonAllowed}
                                modalHeading={modalHeading}
                                handleBackButtonPress={handleBackButtonPress}
                                modalNavigationTo={modalNavigationTo}
                                handleCloseModal={handleCloseModal}
                                type={type}
                                modalCards={modalCards}
                                stripeProductsCard={stripeProductsCard}
                                isShippingPage={isShippingPage}

                            />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Modal;