import { createContext, useState} from "react";
import { manageSubscriptionContextProps, manageSubscriptionProviderProps } from "../types/context-types/manageSubscription.context.types";

export const useManageSubscription = () => {
  const [isCancelSubscriptionBtnClicked, setIsCancelSubscriptionBtnClicked] = useState(false);
  const [isPauseSubscriptionBtnClicked, setIsPauseSubscriptionBtnClicked] = useState(false);

  const handleCancelSubscriptionClick = () => {
    setIsCancelSubscriptionBtnClicked(!isCancelSubscriptionBtnClicked);
  };

  const handlePauseSubscriptionClick = () => {
    setIsPauseSubscriptionBtnClicked(!isPauseSubscriptionBtnClicked);
  };

  return {
    isCancelSubscriptionBtnClicked,
    setIsCancelSubscriptionBtnClicked,
    isPauseSubscriptionBtnClicked,
    setIsPauseSubscriptionBtnClicked,
    handleCancelSubscriptionClick,
    handlePauseSubscriptionClick,
  };
};

export const ManageSubscriptionContext = createContext<manageSubscriptionContextProps>({
  isCancelSubscriptionBtnClicked: false,
  setIsCancelSubscriptionBtnClicked: () => { },
  isPauseSubscriptionBtnClicked: false,
  setIsPauseSubscriptionBtnClicked: () => { },
  handleCancelSubscriptionClick: () => { },
  handlePauseSubscriptionClick: () => { },
});

export const ManageSubscriptionProvider = ({ children }: manageSubscriptionProviderProps) => {
  const value = useManageSubscription();

  return <ManageSubscriptionContext.Provider value={value}>{children}</ManageSubscriptionContext.Provider>;
};
