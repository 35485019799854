import React from 'react';
import './form-input-text.styles.scss';

type FormInputTextProps = {
    label: string,
    errorMessage?: string,
    isModalInput?: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    checkValidation: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
} & React.ComponentProps<'input'>

const FormInputText = ({ label, onChange, errorMessage, checkValidation, handleFocus, isModalInput, ...otherProps }: FormInputTextProps) => {
    return (
        <div className={`${isModalInput ? 'isModalInput' : null} form-input-container`}>
            <input className={`form-input ${errorMessage ? 'error' : null}`} onChange={onChange} {...otherProps} onBlur={checkValidation} onFocus={handleFocus} />
            {label &&
                <label className={`${isModalInput ? 'isModalInput' : null} ${otherProps.value && typeof otherProps.value === 'string' && otherProps.value.length ? 'shrink' : null} form-input-label`}>{label}</label>}
            {errorMessage && <p className={`error-message ${isModalInput ? 'isModalInput' : null}`}>{errorMessage}</p>}
        </div>
    )
}


export default FormInputText;