import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.styles.scss'
import { ModalContext } from '../../contexts/modal.context';
import { ApiDataContext } from '../../contexts/api.data.context';



const DateSelector = () => {

  const today = new Date();
  let dateSelected = today;

  const { deliveryDateSelected, setDeliveryDateSelected, setDeliveryDateSelectedID, setIsNavButtonActive, } = useContext(ModalContext);
  const { deliveryDateReponse } = useContext(ApiDataContext);

  const dateOptions_toDateObject = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  const dateOptions_toString = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 2, 0);


  useEffect(() => {
    if (window.innerWidth < 650) {
      setMonthsToShow(1);
    } else {
      setMonthsToShow(2);
    }
    const handleResize = () => {
      if (window.innerWidth < 650) {
        setMonthsToShow(1);
      } else {
        setMonthsToShow(2);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const enabledDates = deliveryDateReponse?.map(item => {
    const date = new Date(item.StartTime);
    const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}T00:00:00`;
    return { id: item.Id, date: new Date(formattedDate) }
  });

  // const enabledDates = [new Date('2023-02-14'), new Date('2023-02-17'), new Date('2023-03-15'), new Date('2023-03-18'), new Date('2023-04-01')];

  // const enabledDates = [
  //   { id: 15, date: new Date('2023-03-15') },
  //   { id: 18, date: new Date('2023-03-18') },
  //   { id: 25, date: new Date('2023-03-25') }];


  if (deliveryDateSelected) {
    dateSelected = new Date(Date.parse(new Date(deliveryDateSelected).toLocaleDateString('en-US', dateOptions_toDateObject)))
  }
  const [selectedDate, setSelectedDate] = useState(dateSelected);
  const [monthsToShow, setMonthsToShow] = useState(2);

  const handleDateSelect = (date) => {
    let matchingDeliveryId
    const matchingDate = enabledDates.find(day => date.toDateString() === day['date'].toDateString());
    if (matchingDate) {
      matchingDeliveryId = matchingDate['id'];
    }
    const formattedDate = date.toLocaleDateString('en-US', dateOptions_toString);
    setSelectedDate(date);
    setDeliveryDateSelected(formattedDate);
    setDeliveryDateSelectedID(matchingDeliveryId)
    setIsNavButtonActive(true);
    localStorage.setItem('deliveryDateSelected', formattedDate);
    localStorage.setItem('deliveryDateSelectedID', matchingDeliveryId);
  }

  const filterDate = date => {
    return enabledDates?.some(day => date.toDateString() === day['date'].toDateString())
  }

  const CustomInput = React.forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{
        display: 'none',
      }}
    >
    </div>
  ));



  return (
    <div className='datepicker-container'>
      <DatePicker calendarClassName="custom-datepicker"
        selected={selectedDate}
        onChange={handleDateSelect}
        monthsShown={monthsToShow}
        open={true}
        customInput={<CustomInput />}
        filterDate={filterDate}
        minDate={firstDay}
        maxDate={lastDay}
        popperPlacement="bottom"
      />
    </div>
  );
}




export default DateSelector;
