import './shop-items.styles.scss';


const ShopItems = () => {
    return (
        <div className='shop-items-container'>
            <h1>This Contains Shop Items</h1>
            <span>
                'Lorem ipsum dolor sit amet, ei vim facilis mnesarchum, sonet maluisset assueverit pri cu, discere deterruisset ad vix. Tale veri id eum. Vis lorem ullum cu. Quas nihil iracundia cu duo. Decore euripidis disputationi eu vis.

                At imperdiet sententiae pro. Vim animal numquam ne, feugiat impedit et vis, qui impetus commune te. Quo noster possim antiopam ne, ex cum unum incorrupte. Mea ei consetetur elaboraret, eu eam velit ornatus. Tantas aliquam sensibus id usu. Mazim principes quo id.

                An graeco alterum accusam sed, convenire moderatius posidonium has no. Eirmod sanctus qui cu, pri an graecis recteque. Vel an dicunt lobortis disputationi. Adhuc quodsi albucius et sea. Duo cu purto quodsi copiosae.

                Ius justo atomorum an. Vel debitis epicurei deserunt ut, ex mel alienum eligendi. At qui graeci quaeque referrentur, vix ut cetero dolorem. Scaevola suscipiantur vim id, ludus sensibus et eam.

                Usu an mutat justo volumus. Hinc dicit evertitur eam at. Nullam aeterno ea vix. Dicta animal vis id, an diam saperet deleniti eam. Eum et simul aliquid similique, sea in salutandi forensibus definiebas. Per numquam lucilius in.'
            </span>
            <span>
                'Lorem ipsum dolor sit amet, ei vim facilis mnesarchum, sonet maluisset assueverit pri cu, discere deterruisset ad vix. Tale veri id eum. Vis lorem ullum cu. Quas nihil iracundia cu duo. Decore euripidis disputationi eu vis.

                At imperdiet sententiae pro. Vim animal numquam ne, feugiat impedit et vis, qui impetus commune te. Quo noster possim antiopam ne, ex cum unum incorrupte. Mea ei consetetur elaboraret, eu eam velit ornatus. Tantas aliquam sensibus id usu. Mazim principes quo id.

                An graeco alterum accusam sed, convenire moderatius posidonium has no. Eirmod sanctus qui cu, pri an graecis recteque. Vel an dicunt lobortis disputationi. Adhuc quodsi albucius et sea. Duo cu purto quodsi copiosae.

                Ius justo atomorum an. Vel debitis epicurei deserunt ut, ex mel alienum eligendi. At qui graeci quaeque referrentur, vix ut cetero dolorem. Scaevola suscipiantur vim id, ludus sensibus et eam.

                Usu an mutat justo volumus. Hinc dicit evertitur eam at. Nullam aeterno ea vix. Dicta animal vis id, an diam saperet deleniti eam. Eum et simul aliquid similique, sea in salutandi forensibus definiebas. Per numquam lucilius in.'
            </span>
            <span>
                'Lorem ipsum dolor sit amet, ei vim facilis mnesarchum, sonet maluisset assueverit pri cu, discere deterruisset ad vix. Tale veri id eum. Vis lorem ullum cu. Quas nihil iracundia cu duo. Decore euripidis disputationi eu vis.

                At imperdiet sententiae pro. Vim animal numquam ne, feugiat impedit et vis, qui impetus commune te. Quo noster possim antiopam ne, ex cum unum incorrupte. Mea ei consetetur elaboraret, eu eam velit ornatus. Tantas aliquam sensibus id usu. Mazim principes quo id.

                An graeco alterum accusam sed, convenire moderatius posidonium has no. Eirmod sanctus qui cu, pri an graecis recteque. Vel an dicunt lobortis disputationi. Adhuc quodsi albucius et sea. Duo cu purto quodsi copiosae.

                Ius justo atomorum an. Vel debitis epicurei deserunt ut, ex mel alienum eligendi. At qui graeci quaeque referrentur, vix ut cetero dolorem. Scaevola suscipiantur vim id, ludus sensibus et eam.

                Usu an mutat justo volumus. Hinc dicit evertitur eam at. Nullam aeterno ea vix. Dicta animal vis id, an diam saperet deleniti eam. Eum et simul aliquid similique, sea in salutandi forensibus definiebas. Per numquam lucilius in.'
            </span>
        </div>

    )
}

export default ShopItems;