import React from "react";
import OrderTypeTabs from './order-tabs/orders-type-tabs.component';
import CustomerInfo from '../../components/orderDetails/customer/customer-profile.component';
import './order-management-main.styles.scss';

interface OrdersManagementComponentProps {
  subscriptionid?: string;
}

const OrdersManagementComponent: React.FC<OrdersManagementComponentProps> = ({ subscriptionid }) => {
  return (
    <div className="component">
      <>
        <CustomerInfo />
        <div className="orderscomponent">
          {subscriptionid !== undefined ? (
            <OrderTypeTabs subscriptionid={subscriptionid} />
          ) : (
            <OrderTypeTabs />
          )}

        </div>
      </>
    </div>
  );
};

export default OrdersManagementComponent;
