import './shop.styles.scss';
import Modal from '../../components/modal/modal.component';
import ShopItems from '../../components/shop-items/shop-items.component';
import { useContext, useEffect } from 'react';
import { ModalContext } from '../../contexts/modal.context';
import { ApiDataContext } from '../../contexts/api.data.context';
import { getDeliveryDatesAvailable, getProvinces, getCountries } from '../../services/api/data.utils';


const Shop = () => {

    useEffect(() => {
        setInitialData();
    }, [])

    const { modalCategory } = useContext(ModalContext);
    const { setDeliveryDateResponse, setCountires, setProvinces} = useContext(ApiDataContext)

    const setDeliveryDatesAvailable = async () => {
        let rawDeliveryDates = await getDeliveryDatesAvailable();
        setDeliveryDateResponse(rawDeliveryDates.data);  
    }

    const setProvincesData = async () => {
        let provinces = await getProvinces();
        setProvinces(provinces.data);
    }

    const setCountriesData = async () => {
        let countries = await getCountries();
        setCountires(countries.data);
    }

    

    const setInitialData = async () => {
        await setCountriesData();
        await setProvincesData();
        await setDeliveryDatesAvailable();
    }



    return (
        <div>
            <ShopItems />
            <Modal type={modalCategory}></Modal>
        </div>
    )
}


export default Shop;