import { PaymentElement } from "@stripe/react-stripe-js";
import { useState, useContext } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useParams } from 'react-router-dom';
import './checkout-form-styles.scss';
import { CartContext } from "../../../contexts/cart.context";
import Button from "../../button/button.component";



const CheckoutForm = ({ setErrorBanner }) => {
  const stripe = useStripe();
  const elements = useElements();
  let { checkoutID } = useParams();


  const [isProcessing, setIsProcessing] = useState(false);
  const { isInitialPaymentFailed, setIsInitialPaymentFailed } = useContext(CartContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/build-a-box/${checkoutID}/orderconfirmation`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setIsInitialPaymentFailed(true);
      setIsProcessing(false);
      setErrorBanner(error.message)
      return;
      window.location.href = `${window.location.origin}/build-a-box/${checkoutID}`
    } else {
      setIsInitialPaymentFailed(true);
      setIsProcessing(false);
      setErrorBanner(error.message)

      return;
      window.location.href = `${window.location.origin}/build-a-box/${checkoutID}`
    }


  };

  return (
    <form className="payment-form" onSubmit={handleSubmit}>
      <PaymentElement className="payment-element" />
      <Button disabled={isProcessing || !stripe || !elements}>{isProcessing ? "Processing ... " : "Pay now"}</Button>
    </form>
  );
}

export default CheckoutForm;
