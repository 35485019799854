import './filter-options.styles.scss';

type FilterOptionsProps = {
    handleProductsFilterName: (event: React.ChangeEvent<HTMLSelectElement>) => void
}


const FilterOptions = ({ handleProductsFilterName }: FilterOptionsProps) => {
    return (
        <div className='filter-bar-container'>
            <select className='filter-bar' defaultValue={'all'} onChange={handleProductsFilterName}>
                <option value="ALL_PRODUCTS">All Categories</option>
                <option value="BURGER">BURGER</option>
                <option value="MOMOS">MOMOS</option>
                <option value="ICE_CREAM">Ice Cream</option>
                <option value="PIZZA">Pizza</option>
                <option value="COFFEE">Coffee</option>
                <option value="CHEESE">Cheese</option>
                <option value="SOUPS">Soups</option>

            </select>
        </div>
    )
}

export default FilterOptions;