import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { getCustomerData } from "../../services/api/data.utils";
import { StripeContext } from "../../contexts/stripe.context";
import { CustomerContext } from "../../contexts/customer.context";
import { cookieExpirationTime } from "../../constants/other.data";
import ManageSubscriptionComponent from '../../components/manage-subscription/manage-subscription-main.component';

import { sendErrorMessageInSlack } from "../../utils/miscellaneous.functions";

import { CartContext } from "../../contexts/cart.context";
import { ModalContext } from "../../contexts/modal.context";

const CustomerOrdersPage = () => {
  const navigate = useNavigate();

  const [name, setName] = useState(null);
  const {
    setIsAutenticated,
    userSessionObject,
    setCognitoUserId,
  } = useContext(AuthContext);
  const { setStripeCustomerId } = useContext(StripeContext);
  const { setCustomerData } = useContext(CustomerContext);

  const { clearCart } = useContext(CartContext);
  const { resetModalContext } = useContext(ModalContext);

  const expires = new Date();
  expires.setDate(expires.getDate() + cookieExpirationTime);

  let userSession = null;
  let accessToken = null;
  let jwtToken: any = null;
  let idToken = null;
  let userEmail = null;
  let cognitoUserId: any = null;

  useEffect(() => {
    checkAuthState();
    getSessionData();
    setCognitoUserId(cognitoUserId);
    setCustomerDataAvailable();
  }, [cognitoUserId]);

  const checkAuthState = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAutenticated(true);
    } catch (error) {
      setIsAutenticated(false);
      navigate("/signin");
    }
  };

  const handleSignOut = () => {
    try {
      Auth.signOut();
      setIsAutenticated(false);
      localStorage.clear();
      sessionStorage.clear();
      clearCart();
      resetModalContext();
      window.location.href = "/signin";
    } catch (error) {
      console.log("Error in SignOut ", error);
    }
  };

  const getSessionData = () => {
    try{
    if (userSessionObject) {
      userSession = userSessionObject?.getSignInUserSession();
      accessToken = userSession?.getAccessToken() as any;
      jwtToken = accessToken?.jwtToken;
      idToken = userSession?.getIdToken();
      setName(idToken?.payload.given_name);
      userEmail = idToken?.payload.email;
      cognitoUserId = idToken?.payload["cognito:username"];
    } else {
      let fetchedUserSessionObject = JSON.parse(
        localStorage.getItem("userSessionObject") as any
      );
      jwtToken =
        fetchedUserSessionObject?.signInUserSession.accessToken.jwtToken;
      idToken = fetchedUserSessionObject?.signInUserSession.idToken;
      setName(idToken?.payload.given_name);
      userEmail = idToken?.payload.email;
      cognitoUserId = idToken?.payload["cognito:username"];
    }
  }
  catch (error) {
    console.error("Error fetching user session object:", error);
  }
  };



  const setCustomerDataAvailable = async () => {
    try{
  //  sendErrorMessageInSlack(" JWT Token", jwtToken);
    let customerData = await getCustomerData(cognitoUserId, jwtToken);
    const responseStatusOfCustomerData=customerData?.status; 
    if(!responseStatusOfCustomerData){
    const statusCode=customerData;
    if (statusCode == 401 || statusCode==403) {
      handleSignOut();
    }
  }
     console.log('inside orders component',customerData?.data?.CustomerId)
    setStripeCustomerId(customerData?.data.CustomerId);
    localStorage.setItem("stripeCustomerId", customerData?.data.CustomerId);
    setCustomerData(customerData?.data);
}
catch (error) {
  console.error("Error fetching customer data:", error);
}
  };



  return (
    <>
    <ManageSubscriptionComponent />
  </> 
  );
};

export default CustomerOrdersPage;
