import { loadStripe } from "@stripe/stripe-js";
import Stripe from 'stripe';
import { StripeProductDataCombined } from "../../types/context-types/stripe.context.types";

const stripeConfig: Stripe.StripeConfig = {
    apiVersion: '2022-11-15',
};

export const stripeInstance = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY as string, stripeConfig);

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string)

// const session = await stripeInstance.checkout.sessions.create({

/**
 * Below function fetch data from PRICE API. Image URL not included with payload
 */
export const fetchStripeRecurringProducts = async (): Promise<StripeProductDataCombined[]> => {
    const products = await fetchStripeProductData();
    const prices = await fetchStripeRecurringPriceData();
    const joinedProductPriceData = await joinProductPriceData(products.data, prices.data)
    return joinedProductPriceData
};

export const fetchStripeRegularProducts = async (): Promise<StripeProductDataCombined[]> => {
    const products = await fetchStripeProductData();

    const prices = await fetchStripeRegularPriceData();

    const joinedProductPriceData = await joinProductPriceData(products.data, prices.data);

    return joinedProductPriceData
};

const joinProductPriceData = async (productsArray: Stripe.Product[], priceArray: Stripe.Price[]): Promise<StripeProductDataCombined[]> => {
    let productAndPriceArray = []
    for (let i = 0; i < priceArray.length; i++) {
        let data = productsArray.filter((product) => product.active === true).
            find((product) => product.default_price === priceArray[i].id)
        if (data) {
            productAndPriceArray.push({ ...priceArray[i], imageUrl: data.images[0], name: data.name, productId: data.id, category: data.metadata })
        }

    }
    return productAndPriceArray

}

export const fetchStripeRecurringPriceData = async (): Promise<Stripe.Response<Stripe.ApiList<Stripe.Price>>> => {

    const priceData = await stripeInstance.prices.list({
        limit: 150,
        type: 'recurring'
    });
    return priceData
}

export const fetchStripeRegularPriceData = async (): Promise<Stripe.Response<Stripe.ApiList<Stripe.Price>>> => {

    const priceData = await stripeInstance.prices.list({
        limit: 150,
        type: 'one_time'
    });
    return priceData
}


/**
 * Below function fetch data from PRODUCT API. Image URL included. Price, Type (OTP/Recurring) NOT included 
 */
export const fetchStripeProductData = async (): Promise<Stripe.Response<Stripe.ApiList<Stripe.Product>>> => {

    const productInfo = await stripeInstance.products.list({
        limit: 150
    })
    return productInfo
}

type StripeAddress = {
    city: string,
    country: string,
    line1: string,
    line2: string,
    postal_code: string,
    state: string
}

export const createStripeCustomer = async (email: string, name: string, address?: StripeAddress) => {

    const customer = await stripeInstance?.customers.create({
        address,
        email: email,
        name: name,

    });
    return customer



}

export const updateStripeCustomer = async (customerId: string, address: StripeAddress) => {
    const customer = await stripeInstance?.customers.update(
        customerId,
        {
            address
        }

    );
    return customer
}



