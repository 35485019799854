export const clearCartItemRelatedLocalStorage = () => {
    localStorage.removeItem('orderTypeSelected')
    localStorage.removeItem('lineItems')
    localStorage.removeItem('citySelected')
    localStorage.removeItem('cartItems')
    localStorage.removeItem('isAuthFromModal')
    localStorage.removeItem('productSelected')
    localStorage.removeItem('deliveryDateSelectedID')
    localStorage.removeItem('deliveryDateSelected')
    localStorage.removeItem('guestCheckout')
    localStorage.removeItem('metadata')
    localStorage.removeItem('selectedStripeProductFromModal')
    localStorage.removeItem('metadataForStripe')
}

export const clearLocalStorage = () => {
    localStorage.clear();
}

export const clearCartDataInLocalStorage = () => {
    localStorage.setItem('cartTotal', JSON.stringify(0))
    localStorage.setItem('cartCount', JSON.stringify(0))
    localStorage.setItem('cartItems', JSON.stringify([]))

}