import { useContext } from 'react';
import './cart-total.styles.scss'
import { CartContext } from '../../../contexts/cart.context';


const CartTotal = () => {

    const { cartTotal, addOnCharge } = useContext(CartContext);
    
    let totalCartValue = cartTotal || `00.00`

    return (
        <div className='cart-total-container'>
            <h2>Shopping Cart</h2>
            <h6>Total Cost of goods:</h6>
            <h1>${Number(totalCartValue).toFixed(2)}</h1>
            <h4>${addOnCharge.toFixed(2)} will be charged from total</h4>
        </div>
    )
}

export default CartTotal;