import './addon-summary.styles.scss';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CartContext } from '../../contexts/cart.context';
import AddonOrderDetails from '../../components/addon-summary-page-components/addon-summary-order-details/addon-order-summary.component';
import Button from '../../components/button/button.component';
import AddOnOtherDetails from '../../components/addon-summary-page-components/addon-summary-other-details/addon-other-details.component';
import { CustomerContext } from '../../contexts/customer.context';


const AddOnSummary = () => {

    const navigate = useNavigate();
    const { client_secret, checkoutID } = useParams()
    const { addOnCharge } = useContext(CartContext);
    const { customerData } = useContext(CustomerContext);
    const { Firstname } = customerData;

    /**
     * For Future Use when user need to edit the order from Summary pAge
     */
    // const handleEditOrder = async () => {
    //     setIsEditModalFlow(true);
    //     navigate('/shop');
    // }

    const handleOtpPayment = () => {
        if (addOnCharge > 0) {
            navigate(`/build-a-box/${checkoutID}/summary/${client_secret}/paynow`);
        } else {
            navigate(`/build-a-box/${checkoutID}/orderconfirmation`);
        }
    }

    return (
        <div className="cart-summary-container">
            <div className='cart-summary-header'>
                <h1 className='cart-summary-title'>{Firstname?.toUpperCase()}'S CART</h1>
                <div className='cart-summary-button'>
                    <Button onClick={handleOtpPayment}>{addOnCharge > 0 ? 'PROCEED TO PAYMENT' : 'CONFIRM'}</Button>
                </div>
            </div>
            <div className='cart-summary-details'>
                <AddOnOtherDetails />
                <AddonOrderDetails />
            </div>
            

        </div>

    )
}

export default AddOnSummary;
