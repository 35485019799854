import CartItem from '../cart-item/cart-item.component';
import { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../../contexts/cart.context';
import './cart-dropdown.styles.scss';
import CartTotal from '../cart-total/cart-total.component';
import { useNavigate, useParams } from 'react-router-dom';
import { stripeInstance } from '../../../services/stripe/stripe.utils';
import axios from 'axios';
import EmptyCart from '../empty-cart/empty-cart.component';
import Button from '../../button/button.component';

const ordersBaseURL = process.env.REACT_APP_ORDERS_BASE_URL as string

const CartDropdown = () => {

    const { cartItems, addOnCharge, isInitialAddonSelection, addonOrderId, isInitialItemsEmpty } = useContext(CartContext);

    let { checkoutID } = useParams();  //TODO : checkoutID should be encrypted..!
    // let addOnPayload = { 'OrderId': checkoutID, 'OrderLineItems': cartItems };
    let addOnPayload = {}
    let backendRepsonse : any

    const navigate = useNavigate();

    const [stripeClientSec, setStripeClientSec] = useState('');
    const [isCartEmpty, setIsCartEmpty] = useState(false);

    useEffect(() => {
        if (cartItems.length == 0) {
            setIsCartEmpty(true)
        } else {
            setIsCartEmpty(false)

        }
    }, [cartItems.length])

    const sendPayloadtoBackend = async (addOnPayload: any) => {
        if (isInitialAddonSelection) {
            console.log('initial addon', addOnPayload)
            await axios.post('https://play.svix.com/in/e_BuvdcKvaUY4dfL5B6U70TWr705l/', addOnPayload);
            backendRepsonse = await axios.post(`${ordersBaseURL}orders/itemselection`, addOnPayload);
            return backendRepsonse
        } else {
            console.log('NOT initial addon', addOnPayload);
            await axios.put('https://play.svix.com/in/e_BuvdcKvaUY4dfL5B6U70TWr705l/', addOnPayload);
            backendRepsonse = await axios.put(`${ordersBaseURL}orders/itemselection`, addOnPayload);
            return backendRepsonse
        }
    }

    const handleGoToCheckout = async () => {


        if (addOnCharge > 0) {

            let id = addonOrderId || checkoutID
            let paymentIntentId = ''

            try {
                const paymentIntent = await stripeInstance.paymentIntents.create({
                    currency: "CAD",
                    amount: addOnCharge * 100,
                    automatic_payment_methods: { enabled: true },
                    metadata:
                    {
                        'OrderId': id as string
                    }
                });

                paymentIntentId = paymentIntent.id

                addOnPayload = { 'OrderId': checkoutID, 'OrderLineItems': cartItems, 'PaymentIntentId': paymentIntentId };
                const respCode = await sendPayloadtoBackend(addOnPayload)
                const client_secret = paymentIntent?.client_secret
                setStripeClientSec(paymentIntent?.client_secret as string)
                navigate(`/build-a-box/${checkoutID}/summary/${client_secret}`)

            } catch (error) {
                console.log(error, 'Error in creating payment Intent');
            }
        } else {
            try {
                addOnPayload = { 'OrderId': checkoutID, 'OrderLineItems': cartItems, 'PaymentIntentId': null };
                const respCode = await sendPayloadtoBackend(addOnPayload)
                navigate(`/build-a-box/${checkoutID}/summary/0000`)

            } catch (error) {
                console.log(error, 'Error in creating payment Intent');
            }
        }


    }

    return (
        <div className='cart-dropdown-container'>
            <div className='cart-total'>
                <CartTotal />
            </div>
            {
                !isCartEmpty ?
                    <div className='cart-items'>
                        {cartItems.map((item, index) => {
                            return (
                                <CartItem key={index} cartItem={item} />
                            )
                        })}
                    </div> : <EmptyCart />
            }
            <div className={`cart-checkout-button ${((isInitialAddonSelection || isInitialItemsEmpty) && isCartEmpty) ? 'empty-cart' : null}`}>
                <Button disabled={((isInitialAddonSelection || isInitialItemsEmpty) && isCartEmpty)} onClick={handleGoToCheckout}>PROCEED TO CHECKOUT</Button>
            </div>

        </div>
    )
}

export default CartDropdown;